import { createActions } from 'redux-actions';

export default createActions(
  {
    RESET: () => ({}),
    ADD: (robotId, text) => ({ robotId, text }),
    REMOVE: (robotId) => ({ robotId }),
  },
  { prefix: 'NOTIFICATION' }
);
