import classNames from 'classnames/bind';
import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import styles from './EditorBatch.module.scss';

import actions from '@/actions';
import ModalWrapper from '@/components/ui/Modal';
import { ModalService as modal } from '@/libs/Modal';
import { NotifierService as notifier } from '@/libs/Notifier';

const cx = classNames.bind(styles);

const Modal = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    item: '',
    value: '',
  });
  const itemRef = useRef();
  const valueRef = useRef();

  const close = () => {
    modal.hide();
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // 유효성 검사
  const validAll = () => {
    if (values.item === '') {
      notifier.error('Please select an item.');
      itemRef.current.focus();
      return false;
    }
    if (values.value.trim() === '') {
      notifier.error('Please enter the value.');
      valueRef.current.focus();
      return false;
    }
    return true;
  };

  const doApply = () => {
    if (!validAll()) return;

    dispatch(actions.mission.editAllWaypoints(values.item, Number(values.value)));
    close();
  };

  return (
    <ModalWrapper>
      <div className={cx('fields')}>
        <label>
          Target Item
          <div className={cx('fieldWrapper')}>
            <select ref={itemRef} name="item" value={values.item} onChange={handleChange}>
              <option value="">Select</option>
              <option value="relAltitude">Relative Altitude</option>
              <option value="aglAltitude">AGL Altitude</option>
              <option value="mslAltitude">MSL Altitude</option>
            </select>
            <div className={cx('unit')} />
          </div>
        </label>
        <label>
          Value
          <div className={cx('fieldWrapper')}>
            <input
              ref={valueRef}
              name="value"
              type="text"
              disabled={values.item === ''}
              value={values.value}
              onChange={handleChange}
            />
            <div className={cx('unit')}>m</div>
          </div>
        </label>
      </div>
      <div className={cx('bottom')}>
        <button type="button" className={cx('button')} onClick={close}>
          Cancel
        </button>
        <button type="button" className={cx(['button', 'accent'])} onClick={doApply}>
          Apply
        </button>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
