import * as turf from '@turf/turf';
import classNames from 'classnames/bind';
import React, { useState, useMemo, useRef } from 'react';
import { useStore, useDispatch } from 'react-redux';

import styles from './LineDrawer.module.scss';

import actions from '@/actions';
import { NotifierService as notifer } from '@/libs/Notifier';
import CoordUtils from '@/utils/CoordUtils';
import { getElevation } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const LineDrawer = ({ points, onCancelOrSubmit }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const [count, setCount] = useState(points.length);
  const countRef = useRef();

  const changeCount = (e) => {
    setCount(Number(e.target.value));
  };

  const submit = () => {
    if (count < 2) {
      notifer.error('Please enter a number of positions greater than or equal to 2.');
      return;
    }

    onCancelOrSubmit();

    const line = turf.lineString(points);
    const interval = turf.length(line) / (count - 1);

    const countPoints = [points[0]];
    for (let index = 0; index < count - 1; index++) {
      const startDistance = index * interval;
      const endDistance = (index + 1) * interval;

      const segment = turf.lineSliceAlong(line, startDistance, endDistance);
      countPoints.push(segment.geometry.coordinates.at(-1));
    }

    const positions = countPoints.map((countPoint) => {
      return CoordUtils.objectFromArray(countPoint);
    });
    const { altitude } = store.getState().editor.config;

    Promise.all(positions.map(getElevation)).then((elevations) => {
      positions.forEach((position, index) => {
        const waypoint = { position, elevation: elevations[index], altitude };
        dispatch(actions.mission.appendWaypoint(waypoint));
      });
    });
  };

  const cancel = () => {
    onCancelOrSubmit();
  };

  const isExistPath = useMemo(() => {
    return points !== undefined;
  }, [points]);

  return (
    <div className={cx('container')}>
      {!isExistPath && <div className={cx('message')}>Draw the line.</div>}
      {isExistPath && (
        <>
          <div className={cx('message')}>Number of positions :</div>
          <input
            ref={countRef}
            name="count"
            type="number"
            value={count}
            onChange={changeCount}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                submit();
              }
            }}
          />
          <button type="button" className={cx(['button', 'accent'])} onClick={submit}>
            Confirm
          </button>
          <button type="button" className={cx('button')} onClick={cancel}>
            Cancel
          </button>
        </>
      )}
    </div>
  );
};

export default LineDrawer;
