import produce from 'immer';
import moment from 'moment';
import { handleActions } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';

const initialState = {};

export default handleActions(
  {
    'NOTIFICATION/RESET': (state, action) => initialState,
    'NOTIFICATION/ADD': (state, action) =>
      produce(state, (draft) => {
        const { robotId, text } = action.payload;

        const notification = {
          id: uuidv4(),
          text,
          time: moment().format('HH:mm:ss'),
        };

        // 로봇별 전체 알림
        if (Object.hasOwn(state, robotId)) {
          draft[robotId].unshift(notification);
        } else {
          draft[robotId] = [notification];
        }
      }),
    'NOTIFICATION/REMOVE': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;

        delete draft[robotId];
      }),
  },
  initialState
);
