import classNames from 'classnames/bind';
import React from 'react';

import styles from './Top.module.scss';

const cx = classNames.bind(styles);

const Top = ({ labels, children }) => {
  return (
    <div className={cx('container')}>
      <div className={cx('labels')}>
        {labels.map((label, index) => (
          <div key={index} className={cx('label')}>
            {label}
          </div>
        ))}
      </div>
      <div className={cx('status')}>
        {Array.isArray(children) && children.map((el) => el)}
        {!Array.isArray(children) && children}
      </div>
    </div>
  );
};

export default Top;

export const getPermissionLabel = (isOwned) => {
  return isOwned ? 'Control' : 'View Only';
};
