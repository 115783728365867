import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import Feature from 'ol/Feature';
import { LineString } from 'ol/geom';
import Modify from 'ol/interaction/Modify';
import { Vector as VectorLayer } from 'ol/layer';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Style, Stroke } from 'ol/style';
import { useRef, useMemo } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';

import actions from '@/actions';
import OlMap from '@/helpers/OlMap';
import { NotifierService as notifier } from '@/libs/Notifier';
import CoordUtils from '@/utils/CoordUtils';
import { getElevation } from '@/utils/MapUtils';

const Path = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const path = useSelector((state) => state.editor.path);
  const moveFromIndex = useSelector((state) => state.editor.moveFromIndex);
  const pathRef = useRef();
  const modify = useRef();
  const map = OlMap.getMap();

  const positions = useMemo(() => {
    return path.map(({ position }) => fromLonLat(CoordUtils.arrayFromObject(position)));
  }, [path]);

  useUpdateEffect(() => {
    if (moveFromIndex !== null) {
      map.removeInteraction(modify.current);
    } else {
      map.addInteraction(modify.current);
    }
  }, [moveFromIndex]);

  useUpdateEffect(() => {
    // 기존 경로 존재하는 경우
    if (pathRef.current) {
      pathRef.current.setCoordinates(positions);
    }
    // 기존 경로 존재하지 않는 경우
    else {
      pathRef.current = new LineString(positions);
      const feature = new Feature(pathRef.current);
      const source = new VectorSource({ features: [feature], wrapX: false });
      modify.current = new Modify({ source });
      map.addInteraction(modify.current);

      const layer = new VectorLayer({
        source,
        style: new Style({
          stroke: new Stroke({
            color: [65, 163, 255, 0.8], // #41a3ff
            width: 4,
          }),
        }),
      });
      map.addLayer(layer);
    }

    modify.current.on('modifyend', modifyPath);

    return () => {
      modify.current.un('modifyend', modifyPath);
    };
  }, [positions]);

  const modifyPath = async (e) => {
    const modified = pathRef.current.getCoordinates();

    // 변경된 경로점 탐색 및 처리
    for (let index = 0; index < modified.length; index++) {
      const prev = JSON.stringify(positions[index]);
      const curr = JSON.stringify(modified[index]);

      if (prev !== curr) {
        const isMoved = e.target.snappedToVertex_;
        const isSurveyPoint = path[index].id.includes('/survey');
        const isSurveyPath = /\/survey\/\d+$/.test(path[index].id);

        if ((isMoved && isSurveyPoint) || isSurveyPath) {
          pathRef.current.setCoordinates(positions);
          notifier.error('Please modify the survey path using polygon.');
          return;
        }

        const [lng, lat] = toLonLat(modified[index]);
        const elevation = await getElevation({ lat, lng });
        const { altitude } = store.getState().editor.config;
        const waypoint = { position: { lat, lng }, elevation, altitude };

        if (isMoved) {
          // 경로점 이동
          dispatch(actions.mission.moveWaypoint(path[index].id, waypoint));
        } else {
          const id = path[index - 1].id.split('/')[0];
          const targetIndex = store
            .getState()
            .mission.json.missionItems.findIndex((missionItem) => missionItem.id === id);

          // 경로점 추가
          dispatch(actions.mission.insertWaypoint(targetIndex, waypoint));
        }
        break;
      }
    }
  };

  return null;
};

export default Path;
