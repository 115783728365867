//! Be sure to review and update the 'ArduPilot.js' file
//! whenever you make changes to this file.

const toMavLink = (missionItems) => {
  const messages = [];
  missionItems.forEach((missionItem) => {
    // Wait For Distance
    if (missionItem.type === 'condDistance') {
      //! This message is not used in PX4.
    }
    // Wait For Yaw
    if (missionItem.type === 'condYaw') {
      //! This message is not used in PX4.
    }
    // Autotune Enable
    if (missionItem.type === 'doAutotuneEnable') {
      //! This message is not used in PX4.
    }
    // Change Speed
    if (missionItem.type === 'doChangeSpeed') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        178, // MAV_CMD_DO_CHANGE_SPEED
        0, // current
        missionItem.data.type ?? 1, // 1: SPEED_TYPE_GROUNDSPEED
        missionItem.data.speed,
        missionItem.data.throttle ?? -1, // -1: no change
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Gripper
    else if (missionItem.type === 'doGripper') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        211, // MAV_CMD_DO_GRIPPER
        0, // current
        missionItem.data.gripper,
        missionItem.data.action,
        0, // param3
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Guided Limits
    else if (missionItem.type === 'doGuidedLimits') {
      //! This message is not used in PX4.
    }
    // Set Camera Trigger Distance
    else if (missionItem.type === 'doSetCamTriggDist') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        206, // MAV_CMD_DO_SET_CAM_TRIGG_DIST
        0, // current
        missionItem.data.distance, // distance
        0, // camera shutter integration time: ignore
        1, // trigger camera once immediately
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Set Relay
    else if (missionItem.type === 'doSetRelay') {
      //! This message is not used in PX4.
    }
    // Cycle Relay
    else if (missionItem.type === 'doRepeatRelay') {
      //! This message is not used in PX4.
    }
    // Set Region Of Interest
    else if (missionItem.type === 'doSetRoiLocation') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        195, // MAV_CMD_DO_SET_ROI_LOCATION
        0, // current
        0, // Gimbal device ID
        0, // param2
        0, // param3
        0, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.altitude,
      ]);
    }
    // Set Region Of Interest None
    else if (missionItem.type === 'doSetRoiNone') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        197, // MAV_CMD_DO_SET_ROI_NONE
        0, // current
        0, // Gimbal device ID
        0, // param2
        0, // param3
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Set Servo
    else if (missionItem.type === 'doSetServo') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        183, // MAV_CMD_DO_SET_SERVO
        0, // current
        missionItem.data.servo,
        missionItem.data.pwm,
        0, // param3
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Cycle Servo
    else if (missionItem.type === 'doRepeatServo') {
      //! This message is not used in PX4.
    }
    // Jump To Item
    else if (missionItem.type === 'doJump') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        177, // MAV_CMD_DO_JUMP
        0, // current
        missionItem.data.item - 1,
        missionItem.data.repeat,
        0, // param3
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Trigger Parachute
    else if (missionItem.type === 'doParachute') {
      //! This message is not used in PX4.
    }
    // Change Altitude
    else if (missionItem.type === 'navContinueAndChangeAlt') {
      //! This message is not used in PX4.
    }
    // Delay Until
    else if (missionItem.type === 'navDelay') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        93, // MAV_CMD_NAV_DELAY
        0, // current
        missionItem.data.hold,
        missionItem.data.hour,
        missionItem.data.minute,
        missionItem.data.second,
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Guided Enable
    else if (missionItem.type === 'navGuidedEnable') {
      //! This message is not used in PX4.
    }
    // Land
    else if (missionItem.type === 'navLand') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        16, // MAV_CMD_NAV_WAYPOINT
        0, // current
        0, // param1
        0, // param2
        0, // param3
        NaN, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.altitude,
      ]);
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        21, // MAV_CMD_NAV_LAND
        0, // current
        0, // param1
        0, // param2
        0, // param3
        NaN, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        0,
      ]);
    }
    // Loiter (Time)
    else if (missionItem.type === 'navLoiterTime') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        19, // MAV_CMD_NAV_LOITER_TIME
        0, // current
        missionItem.data.loiterTime,
        1, // param2
        50, // param3
        1, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.altitude,
      ]);
    }
    // Loiter (Altitude)
    else if (missionItem.type === 'navLoiterToAlt') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        31, // MAV_CMD_NAV_LOITER_TO_ALT
        0, // current
        1, // param1
        50, // param2
        0, // param3
        1, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.altitude,
      ]);
    }
    // Loiter (Turns)
    else if (missionItem.type === 'navLoiterTurns') {
      //! This message is not used in PX4.
    }
    // Return To Launch
    else if (missionItem.type === 'navReturnToLaunch') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        20, // MAV_CMD_NAV_RETURN_TO_LAUNCH
        0, // current
        0, // param1
        0, // param2
        0, // param3
        0, // param4
        0,
        0,
        0,
      ]);
    }
    // Takeoff
    else if (missionItem.type === 'navTakeoff') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        22, // MAV_CMD_NAV_TAKEOFF
        1, // current
        0, // param1
        0, // param2
        0, // param3
        NaN, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.altitude,
      ]);
    }
    // Waypoint
    else if (missionItem.type === 'navWaypoint') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        16, // MAV_CMD_NAV_WAYPOINT
        0, // current
        0, // param1
        0, // param2
        0, // param3
        NaN, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.altitude,
      ]);

      // 속도 변경하는 경우
      if (missionItem.data.speed) {
        messages.push([
          messages.length,
          2, // MAV_FRAME_MISSION
          178, // MAV_CMD_DO_CHANGE_SPEED
          0, // current
          1, // speed type: SPEED_TYPE_GROUNDSPEED
          missionItem.data.speed, // speed
          -1, // throttle: no change
          0, // param4
          0, // x
          0, // y
          0, // z
        ]);
      }
    }
  });
  return messages;
};

const fromMavLink = (messages) => {
  const missionItems = [];
  messages.forEach((message, index) => {
    // 16: MAV_CMD_NAV_WAYPOINT
    if (message.command === 16) {
      missionItems.push({
        id: index,
        type: 'navWaypoint',
        data: {
          name: '',
          position: {
            lat: message.x / 10000000,
            lng: message.y / 10000000,
          },
          altitude: message.z,
        },
      });
    }
    // 18: MAV_CMD_NAV_LOITER_TURNS
    else if (message.command === 18) {
      //! This message is not used in PX4.
    }
    // 19: MAV_CMD_NAV_LOITER_TIME
    else if (message.command === 19) {
      missionItems.push({
        id: index,
        type: 'navLoiterTime',
        data: {
          loiterTime: message.param1,
          position: {
            lat: message.x / 10000000,
            lng: message.y / 10000000,
          },
          altitude: message.z,
        },
      });
    }
    // 20: MAV_CMD_NAV_RETURN_TO_LAUNCH
    else if (message.command === 20) {
      missionItems.push({
        id: index,
        type: 'navReturnToLaunch',
      });
    }
    // 21: MAV_CMD_NAV_LAND
    else if (message.command === 21) {
      missionItems.push({
        id: index,
        type: 'navLand',
        data: {
          position: {
            lat: message.x / 10000000,
            lng: message.y / 10000000,
          },
        },
      });
    }
    // 22: MAV_CMD_NAV_TAKEOFF
    else if (message.command === 22) {
      missionItems.push({
        id: index,
        type: 'navTakeoff',
        data: {
          name: '',
          position: {
            lat: message.x / 10000000,
            lng: message.y / 10000000,
          },
          altitude: message.z,
        },
      });
    }
    // 30: MAV_CMD_NAV_CONTINUE_AND_CHANGE_ALT
    else if (message.command === 30) {
      //! This message is not used in PX4.
    }
    // 31: MAV_CMD_NAV_LOITER_TO_ALT
    else if (message.command === 31) {
      missionItems.push({
        id: index,
        type: 'navLoiterToAlt',
        data: {
          position: {
            lat: message.x / 10000000,
            lng: message.y / 10000000,
          },
          altitude: message.z,
        },
      });
    }
    // 92: MAV_CMD_NAV_GUIDED_ENABLE
    else if (message.command === 92) {
      //! This message is not used in PX4.
    }
    // 93: MAV_CMD_NAV_DELAY
    else if (message.command === 93) {
      missionItems.push({
        id: index,
        type: 'navDelay',
        data: {
          hold: message.param1,
          hour: message.param2,
          minute: message.param3,
          second: message.param4,
        },
      });
    }
    // 114: MAV_CMD_CONDITION_DISTANCE
    else if (message.command === 114) {
      //! This message is not used in PX4.
    }
    // 115: MAV_CMD_CONDITION_YAW
    else if (message.command === 115) {
      //! This message is not used in PX4.
    }
    // 177: MAV_CMD_DO_JUMP
    else if (message.command === 177) {
      missionItems.push({
        id: index,
        type: 'doJump',
        data: {
          item: message.param1 + 1,
          repeat: message.param2,
        },
      });
    }
    // 178: MAV_CMD_DO_CHANGE_SPEED
    else if (message.command === 178) {
      missionItems.push({
        id: index,
        type: 'doChangeSpeed',
        data: {
          type: message.param1,
          speed: message.param2,
          throttle: message.param3,
        },
      });
    }
    // 181: MAV_CMD_DO_SET_RELAY
    else if (message.command === 181) {
      //! This message is not used in PX4.
    }
    // 182: MAV_CMD_DO_REPEAT_RELAY
    else if (message.command === 182) {
      //! This message is not used in PX4.
    }
    // 183: MAV_CMD_DO_SET_SERVO
    else if (message.command === 183) {
      missionItems.push({
        id: index,
        type: 'doSetServo',
        data: {
          servo: message.param1,
          pwm: message.param2,
        },
      });
    }
    // 184: MAV_CMD_DO_REPEAT_SERVO
    else if (message.command === 184) {
      //! This message is not used in PX4.
    }
    // 195: MAV_CMD_DO_SET_ROI_LOCATION
    else if (message.command === 195) {
      missionItems.push({
        id: index,
        type: 'doSetRoiLocation',
        data: {
          position: {
            lat: message.x / 10000000,
            lng: message.y / 10000000,
          },
          altitude: message.z,
        },
      });
    }
    // 197: MAV_CMD_DO_SET_ROI_NONE
    else if (message.command === 197) {
      missionItems.push({
        id: index,
        type: 'doSetRoiNone',
      });
    }
    // 206: MAV_CMD_DO_SET_CAM_TRIGG_DIST
    else if (message.command === 206) {
      missionItems.push({
        id: index,
        type: 'doSetCamTriggDist',
        data: { distance: message.param1 },
      });
    }
    // 208: MAV_CMD_DO_PARACHUTE
    else if (message.command === 208) {
      //! This message is not used in PX4.
    }
    // 211: MAV_CMD_DO_GRIPPER
    else if (message.command === 211) {
      missionItems.push({
        id: index,
        type: 'doGripper',
        data: {
          gripper: message.param1,
          action: message.param2,
        },
      });
    }
    // 212: MAV_CMD_DO_AUTOTUNE_ENABLE
    else if (message.command === 212) {
      //! This message is not used in PX4.
    }
    // 222: MAV_CMD_DO_GUIDED_LIMITS
    else if (message.command === 222) {
      //! This message is not used in PX4.
    }
  });

  return missionItems;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { toMavLink, fromMavLink };
