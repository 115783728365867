import useMountEffect from '@restart/hooks/useMountEffect';
import axios from 'axios';
import classNames from 'classnames/bind';
import React, { useState, useRef, useLayoutEffect } from 'react';
import { RiRefreshLine, RiCloseLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';

import styles from './Panel.module.scss';
import ViewFinder from './ViewFinder';

import actions from '@/actions';

const cx = classNames.bind(styles);

const Panel = ({ data: robot }) => {
  const dispatch = useDispatch();
  const [videoUrls, setVideoUrls] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef();
  const isDragging = useRef(false);
  const offset = useRef({ x: 0, y: 0 });

  useLayoutEffect(() => {
    const dashboard = document.getElementById(`dashboard/${robot.id}`);
    const { top, right } = dashboard.getBoundingClientRect();

    const nextTop = Math.max(0, top); // Dashboard가 화면 상단을 벗어난 경우
    const nextLeft = right + 8; // Margin (8)

    containerRef.current.style.top = `${nextTop}px`;
    containerRef.current.style.left = `${nextLeft}px`;
  }, []);

  useMountEffect(() => {
    load();
  });

  const load = () => {
    if (!robot.metadata['VIDEO_TYPE']) {
      setVideoUrls(null);
      return;
    }

    const body = { type: robot.metadata['VIDEO_TYPE'] };
    switch (body.type) {
      // RTSP (e.g. Rifatron)
      case 'RTSP':
        body.payload = {
          url: robot.metadata['VIDEO_RTSP_URL'],
          username: robot.metadata['VIDEO_RTSP_USERNAME'],
          password: robot.metadata['VIDEO_RTSP_PASSWORD'],
        };
        break;

      // 3rd Party WebRTC (e.g. Smatii)
      case 'WebRTC':
        body.payload = {
          url: robot.metadata['VIDEO_WEBRTC_URL'],
          token: robot.metadata['VIDEO_WEBRTC_TOKEN'],
        };
        break;

      default:
        break;
    }

    setIsLoading(true);
    axios.post(`${process.env.REACT_APP_STREAMHUB_URL}/robots/${robot.id}`, body).then((result) => {
      const { success, data } = result.data;

      setVideoUrls(success ? data.urls : []);
      setTimeout(() => setIsLoading(false), 1000);
    });
  };

  const startDrag = (e) => {
    isDragging.current = true;
    containerRef.current.style.pointerEvents = 'none';

    const { top, left } = containerRef.current.getBoundingClientRect();
    offset.current.x = e.clientX - left;
    offset.current.y = e.clientY - top;

    document.addEventListener('mousemove', onDrag);
    document.addEventListener('mouseup', endDrag);
  };

  const onDrag = (e) => {
    if (!isDragging.current) return;

    let nextLeft = e.clientX - offset.current.x;
    let nextTop = e.clientY - offset.current.y;

    const maxLeft = window.innerWidth - containerRef.current.offsetWidth;
    const maxTop = window.innerHeight - containerRef.current.offsetHeight - 56; // Bottom Bar's Height (56)

    // 수평 이동 제한
    nextLeft = Math.max(0, Math.min(nextLeft, maxLeft));
    // 수직 이동 제한
    nextTop = Math.max(56, Math.min(nextTop, maxTop)); // Topbar's Height (56)

    containerRef.current.style.left = `${nextLeft}px`;
    containerRef.current.style.top = `${nextTop}px`;
  };

  const endDrag = () => {
    isDragging.current = false;
    containerRef.current.style.pointerEvents = 'auto';

    document.removeEventListener('mousemove', onDrag);
    document.removeEventListener('mouseup', endDrag);
  };

  const close = () => {
    dispatch(actions.robotOptions.toggle(robot.id, 'video'));
  };

  return (
    <div ref={containerRef} className={cx(['container', { show: videoUrls !== undefined }])}>
      <div className={cx('color')} style={{ backgroundColor: robot.color }} />
      <div className={cx('header')}>
        <div className={cx('button')} onClick={load}>
          <RiRefreshLine size={12} color="white" title="Refresh" />
        </div>
        <div className={cx('handle')} onMouseDown={startDrag} />
        <div className={cx('button')} onClick={close}>
          <RiCloseLine size={16} color="white" />
        </div>
      </div>
      <div className={cx('body')}>
        {isLoading && (
          <div className={cx('empty')}>
            <div className={cx(['title', 'loading'])}>Loading</div>
            <div className={cx('desc')}>Searching for a video channels.</div>
          </div>
        )}
        {!isLoading && (
          <>
            {videoUrls === null && (
              <div className={cx('empty')}>
                <div className={cx('title')}>No Camera</div>
                <div className={cx('desc')}>No video stream settings.</div>
              </div>
            )}
            {videoUrls?.length === 0 && (
              <div className={cx('empty')}>
                <div className={cx('title')}>No Channels</div>
                <div className={cx('desc')}>Video channel not detected.</div>
              </div>
            )}
            {videoUrls?.map((videoUrl, index) => (
              <ViewFinder key={index} url={videoUrl} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Panel;
