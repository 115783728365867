import React from 'react';
import { useSelector } from 'react-redux';

import MODELS from '@/models';

const Mappers = () => {
  const robots = useSelector((state) => state.robots);

  return (
    <>
      {robots
        .filter(({ isActive }) => isActive)
        .map((robot) => {
          const MapMapper = MODELS[robot.model.id]?.MapMapper;
          if (!MapMapper) return null;

          return <MapMapper key={robot.id} data={robot} />;
        })}
    </>
  );
};

export default Mappers;
