import classNames from 'classnames/bind';
import React, { useContext, useRef, useMemo } from 'react';

import styles from './index.module.scss';

import { MOUNT_POINTS } from '@/define/mountPoints';
import { NTRIPContext } from '@/helpers/NTRIPProvider';
import { NotifierService as notifier } from '@/libs/Notifier';

const cx = classNames.bind(styles);

const Ntrip = ({ robot }) => {
  const ntrip = useContext(NTRIPContext);
  const domRefs = useRef({
    host: useRef(),
    port: useRef(),
    username: useRef(),
    password: useRef(),
    mountPoint: useRef(),
  }).current;

  const isConnecting = useMemo(() => {
    return ntrip.robotOptions.allIds.includes(robot.id);
  }, [ntrip.robotOptions]);

  const options = useMemo(() => {
    if (ntrip.robotOptions.allIds.includes(robot.id)) {
      return ntrip.robotOptions.byId[robot.id];
    }

    return {
      host: 'www.gnssdata.or.kr',
      port: '2101',
      username: '',
      password: '',
      mountPoint: '',
    };
  }, [ntrip.robotOptions]);

  const validAll = () => {
    const host = domRefs.host.current;
    const port = domRefs.port.current;
    const username = domRefs.username.current;
    const password = domRefs.password.current;
    const mountPoint = domRefs.mountPoint.current;

    if (host.value.trim() === '') {
      notifier.error('Please enter a host.');
      host.focus();
      return false;
    }
    if (port.value.trim() === '') {
      notifier.error('Please enter a port.');
      port.focus();
      return false;
    }
    if (username.value.trim() === '') {
      notifier.error('Please enter a username.');
      username.focus();
      return false;
    }
    if (password.value.trim() === '') {
      notifier.error('Please enter a password.');
      password.focus();
      return false;
    }
    if (mountPoint.value === '') {
      notifier.error('Please select a mount point.');
      mountPoint.focus();
      return false;
    }
    return true;
  };

  const doConnect = () => {
    if (!validAll()) return;

    const options = {
      host: domRefs.host.current.value,
      port: domRefs.port.current.value,
      username: domRefs.username.current.value,
      password: domRefs.password.current.value,
      mountPoint: domRefs.mountPoint.current.value,
    };
    ntrip.connect(robot.id, options);
  };

  const doDisconnect = () => {
    ntrip.disconnect(robot.id);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('rows')}>
        <div className={cx('row')}>
          <div className={cx('label')}>Host</div>
          <input
            ref={domRefs.host}
            type="text"
            name="host"
            defaultValue={options.host}
            readOnly={isConnecting}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.target.blur();
              }
            }}
          />
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>Port</div>
          <input
            ref={domRefs.port}
            type="number"
            name="port"
            defaultValue={options.port}
            readOnly={isConnecting}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.target.blur();
              }
            }}
          />
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>Username</div>
          <input
            ref={domRefs.username}
            type="text"
            name="username"
            defaultValue={options.username}
            readOnly={isConnecting}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.target.blur();
              }
            }}
          />
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>Password</div>
          <input
            ref={domRefs.password}
            type="password"
            name="password"
            defaultValue={options.password}
            readOnly={isConnecting}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.target.blur();
              }
            }}
          />
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>Mount Point</div>
          <select ref={domRefs.mountPoint} name="mountPoint" defaultValue={options.mountPoint} disabled={isConnecting}>
            <option value="">Select</option>
            {MOUNT_POINTS.map((mountPoint) =>
              mountPoint.rtcmTypes.map((rtcmType, index) => (
                <option key={index} value={rtcmType}>
                  {mountPoint.name} | {rtcmType}
                </option>
              ))
            )}
          </select>
        </div>
      </div>
      <div className={cx('buttons')}>
        {!isConnecting && (
          <button type="button" className={cx('button')} onClick={doConnect}>
            Connect
          </button>
        )}
        {isConnecting && (
          <button type="button" className={cx('button')} onClick={doDisconnect}>
            Disconnect
          </button>
        )}
      </div>
    </div>
  );
};

export default Ntrip;
