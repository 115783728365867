import React from 'react';

import Copter from './Copter';
import Plane from './Plane';

const FailSafe = ({ robot }) => {
  if (robot.model.name === 'Copter') {
    return <Copter robot={robot} />;
  }

  if (['VTOL', 'Fixed-wing'].includes(robot.model.name)) {
    return <Plane robot={robot} />;
  }

  return null;
};

export default FailSafe;
