import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import {
  point,
  envelope,
  lineChunk,
  lineString,
  destination,
  transformRotate,
  degreesToRadians,
  featureCollection,
} from '@turf/turf';
import { useSelector, useDispatch } from 'react-redux';

import actions from '@/actions';
import { getMissionItemForSurveySegment } from '@/helpers/MissionConverter';
import CoordUtils from '@/utils/CoordUtils';

const useEditorShoots = () => {
  const dispatch = useDispatch();
  const missionItems = useSelector((state) => state.mission.json.missionItems);

  useUpdateEffect(() => {
    const shoots = [];
    missionItems
      .filter((missionItem) => missionItem.data?.camera)
      .forEach((missionItem) => {
        const surveyMissionItem = getMissionItemForSurveySegment(missionItem);
        const { altitude, rotate, positions, camera } = surveyMissionItem.data;
        const shootPaths = getShootPaths(positions);

        shootPaths.forEach((shootPath, pathIndex) => {
          const shootPoints = getShootPoints(shootPath, camera.interval);

          shootPoints.forEach((shootPoint, pointIndex) => {
            let id;
            // 촬영 시작점
            if (pointIndex === 0) {
              id = `${missionItem.id}/${pathIndex}/camera/start`;
            }
            // 촬영 중간점
            else if (pointIndex < shootPoints.length - 1) {
              id = `${missionItem.id}/${pathIndex}/${pointIndex}`;
            }
            // 촬영 종료점
            else {
              id = `${missionItem.id}/${pathIndex}/camera/end`;
            }

            shoots.push({
              id,
              position: CoordUtils.objectFromArray(shootPoint),
              mslAltitude: missionItems[0].data.elevation + altitude,
              boundary: getBoundary(shootPoint, rotate - 90, altitude, camera.options.aov),
            });
          });
        });
      });

    dispatch(actions.editor.loadShoots(shoots));
  }, [missionItems]);

  const getShootPaths = (positions) => {
    const triggerPositions = positions
      .filter((position) => Object.hasOwn(position, 'shoot'))
      .map((position) => CoordUtils.arrayFromObject(position));

    const paths = [];
    triggerPositions.forEach((triggerPosition, index) => {
      if (index % 2) {
        paths.push([triggerPositions[index - 1], triggerPosition]);
      }
    });
    return paths;
  };

  const getShootPoints = (path, interval) => {
    // Interval 의한 경로 분할
    const subpaths = lineChunk(lineString(path), interval / 1000).features;
    // 분할 경로 각 시작점 정의
    const shootPoints = subpaths.map((subpath) => subpath.geometry.coordinates[0]);
    // 경로 종료점 추가
    shootPoints.push(path[1]);

    return shootPoints;
  };

  const getBoundary = (coordinates, heading, altitude, aov) => {
    // 촬영 지점으로부터 전방, 측면 거리
    const forwardDistance = getDistanceToSide(aov.vertical, altitude);
    const sideDistance = getDistanceToSide(aov.horizontal, altitude);

    // 상하 좌표
    const [top, bottom] = [0, 180].map((degree) => getMovedCoordinate(coordinates, forwardDistance / 1000, degree));
    // 좌우 좌표
    const [left, right] = [-90, 90].map((degree) => getMovedCoordinate(coordinates, sideDistance / 1000, degree));

    // 촬영 영역 생성
    const points = featureCollection([point(top), point(right), point(bottom), point(left)]);
    const polygon = envelope(points);

    // 촬영 영역 회전
    const rotated = transformRotate(polygon, heading);
    const coords = rotated.geometry.coordinates[0];

    return coords.map((coord) => CoordUtils.objectFromArray(coord));
  };

  const getDistanceToSide = (degree, altitude) => {
    return Math.tan(degreesToRadians(degree / 2)) * altitude;
  };

  const getMovedCoordinate = (origin, distance, direction) => {
    const movedPoint = destination(origin, distance, direction);
    return movedPoint.geometry.coordinates;
  };
};

export default useEditorShoots;
