import classNames from 'classnames/bind';
import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import MissionItemRow from '@/components/ui/MissionItemRow';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const domRefs = useRef({
    servo: useRef(),
    pwm: useRef(),
  }).current;

  useEffect(() => {
    domRefs.servo.current.value = missionItem.data.servo;
    domRefs.pwm.current.value = missionItem.data.pwm;
  }, [missionItem.data]);

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = Number(e.target.value);
    dispatch(actions.mission.updateMissionItem(index, name, value));
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Servo Instance Number">
        <input
          ref={domRefs.servo}
          name="servo"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Pulse Width Modulation">
        <input
          ref={domRefs.pwm}
          name="pwm"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
    </div>
  );
};

export default MissionItem;
