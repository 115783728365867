import produce from 'immer';
import { handleActions } from 'redux-actions';

const initialState = {};

export default handleActions(
  {
    'ROBOT_OPTIONS/RESET': (state, action) => initialState,
    'ROBOT_OPTIONS/INIT': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;

        draft[robotId] = {
          label: true,
          video: false,
        };
      }),
    'ROBOT_OPTIONS/REMOVE': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;

        delete draft[robotId];
      }),
    'ROBOT_OPTIONS/TOGGLE': (state, action) =>
      produce(state, (draft) => {
        const { robotId, key } = action.payload;

        draft[robotId][key] = !state[robotId][key];
      }),
  },
  initialState
);
