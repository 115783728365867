import produce from 'immer';
import { handleActions } from 'redux-actions';

const initialState = [];

export default handleActions(
  {
    'ROBOTS/RESET': (state, action) => initialState,
    'ROBOTS/LOAD': (state, action) => {
      const { robots } = action.payload;

      return robots.map((robot) => {
        robot.isActive = false;
        robot.isShow = false;
        return robot;
      });
    },
    'ROBOTS/ACTIVATE': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;

        const index = state.findIndex(({ id }) => id === robotId);
        draft[index].isActive = true;
      }),
    'ROBOTS/DEACTIVATE': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;

        const index = state.findIndex(({ id }) => id === robotId);
        draft[index].isActive = false;
      }),
    'ROBOTS/TOGGLE_SHOW': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;

        const index = state.findIndex(({ id }) => id === robotId);
        draft[index].isShow = !state[index].isShow;
      }),
    'ROBOTS/CHANGE_COLOR': (state, action) =>
      produce(state, (draft) => {
        const { robotId, color } = action.payload;

        const index = state.findIndex(({ id }) => id === robotId);
        draft[index].color = color;
      }),
    'ROBOTS/UPDATE_VIDEO': (state, action) =>
      produce(state, (draft) => {
        const { robotId, data } = action.payload;

        const index = state.findIndex(({ id }) => id === robotId);
        // 'VIDEO'로 시작하는 metadata 제거
        Object.keys(state[index].metadata).forEach((key) => {
          if (key.startsWith('VIDEO')) {
            delete draft[index].metadata[key];
          }
        });

        switch (data.type) {
          case 'None':
            break;

          case 'Kurento':
            draft[index].metadata['VIDEO_TYPE'] = data.type;
            break;

          case 'RTSP':
            draft[index].metadata['VIDEO_TYPE'] = data.type;
            draft[index].metadata['VIDEO_RTSP_URL'] = data.url;
            draft[index].metadata['VIDEO_RTSP_USERNAME'] = data.username;
            draft[index].metadata['VIDEO_RTSP_PASSWORD'] = data.password;
            break;

          case 'WebRTC':
            draft[index].metadata['VIDEO_TYPE'] = data.type;
            draft[index].metadata['VIDEO_WEBRTC_URL'] = data.url;
            draft[index].metadata['VIDEO_WEBRTC_TOKEN'] = data.token;
            break;

          default:
            break;
        }
      }),
  },
  initialState
);
