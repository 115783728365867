import classNames from 'classnames/bind';
import React from 'react';
import { RiFullscreenLine, RiCrosshairLine, RiScreenshot2Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import styles from './MapToolbar.module.scss';

import actions from '@/actions';
import Bar from '@/components/ui/Bar';
import VWorldMap from '@/helpers/VWorldMap';

const cx = classNames.bind(styles);

const MapToolbar = () => {
  const dispatch = useDispatch();
  const isShowShoots = useSelector((state) => state.map.isShowShoots);

  const toggleShoots = () => {
    dispatch(actions.map.toggleShoots());
  };

  const fitBounds = () => {
    VWorldMap.fitBounds();
  };

  const moveToCurrent = () => {
    const { height } = window.ws3d.viewer.camera.positionCartographic;

    window.navigator.geolocation.getCurrentPosition(({ coords }) => {
      window.ws3d.viewer.camera.flyTo({
        destination: window.ws3d.common.Cartesian3.fromDegrees(coords.longitude, coords.latitude, height),
        duration: 0,
      });
    });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('group')}>
        <div className={cx(['button', { active: isShowShoots }])} onClick={toggleShoots}>
          <RiScreenshot2Line size={18} title={isShowShoots ? 'Hide Shoots' : 'Show Shoots'} />
        </div>
        <Bar />
        <div className={cx('button')} onClick={fitBounds}>
          <RiFullscreenLine size={18} title="Fit Bounds" />
        </div>
        <Bar />
        <div className={cx('button')} onClick={moveToCurrent}>
          <RiCrosshairLine size={18} title="Current Location" />
        </div>
      </div>
    </div>
  );
};

export default MapToolbar;
