import produce from 'immer';
import { handleActions } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';

import { SURVEY_DEFAULT_OPTIONS } from '@/config';
import { deepCopy, nestedAssign } from '@/utils/ObjectUtils';

const initialState = {
  id: null,
  name: '',
  json: {
    specs: {
      protocol: null,
      firmware: null,
      frame: null,
    },
    missionItems: [],
  },
};

export default handleActions(
  {
    'MISSION/SET_SPECS': (state, action) =>
      produce(state, (draft) => {
        draft.json.specs = action.payload.specs;
      }),
    'MISSION/CHANGE_NAME': (state, action) =>
      produce(state, (draft) => {
        draft.name = action.payload.name;
      }),
    'MISSION/LOAD': (state, action) =>
      produce(state, (draft) => {
        const { id, name, json } = action.payload;
        draft.id = id || uuidv4();
        draft.name = name;
        draft.json = json;
      }),
    'MISSION/APPEND_WAYPOINT': (state, action) =>
      produce(state, (draft) => {
        const { waypoint } = action.payload;

        const newMissionItem = {
          id: uuidv4(),
          type: state.json.missionItems.length === 0 ? 'navTakeoff' : 'navWaypoint',
          data: {
            name: '',
            ...waypoint,
          },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/INSERT_WAYPOINT': (state, action) =>
      produce(state, (draft) => {
        const { index, waypoint } = action.payload;

        const newMissionItem = {
          id: uuidv4(),
          type: 'navWaypoint',
          data: {
            name: '',
            ...waypoint,
          },
        };
        const missionItems = deepCopy(state.json.missionItems);
        missionItems.splice(index + 1, 0, newMissionItem);
        draft.json.missionItems = missionItems;
      }),
    'MISSION/MOVE_WAYPOINT': (state, action) =>
      produce(state, (draft) => {
        const { id, waypoint } = action.payload;

        const index = state.json.missionItems.findIndex((missionItem) => missionItem.id === id);
        if (index === -1) return;

        const missionItems = deepCopy(state.json.missionItems); // to avoid read-only property
        missionItems[index].data.position = waypoint.position;
        missionItems[index].data.elevation = waypoint.elevation;
        draft.json.missionItems = missionItems;
      }),
    'MISSION/EDIT_WAYPOINT': (state, action) =>
      produce(state, (draft) => {
        const { index, name, value } = action.payload;

        const missionItems = deepCopy(state.json.missionItems);
        nestedAssign(missionItems[index].data, name.split('.'), value);
        draft.json.missionItems = missionItems;
      }),
    'MISSION/EDIT_ALL_WAYPOINTS': (state, action) =>
      produce(state, (draft) => {
        if (state.json.missionItems.length === 0) return;

        const { name, value } = action.payload;

        // Relative Altitude
        if (name === 'relAltitude') {
          draft.json.missionItems = state.json.missionItems.map((missionItem) => {
            if (
              ['navLoiterTime', 'navLoiterToAlt', 'navLoiterTurns', 'navTakeoff', 'navWaypoint', 'cusSurvey'].includes(
                missionItem.type
              )
            ) {
              const newMissionItem = deepCopy(missionItem);
              newMissionItem.data.altitude = value;
              return newMissionItem;
            }
            return missionItem;
          });
        }
        // AGL Altitude
        else if (name === 'aglAltitude') {
          const homeElevation = state.json.missionItems[0].data.elevation;
          draft.json.missionItems = state.json.missionItems.map((missionItem) => {
            if (['navLoiterTime', 'navLoiterToAlt', 'navLoiterTurns', 'navWaypoint'].includes(missionItem.type)) {
              const newMissionItem = deepCopy(missionItem);
              newMissionItem.data.altitude = value - homeElevation + missionItem.data.elevation;
              return newMissionItem;
            }
            return missionItem;
          });
        }
        // MSL Altitude
        else if (name === 'mslAltitude') {
          const homeElevation = state.json.missionItems[0].data.elevation;
          draft.json.missionItems = state.json.missionItems.map((missionItem) => {
            if (['navLoiterTime', 'navLoiterToAlt', 'navLoiterTurns', 'navWaypoint'].includes(missionItem.type)) {
              const newMissionItem = deepCopy(missionItem);
              newMissionItem.data.altitude = value - homeElevation;
              return newMissionItem;
            }
            return missionItem;
          });
        }
      }),
    'MISSION/APPEND_SURVEY': (state, action) =>
      produce(state, (draft) => {
        const { boundary, positions, altitude } = action.payload;

        const newMissionItem = {
          id: uuidv4(),
          type: 'cusSurvey',
          data: {
            ...SURVEY_DEFAULT_OPTIONS,
            boundary,
            positions,
            altitude,
          },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/EDIT_SURVEY': (state, action) =>
      produce(state, (draft) => {
        const { index, values } = action.payload;

        const missionItems = deepCopy(state.json.missionItems);
        Object.entries(values).forEach(([name, value]) => {
          nestedAssign(missionItems[index].data, name.split('.'), value);
        });
        draft.json.missionItems = missionItems;
      }),
    'MISSION/APPEND_GRIPPER': (state, action) =>
      produce(state, (draft) => {
        const newMissionItem = {
          id: uuidv4(),
          type: 'doGripper',
          data: {
            gripper: 1,
            action: 0, // 0: Release, 1: Grab
          },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/APPEND_SET_RELAY': (state, action) =>
      produce(state, (draft) => {
        const newMissionItem = {
          id: uuidv4(),
          type: 'doSetRelay',
          data: {
            relay: 0,
            setting: 1,
          },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/APPEND_CYCLE_RELAY': (state, action) =>
      produce(state, (draft) => {
        const newMissionItem = {
          id: uuidv4(),
          type: 'doRepeatRelay',
          data: {
            relay: 0,
            count: 1,
            time: 10,
          },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/APPEND_SET_SERVO': (state, action) =>
      produce(state, (draft) => {
        const newMissionItem = {
          id: uuidv4(),
          type: 'doSetServo',
          data: {
            servo: 1,
            pwm: 1500,
          },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/APPEND_CYCLE_SERVO': (state, action) =>
      produce(state, (draft) => {
        const newMissionItem = {
          id: uuidv4(),
          type: 'doRepeatServo',
          data: {
            servo: 1,
            pwm: 1000,
            count: 1,
            time: 10,
          },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/APPEND_DELAY_UNTIL': (state, action) =>
      produce(state, (draft) => {
        const newMissionItem = {
          id: uuidv4(),
          type: 'navDelay',
          data: {
            hold: 30,
            hour: 0,
            minute: 0,
            second: 0,
          },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/APPEND_JUMP_TO_ITEM': (state, action) =>
      produce(state, (draft) => {
        const newMissionItem = {
          id: uuidv4(),
          type: 'doJump',
          data: {
            item: 1,
            repeat: 10,
          },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/APPEND_CHANGE_ALTITUDE': (state, action) =>
      produce(state, (draft) => {
        const { altitude } = action.payload;

        const newMissionItem = {
          id: uuidv4(),
          type: 'navContinueAndChangeAlt',
          data: { mode: 1, altitude },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/APPEND_CHANGE_SPEED': (state, action) =>
      produce(state, (draft) => {
        const { speed } = action.payload;

        const newMissionItem = {
          id: uuidv4(),
          type: 'doChangeSpeed',
          data: { type: 0, speed },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/EDIT_CHANGE_SPEED': (state, action) =>
      produce(state, (draft) => {
        const { index, name, value } = action.payload;

        const missionItems = deepCopy(state.json.missionItems);
        // 값이 undefined 인 경우 필드 제거
        if (value === undefined) {
          delete missionItems[index].data[name];
        } else {
          missionItems[index].data[name] = value;
        }
        draft.json.missionItems = missionItems;
      }),
    'MISSION/APPEND_WAIT_FOR_DISTANCE': (state, action) =>
      produce(state, (draft) => {
        const newMissionItem = {
          id: uuidv4(),
          type: 'condDistance',
          data: {
            distance: 10,
          },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/APPEND_WAIT_FOR_YAW': (state, action) =>
      produce(state, (draft) => {
        const newMissionItem = {
          id: uuidv4(),
          type: 'condYaw',
          data: {
            direction: 0,
            offset: 0,
            heading: 0,
            rate: 5,
          },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/APPEND_TRIGGER_PARACHUTE': (state, action) =>
      produce(state, (draft) => {
        const newMissionItem = {
          id: uuidv4(),
          type: 'doParachute',
          data: {
            action: 1,
          },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/APPEND_GUIDED_ENABLE': (state, action) =>
      produce(state, (draft) => {
        const newMissionItem = {
          id: uuidv4(),
          type: 'navGuidedEnable',
          data: {
            enable: 1,
          },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/APPEND_GUIDED_LIMITS': (state, action) =>
      produce(state, (draft) => {
        const newMissionItem = {
          id: uuidv4(),
          type: 'doGuidedLimits',
          data: {
            timeout: 0,
            minAlt: 25,
            maxAlt: 100,
            horizMoveLimit: 25,
          },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/APPEND_AUTOTUNE_ENABLE': (state, action) =>
      produce(state, (draft) => {
        const newMissionItem = {
          id: uuidv4(),
          type: 'doAutotuneEnable',
          data: {
            enable: 1,
          },
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/APPEND_SET_ROI_NONE': (state, action) =>
      produce(state, (draft) => {
        const newMissionItem = {
          id: uuidv4(),
          type: 'doSetRoiNone',
        };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
    'MISSION/RESET_MISSION_ITEMS': (state, action) =>
      produce(state, (draft) => {
        draft.json.missionItems = [];
      }),
    'MISSION/REMOVE_MISSION_ITEM': (state, action) =>
      produce(state, (draft) => {
        const { index } = action.payload;

        const missionItems = [...state.json.missionItems];
        missionItems.splice(index, 1);
        draft.json.missionItems = missionItems;
      }),
    'MISSION/SHIFT_MISSION_ITEM': (state, action) =>
      produce(state, (draft) => {
        const { index, offset } = action.payload;

        const missionItems = [...state.json.missionItems];
        [missionItems[index], missionItems[index + offset]] = [missionItems[index + offset], missionItems[index]];
        draft.json.missionItems = missionItems;
      }),
    'MISSION/UPDATE_MISSION_ITEM': (state, action) =>
      produce(state, (draft) => {
        const { index, name, value } = action.payload;

        const missionItems = deepCopy(state.json.missionItems);
        missionItems[index].data[name] = value;
        draft.json.missionItems = missionItems;
      }),
    'MISSION/CHANGE_MISSION_ITEM_TYPE': (state, action) =>
      produce(state, (draft) => {
        const { index, type } = action.payload;

        const missionItems = deepCopy(state.json.missionItems);
        missionItems[index].type = type;

        switch (type) {
          case 'navLoiterTime':
            missionItems[index].data.loiterTime = 30;
            break;

          case 'navLoiterTurns':
            missionItems[index].data.turns = 1;
            break;

          default:
            break;
        }
        draft.json.missionItems = missionItems;
      }),
    'MISSION/APPEND_RETURN_TO_LAUNCH': (state, action) =>
      produce(state, (draft) => {
        const newMissionItem = { id: uuidv4(), type: 'navReturnToLaunch' };
        draft.json.missionItems = [...state.json.missionItems, newMissionItem];
      }),
  },
  initialState
);
