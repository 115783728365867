import classNames from 'classnames/bind';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import styles from './index.module.scss';
import Panel from './Panel';

const cx = classNames.bind(styles);

const Videos = () => {
  const robots = useSelector((state) => state.robots);
  const robotOptions = useSelector((state) => state.robotOptions);

  const filteredRobots = useMemo(() => {
    const robotIds = Object.keys(robotOptions).filter((id) => robotOptions[id].video);
    return robots.filter(({ id }) => robotIds.includes(id));
  }, [robots, robotOptions]);

  return (
    <div className={cx('container')}>
      {filteredRobots.map((robot) => (
        <Panel key={robot.id} data={robot} />
      ))}
    </div>
  );
};

export default Videos;
