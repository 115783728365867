import { createActions } from 'redux-actions';

export default createActions(
  {
    RESET: () => ({}),
    TOGGLE: (robotId) => ({ robotId }),
    SET_FOCUS: (robotId) => ({ robotId }),
  },
  { prefix: 'ROBOT' }
);
