import classNames from 'classnames/bind';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);

  const setBodyHeight = (e) => {
    doCommand('config/height/stand', e.target.dataset.value);
  };

  const setFootRaise = (e) => {
    doCommand('config/height/raise', e.target.dataset.value);
  };

  const setSpeedLevel = (e) => {
    doCommand('config/speed', e.target.dataset.value);
  };

  const doCommand = (name, value) => {
    robots.forEach((robot) => {
      publishCommand(robot, name, { value });
    });
    dispatch(actions.command.toggle());
  };

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('title')}>Settings</div>
        <div className={cx('message')}>Insert a description for this function.</div>
      </div>
      <div className={cx('rows')}>
        <div className={cx('row')}>
          <div className={cx('label')}>Body Height</div>
          <div className={cx(['buttons', 'settings'])}>
            <div data-value="1" className={cx('button')} onClick={setBodyHeight}>
              Low
            </div>
            <div data-value="2" className={cx('button')} onClick={setBodyHeight}>
              Middle
            </div>
            <div data-value="3" className={cx('button')} onClick={setBodyHeight}>
              High
            </div>
          </div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>Foot Raise</div>
          <div className={cx(['buttons', 'settings'])}>
            <div data-value="1" className={cx('button')} onClick={setFootRaise}>
              Low
            </div>
            <div data-value="2" className={cx('button')} onClick={setFootRaise}>
              Middle
            </div>
            <div data-value="3" className={cx('button')} onClick={setFootRaise}>
              High
            </div>
          </div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>Speed Level</div>
          <div className={cx(['buttons', 'settings'])}>
            <div data-value="1" className={cx('button')} onClick={setSpeedLevel}>
              Slow
            </div>
            <div data-value="2" className={cx('button')} onClick={setSpeedLevel}>
              Normal
            </div>
            <div data-value="3" className={cx('button')} onClick={setSpeedLevel}>
              Fast
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Action;
