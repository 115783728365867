import produce from 'immer';
import { handleActions } from 'redux-actions';

const initialState = {
  config: {
    altitude: 100,
    speed: 2,
  },
  mode: null,
  moveFromIndex: null,
  labels: [],
  markers: {},
  path: [],
  shoots: [],
};

export default handleActions(
  {
    'EDITOR/SET_CONFIG': (state, action) =>
      produce(state, (draft) => {
        draft.config = action.payload.config;
      }),
    'EDITOR/CHANGE_MODE': (state, action) =>
      produce(state, (draft) => {
        draft.mode = action.payload.mode;
      }),
    'EDITOR/SET_MOVE_FROM_INDEX': (state, action) =>
      produce(state, (draft) => {
        draft.moveFromIndex = action.payload.moveFromIndex;
      }),
    'EDITOR/LOAD_LABELS': (state, action) =>
      produce(state, (draft) => {
        draft.labels = action.payload.labels;
      }),
    'EDITOR/LOAD_MARKERS': (state, action) =>
      produce(state, (draft) => {
        draft.markers = action.payload.markers;
      }),
    'EDITOR/LOAD_PATH': (state, action) =>
      produce(state, (draft) => {
        draft.path = action.payload.path;
      }),
    'EDITOR/LOAD_SHOOTS': (state, action) =>
      produce(state, (draft) => {
        draft.shoots = action.payload.shoots;
      }),
  },
  initialState
);
