import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';

import BottomBar from './BottomBar';
import Chart from './Chart';
import GuideMissionList from './GuideMissionList';
import GuideRobotList from './GuideRobotList';
import styles from './index.module.scss';
import Map2D from './Map2D';
import Map3D from './Map3D';
import Panel from './Panel';

import actions from '@/actions';
import AddTakeoffModal from '@/components/modals/AddTakeoff';
import SelectSpecsModal from '@/components/modals/SelectSpecs';
import StartMissionHubModal from '@/components/modals/StartMissionHub';
import WrongMissionModal from '@/components/modals/WrongMission';
import Callout from '@/components/ui/Callout';
import TopBar from '@/components/ui/TopBar';
import API from '@/helpers/API';
import { getMission } from '@/helpers/Requester';
import useEditorLabels from '@/hooks/useEditorLabels';
import useEditorMarkers from '@/hooks/useEditorMarkers';
import useEditorPath from '@/hooks/useEditorPath';
import useEditorShoots from '@/hooks/useEditorShoots';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Page = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();

  useEditorLabels();
  useEditorMarkers();
  useEditorPath();
  useEditorShoots();

  useMountEffect(() => {
    // API: 계정 조회
    API.get('/accounts').then(({ success, data }) => {
      if (success) {
        dispatch(actions.user.load(data));
      }
    });
  });

  useEffect(() => {
    // ~/mission-hub
    if (!params.id) {
      modal.show(StartMissionHubModal);
      return;
    }
    // ~/mission-hub/new
    else if (params.id === 'new') {
      const needSpecs = Object.values(store.getState().mission.json.specs).some((value) => value === null);
      if (needSpecs) {
        modal.show(SelectSpecsModal);
      } else {
        modal.show(AddTakeoffModal);
      }
      return;
    }

    getMission(params.id).then(({ success, data }) => {
      if (success && data) {
        dispatch(actions.mission.load(data));
      } else {
        modal.show(WrongMissionModal);
      }
    });
  }, [params.id]);

  return (
    <>
      <Helmet>
        <title>M1UCS | Mission Hub</title>
      </Helmet>
      <div className={cx('container')}>
        <TopBar />
        <div className={cx('content')}>
          <Map2D />
          <Map3D />
          <div className={cx('leftTop')}>
            <Panel />
          </div>
          <div className={cx('leftBottom')}>
            <Chart />
          </div>
          <div className={cx('rightBottom')}>
            <GuideRobotList />
            <GuideMissionList />
          </div>
        </div>
        <BottomBar />
        <Callout />
      </div>
    </>
  );
};

export default Page;
