import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import { filesize } from 'filesize';
import moment from 'moment';
import React, { useState, useMemo, useRef } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useStore, useDispatch } from 'react-redux';

import styles from './Panel.module.scss';

import actions from '@/actions';
import API from '@/helpers/API';
import { NotifierService as notifier } from '@/libs/Notifier';

const cx = classNames.bind(styles);

const Panel = ({ data: video, onClose }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState();
  const timerId = useRef();

  useUpdateEffect(() => {
    // 영상 선택된 경우
    if (video) {
      setShow(true);

      if (timerId.current) {
        clearTimeout(timerId.current);
        timerId.current = null;
      }

      API.get(`/robots/${video.robotId}/videos/${video.id}`).then(({ success, data }) => {
        if (success) {
          setInfo({ ...video, ...data });
        }
      });
    }
    // 영상 선택되지 않은 경우
    else {
      setShow(false);
      timerId.current = setTimeout(setInfo, 200); // 애니메이션 완료 후 초기화
    }
  }, [video]);

  const paddingTop = useMemo(() => {
    if (!info) return;
    return `calc(100% / ${info.width / info.height})`;
  }, [info]);

  const doAction = (e) => {
    switch (e.currentTarget.name) {
      case 'download':
        downloadVideo();
        break;

      case 'delete':
        deleteVideo();
        break;

      default:
        break;
    }
  };

  const downloadVideo = () => {
    window.open(info.downloadLink.link);
  };

  const deleteVideo = () => {
    const robot = store.getState().robots.find(({ id }) => id === video.robotId);

    // 제어권한 미보유 시
    if (!robot.isOwned) {
      notifier.error('Please confirm whether you are in command of the robot.');
      return;
    }

    dispatch(actions.videos.setVisible(video.id, false));
    onClose();

    API.delete(`/robots/${video.robotId}/videos/${video.id}`).then(({ success }) => {
      if (success) {
        dispatch(actions.videos.delete(video.id));
      } else {
        dispatch(actions.videos.setVisible(video.id, true));
        notifier.error('Video delete failed.');
      }
    });
  };

  return (
    <div className={cx(['container', { show }])}>
      <div className={cx('outside')} onClick={onClose}>
        <div className={cx('close')}>
          <MdOutlineClose color="white" size={24} />
        </div>
      </div>
      <div className={cx('panel')}>
        {info && (
          <div className={cx('wrapper')}>
            <div className={cx('player')} style={{ paddingTop }}>
              <video key={info.id} controls className={cx('video')}>
                <source src={info.playLink.link} type="video/mp4" />
              </video>
            </div>
            <div className={cx('info')}>
              <div className={cx('titleWrapper')}>
                <div className={cx('title')}>{info.name}</div>
                <div className={cx('buttons')}>
                  <button type="button" name="download" className={cx('button')} onClick={doAction}>
                    <div className={cx('label')}>Download</div>
                  </button>
                  <button type="button" name="delete" className={cx('button')} onClick={doAction}>
                    <div className={cx('label')}>Delete</div>
                  </button>
                </div>
              </div>
              {info.tags.length > 0 && (
                <div className={cx('tags')}>
                  {info.tags.map((tag, index) => (
                    <div key={index} className={cx('tag')}>
                      #{tag}
                    </div>
                  ))}
                </div>
              )}
              <div className={cx('descWrapper')}>
                <div className={cx('desc')}>{info.description ?? 'No description'}</div>
                <div className={cx('date')}>{moment(info.createdAt).format('YYYY-MM-DD HH:mm')}</div>
              </div>
              <div className={cx('table')}>
                <div className={cx('header')}>
                  <div className={cx('column')}>Size</div>
                  <div className={cx('column')}>Quality</div>
                  <div className={cx('column')}>Type</div>
                  <div className={cx('column')}>Codec</div>
                  <div className={cx('column')}>FPS</div>
                  <div className={cx('column')}>File Size</div>
                </div>
                <div className={cx('body')}>
                  <div className={cx('column')}>
                    {info.playLink.width} × {info.playLink.height}
                  </div>
                  <div className={cx('column')}>{info.playLink.rendition}</div>
                  <div className={cx('column')}>{info.playLink.type}</div>
                  <div className={cx('column')}>{info.playLink.codec}</div>
                  <div className={cx('column')}>{info.playLink.fps.toFixed(1)}</div>
                  <div className={cx('column')}>{filesize(info.downloadLink.size, { standard: 'jedec' })}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Panel;
