import classNames from 'classnames/bind';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import MissionItemRow from '@/components/ui/MissionItemRow';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const domRefs = useRef({
    enable: useRef(),
  }).current;

  useEffect(() => {
    domRefs.enable.current.value = missionItem.data.enable;
  }, [missionItem.data]);

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = Number(e.target.value);
    dispatch(actions.mission.updateMissionItem(index, name, value));
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Enable">
        <select ref={domRefs.enable} name="enable" onChange={handleBlur}>
          <option value="1">Enable</option>
          <option value="0">Disable</option>
        </select>
      </MissionItemRow>
    </div>
  );
};

export default MissionItem;
