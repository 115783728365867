import useMountEffect from '@restart/hooks/useMountEffect';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useStore, useSelector } from 'react-redux';

import RobotMarker from '@/components/ui/map/RobotMarker';
import OlMap from '@/helpers/OlMap';
import EventEmitter from '@/libs/EventEmitter';

const Robot = ({ data: robot }) => {
  const store = useStore();

  useMountEffect(() => {
    const element = getMarkerElement();
    const subscribeToken = EventEmitter.subscribe(`${robot.id}/telemetry/globalPosition`, ({ lat, lng, hdg }) => {
      element.style.transform = `rotate(${hdg}deg)`;

      OlMap.addRobot(robot.id, element, { lat, lng });
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
      OlMap.removeRobot(robot.id);
    };
  });

  const getMarkerElement = () => {
    const content = document.createElement('div');

    createRoot(content).render(
      <Provider store={store}>
        <RobotMarker data={robot} />
      </Provider>
    );
    return content;
  };
};

const GuideRobots = () => {
  const robots = useSelector((state) => state.robots);

  return (
    <>
      {robots
        .filter(({ isShow }) => isShow)
        .map((robot) => (
          <Robot key={robot.id} data={robot} />
        ))}
    </>
  );
};

export default GuideRobots;
