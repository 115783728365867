import classNames from 'classnames/bind';
import React from 'react';

import styles from './Monitor.module.scss';

import { Attitude, EKFStatus, Tabs, Vibration } from '@/components/ui/Dashboard';

const cx = classNames.bind(styles);

const Monitor = ({ robotId }) => {
  return (
    <div className={cx('container')}>
      <Tabs tabs={['Attitude', 'EKF Status', 'Vibration']}>
        <Attitude data={robotId} className={cx('attitude')} />
        <EKFStatus data={robotId} />
        <Vibration data={robotId} />
      </Tabs>
    </div>
  );
};

export default Monitor;
