import React from 'react';
import { MdOutlineSatelliteAlt } from 'react-icons/md';

import { Top, TopStatusValue, TopNotificationCount } from '@/components/ui/Dashboard';
import { getPermissionLabel } from '@/components/ui/Dashboard/Top';

const Component = ({ robot }) => {
  return (
    <Top labels={[robot.model.agentType, getPermissionLabel(robot.isOwned)]}>
      <TopStatusValue
        Icon={MdOutlineSatelliteAlt}
        event={`${robot.id}/telemetry`}
        callback={({ sats }) => sats}
        style={{ gap: 4 }}
      />
      <TopNotificationCount robotId={robot.id} />
    </Top>
  );
};

export default Component;
