import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useSelector } from 'react-redux';

import useWaitingRobotTimer from '@/hooks/useWaitingRobotTimer';
import EventEmitter from '@/libs/EventEmitter';
import { isColdPosition } from '@/utils/MapUtils';

const useOrganizer = (robot) => {
  const telemetry = useSelector((state) => state.telemetry[robot.id]);

  useWaitingRobotTimer(robot.id, [telemetry], 10000);

  useUpdateEffect(() => {
    // GPS 데이터 유효하지 않은 경우
    if (isColdPosition(telemetry.lat, telemetry.lon)) return;

    EventEmitter.publish(`${robot.id}/event/footprint`, {
      lat: telemetry.lat,
      lng: telemetry.lon,
    });
    EventEmitter.publish(`${robot.id}/telemetry`, telemetry);
  }, [telemetry]);
};

export default useOrganizer;
