import useMountEffect from '@restart/hooks/useMountEffect';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import actions from '@/actions';
import API from '@/helpers/API';
import { MessageContext } from '@/helpers/MessageProvider/ForMissionHub';

const useLoadRobots = () => {
  const dispatch = useDispatch();
  const { subscribeTelemetry } = useContext(MessageContext);

  useMountEffect(() => {
    // API: 로봇내역 조회
    API.get('/robots').then(({ success, data }) => {
      if (success) {
        const robots = data.filter(({ model }) => model.agentType === 'MavLink');

        dispatch(actions.robots.load(robots));
        subscribeTelemetry(robots);
      }
    });
  });
};

export default useLoadRobots;
