import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import styles from './index.module.scss';
import ProcessingButton from '../ProcessingButton';

import actions from '@/actions';
import API from '@/helpers/API';

const cx = classNames.bind(styles);

const VideoStream = ({ robot }) => {
  const dispatch = useDispatch();
  const [type, setType] = useState(robot.metadata['VIDEO_TYPE'] ?? 'None');
  const [formInfo, setFormInfo] = useState({
    url: '',
    username: '',
    password: '',
    token: '',
  });
  const urlRef = useRef();
  const usernameRef = useRef();
  const passwordRef = useRef();
  const tokenRef = useRef();

  useMountEffect(() => {
    switch (type) {
      case 'RTSP':
        setFormInfo({
          ...formInfo,
          url: robot.metadata['VIDEO_RTSP_URL'],
          username: robot.metadata['VIDEO_RTSP_USERNAME'],
          password: robot.metadata['VIDEO_RTSP_PASSWORD'],
        });
        break;

      case 'WebRTC':
        setFormInfo({
          ...formInfo,
          url: robot.metadata['VIDEO_WEBRTC_URL'],
          token: robot.metadata['VIDEO_WEBRTC_TOKEN'],
        });
        break;

      default:
        break;
    }
  });

  const selectType = (e) => {
    setType(e.target.value);

    urlRef.current.value = '';
    usernameRef.current.value = '';
    passwordRef.current.value = '';
    tokenRef.current.value = '';
  };

  const handleFormChange = (e) => {
    setFormInfo({
      ...formInfo,
      [e.target.name]: e.target.value,
    });
  };

  const doSave = () => {
    const data = { type };
    switch (type) {
      case 'RTSP':
        data.url = formInfo.url;
        data.username = formInfo.username;
        data.password = formInfo.password;
        break;

      case 'WebRTC':
        data.url = formInfo.url;
        data.token = formInfo.token;
        break;

      default:
        break;
    }

    dispatch(actions.robots.updateVideo(robot.id, data));
    return API.patch(`/robots/${robot.id}/metadata/video-config`, data).then((result) => result.success);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('rows')}>
        <div className={cx('row')}>
          <div className={cx('label')}>Type</div>
          <div className={cx('radioGroup')}>
            <label>
              <input type="radio" name="type" value="None" defaultChecked={type === 'None'} onChange={selectType} />
              None
            </label>
            <label>
              <input
                type="radio"
                name="type"
                value="Kurento"
                defaultChecked={type === 'Kurento'}
                onChange={selectType}
              />
              M1Cast
            </label>
            <label>
              <input type="radio" name="type" value="RTSP" defaultChecked={type === 'RTSP'} onChange={selectType} />
              RTSP
            </label>
            <label>
              <input type="radio" name="type" value="WebRTC" defaultChecked={type === 'WebRTC'} onChange={selectType} />
              SmatiiView
            </label>
          </div>
        </div>
        <div className={cx(['row', { hide: ['None', 'Kurento'].includes(type) }])}>
          <div className={cx('label')}>URL</div>
          <input
            ref={urlRef}
            type="text"
            name="url"
            defaultValue={formInfo.url}
            onChange={handleFormChange}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.target.blur();
              }
            }}
          />
        </div>
        <div className={cx(['row', { hide: type !== 'RTSP' }])}>
          <div className={cx('label')}>Username</div>
          <input
            ref={usernameRef}
            type="text"
            name="username"
            defaultValue={formInfo.username}
            onChange={handleFormChange}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.target.blur();
              }
            }}
          />
        </div>
        <div className={cx(['row', { hide: type !== 'RTSP' }])}>
          <div className={cx('label')}>Password</div>
          <input
            ref={passwordRef}
            type="text"
            name="password"
            defaultValue={formInfo.password}
            onChange={handleFormChange}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.target.blur();
              }
            }}
          />
        </div>
        <div className={cx(['row', { hide: type !== 'WebRTC' }])}>
          <div className={cx('label')}>Token</div>
          <input
            ref={tokenRef}
            type="text"
            name="token"
            defaultValue={formInfo.token}
            onChange={handleFormChange}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.target.blur();
              }
            }}
          />
        </div>
      </div>
      <ProcessingButton text="Save" doProcess={doSave} />
    </div>
  );
};

export default VideoStream;
