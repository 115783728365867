import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './index.module.scss';
import Robot from './Robot';

import actions from '@/actions';
import Checkbox from '@/components/ui/Checkbox';
import API from '@/helpers/API';

const cx = classNames.bind(styles);

const SideBar = () => {
  const dispatch = useDispatch();
  const robots = useSelector((state) => state.robots);
  const targetRobotIds = useSelector((state) => state.robot.targetRobotIds);

  const isSelectedAll = useMemo(() => {
    return robots.length > 0 && robots.length === targetRobotIds.length;
  }, [robots, targetRobotIds]);

  useMountEffect(() => {
    API.get('/robots').then(({ success, data }) => {
      if (success) {
        dispatch(actions.robots.load(data));
      }
    });
  });

  const toggleSelectAll = () => {
    if (isSelectedAll) {
      dispatch(actions.robot.reset());
      return;
    }

    robots
      .filter(({ id }) => !targetRobotIds.includes(id))
      .forEach(({ id }) => {
        dispatch(actions.robot.toggle(id));
      });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('header')}>
          <div className={cx('total')}>Total {commaNumber(robots.length)}</div>
          <div className={cx('option')}>
            <Checkbox checked={isSelectedAll} onClick={toggleSelectAll} />
            Select All
          </div>
        </div>
        {robots.map((robot, index) => (
          <Robot key={index} data={robot} />
        ))}
      </div>
    </div>
  );
};

export default SideBar;
