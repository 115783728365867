import classNames from 'classnames/bind';
import React, { useState } from 'react';

import styles from './BinarySwitch.module.scss';

const cx = classNames.bind(styles);

const BinarySwitch = ({ items }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const select = (e) => {
    const nextSelectedIndex = Number(e.currentTarget.dataset.index);
    setSelectedIndex(nextSelectedIndex);

    items[nextSelectedIndex].onClick(e);
  };

  return (
    <div className={cx('container')}>
      <div className={cx(['bar', { left: selectedIndex === 0 }])} />
      <div className={cx('buttons')}>
        <div className={cx('button')} data-index={0} data-value={items[0].value} onClick={select}>
          {items[0].title}
        </div>
        <div className={cx('button')} data-index={1} data-value={items[1].value} onClick={select}>
          {items[1].title}
        </div>
      </div>
    </div>
  );
};

export default BinarySwitch;
