export const sleep = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

export const waitForInspectionStep = () => {
  return sleep(1000);
};

export const waitUntilMissionUpload = () => {
  return sleep(1000);
};

export const waitUntilParamSet = () => {
  return sleep(500);
};
