import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import ErrorBoundary from './components/global/ErrorBoundary';
import NotFound from './components/global/NotFound';
import { ProtectedRoute, UnprotectedRoute } from './helpers/AuthRouter';
import FontLoader from './helpers/FontLoader';
import MessageProvider from './helpers/MessageProvider';
import NTRIPProvider from './helpers/NTRIPProvider';
import StoreProvider from './helpers/StoreProvider';
import { ModalContainer } from './libs/Modal';
import { NotifierContainer } from './libs/Notifier';
import AutoLogin from './pages/autologin';
import ControlCenter from './pages/control-center';
import Intro from './pages/intro';
import Login from './pages/login';
import MissionHub from './pages/mission-hub';
import VideoManager from './pages/video-manager';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <UnprotectedRoute>
            <Intro />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/login"
        element={
          <UnprotectedRoute>
            <Login />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/autologin"
        element={
          <UnprotectedRoute>
            <AutoLogin />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/control-center"
        element={
          <ProtectedRoute>
            <MessageProvider.ForControlCenter>
              <NTRIPProvider>
                <ModalContainer />
                <ControlCenter />
              </NTRIPProvider>
            </MessageProvider.ForControlCenter>
          </ProtectedRoute>
        }
      />
      <Route
        path="/mission-hub/:id?"
        element={
          <ProtectedRoute>
            <MessageProvider.ForMissionHub>
              <ModalContainer />
              <MissionHub />
            </MessageProvider.ForMissionHub>
          </ProtectedRoute>
        }
      />
      <Route
        path="/video-manager"
        element={
          <ProtectedRoute>
            <ModalContainer />
            <VideoManager />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <FontLoader />
      <NotifierContainer duration={5000} />
      <StoreProvider>
        {process.env.REACT_APP_USE_ERROR_BOUNDARY === 'true' && (
          <ErrorBoundary>
            <AppRoutes />
          </ErrorBoundary>
        )}
        {process.env.REACT_APP_USE_ERROR_BOUNDARY !== 'true' && <AppRoutes />}
      </StoreProvider>
    </BrowserRouter>
  );
};

export default App;
