import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

import styles from './index.module.scss';
import Main from './Main';
import SideBar from './SideBar';

import actions from '@/actions';
import TopBar from '@/components/ui/TopBar';
import API from '@/helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const dispatch = useDispatch();

  useMountEffect(() => {
    // API: 계정 조회
    API.get('/accounts').then(({ success, data }) => {
      if (success) {
        dispatch(actions.user.load(data));
      }
    });
  });

  return (
    <>
      <Helmet>
        <title>M1UCS | Video Manager</title>
      </Helmet>
      <div className={cx('container')}>
        <TopBar />
        <div className={cx('content')}>
          <SideBar />
          <Main />
        </div>
      </div>
    </>
  );
};

export default Page;
