import classNames from 'classnames/bind';
import React from 'react';
import { RiLoader2Line } from 'react-icons/ri';

import styles from './Spinner.module.scss';

const cx = classNames.bind(styles);

const Spinner = ({ size = 24, color = 'white' }) => {
  return <RiLoader2Line size={size} color={color} className={cx('spin')} />;
};

export default Spinner;
