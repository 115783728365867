import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useSelector } from 'react-redux';

import useWaitingRobotTimer from '@/hooks/useWaitingRobotTimer';
import EventEmitter from '@/libs/EventEmitter';
import { isColdPosition } from '@/utils/MapUtils';

const useOrganizer = (robot) => {
  const gps = useSelector((state) => state.telemetry[robot.id]?.fix);
  const lowstate = useSelector((state) => state.telemetry[robot.id]?.lowstate);
  const sportmodestate = useSelector((state) => state.telemetry[robot.id]?.sportmodestate);

  useWaitingRobotTimer(robot.id, [lowstate]);

  useUpdateEffect(() => {
    const { latitude: lat, longitude: lng, altitude: alt } = gps;

    // GPS 데이터 유효하지 않은 경우
    if (isColdPosition(lat, lng)) return;

    EventEmitter.publish(`${robot.id}/event/footprint`, { lat, lng });
    EventEmitter.publish(`${robot.id}/telemetry/globalPosition`, { lat, lng, alt });
  }, [gps]);

  useUpdateEffect(() => {
    const { 0: roll, 1: pitch, 2: yaw } = lowstate.imu_state.rpy;
    EventEmitter.publish(`${robot.id}/telemetry/attitude`, { roll, pitch, yaw });

    EventEmitter.publish(`${robot.id}/telemetry/lowstate`, lowstate);
  }, [lowstate]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/sportmodestate`, sportmodestate);
  }, [sportmodestate]);
};

export default useOrganizer;
