import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import VWorldMap from '@/helpers/VWorldMap';
import { getCSSVariable } from '@/utils/theme';

const DIAMETER = 32; // Diameter of the outer circle for marker (in pixels)

const Markers = () => {
  const missionId = useSelector((state) => state.mission.id);
  const markers = useSelector((state) => state.editor.markers);
  const markersRef = useRef({});

  useUpdateEffect(() => {
    Object.values(markersRef.current).forEach(({ entity }) => window.ws3d.viewer.entities.remove(entity));
    markersRef.current = {};

    VWorldMap.fitBounds();
  }, [missionId]);

  useEffect(() => {
    const currMarkerIds = Object.keys(markersRef.current);
    const nextMarkerIds = Object.keys(markers);

    // 마커 추가
    if (currMarkerIds.length < nextMarkerIds.length) {
      const willBeAddedIds = nextMarkerIds.filter((id) => !Object.hasOwn(markersRef.current, id));

      willBeAddedIds.forEach((id) => {
        const entity = getMarkerEntity(id);
        markersRef.current[id] = { ...markers[id], entity };
      });
    }
    // 마커 제거
    else if (nextMarkerIds.length < currMarkerIds.length) {
      const willBeRemovedIds = currMarkerIds.filter((id) => !Object.hasOwn(markers, id));

      willBeRemovedIds.forEach((id) => {
        window.ws3d.viewer.entities.remove(markersRef.current[id].entity);
        delete markersRef.current[id];
      });
    }
    // 마커 이동
    else if (nextMarkerIds.length === currMarkerIds.length) {
      nextMarkerIds.forEach((id) => {
        const oldMarker = markersRef.current[id];
        const newMarker = markers[id];

        const oldPosition = getCartesian3Position(oldMarker.position, oldMarker.mslAltitude);
        const newPosition = getCartesian3Position(newMarker.position, newMarker.mslAltitude);

        if (!newPosition.equals(oldPosition)) {
          markersRef.current[id].position = newMarker.position;
          markersRef.current[id].relAltitude = newMarker.relAltitude;
          markersRef.current[id].mslAltitude = newMarker.mslAltitude;
          markersRef.current[id].entity.position = newPosition;
        }
      });
    }

    // 나머지 마커 라벨 변경
    Object.keys(markersRef.current).forEach((id) => {
      if (markersRef.current[id].label === markers[id].label) return;

      window.ws3d.viewer.entities.remove(markersRef.current[id].entity);
      const entity = getMarkerEntity(id);
      markersRef.current[id] = { ...markers[id], entity };
    });
  }, [markers]);

  const getMarkerEntity = (id) => {
    const { position, mslAltitude, label } = markers[id];

    return window.ws3d.viewer.entities.add({
      position: getCartesian3Position(position, mslAltitude),
      billboard: {
        image: getMarkerImage(label),
        verticalOrigin: window.ws3d.common.VerticalOrigin.BOTTOM,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
      },
    });
  };

  // 마커 이미지
  const getMarkerImage = (label) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = DIAMETER;
    canvas.height = DIAMETER * 1.5;

    const centerX = DIAMETER / 2;
    const centerY = DIAMETER / 2;
    const radius = DIAMETER / 2;

    // 외부 원
    ctx.fillStyle = getCSSVariable('--tint-color');
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
    ctx.fill();

    // 내부 원
    ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius * 0.75, 0, Math.PI * 2);
    ctx.fill();

    // 라벨
    ctx.font = 'bold 12px "Noto Sans KR", sans-serif';
    ctx.fillStyle = 'white';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(label, centerX, centerY);

    // 마커 꼭지점 (삼각형)
    const triangleY = centerY + radius - 4; // offset
    const triangleBase = DIAMETER * 0.75; // 변 길이
    const triangleHeight = triangleY + radius - 1; // 높이

    ctx.fillStyle = getCSSVariable('--tint-color');
    ctx.beginPath();
    ctx.moveTo(centerX - triangleBase / 2, triangleY); // 좌상단
    ctx.lineTo(centerX + triangleBase / 2, triangleY); // 우상단
    ctx.lineTo(centerX, triangleHeight); // 하단 꼭지점
    ctx.closePath();
    ctx.fill();

    return canvas.toDataURL('image/png'); // Base64 변환
  };

  const getCartesian3Position = ({ lat, lng }, mslAltitude) => {
    return window.ws3d.common.Cartesian3.fromDegrees(lng, lat, mslAltitude);
  };

  return null;
};

export default Markers;
