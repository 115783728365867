import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import styles from './Dashboards.module.scss';

import Checkbox from '@/components/ui/Checkbox';
import MODELS from '@/models';

const cx = classNames.bind(styles);

const Dashboards = () => {
  const robots = useSelector((state) => state.robots);
  const [isShowAll, setIsShowAll] = useState(false);

  const filteredRobots = useMemo(() => {
    const draftRobots = robots.filter(({ isActive }) => isActive);
    draftRobots.sort((a, b) => (a.name < b.name ? -1 : 1));

    // 전체 보기 시
    if (isShowAll) {
      // 비활성 로봇 선별 및 정렬
      const inactives = robots.filter(({ isActive }) => !isActive);
      inactives.sort((a, b) => (a.name < b.name ? -1 : 1));

      inactives.forEach((robot) => draftRobots.push(robot));
    }

    return draftRobots;
  }, [robots, isShowAll]);

  const toggleShowAll = () => {
    setIsShowAll(!isShowAll);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('header')}>
          <div className={cx('total')}>Total {commaNumber(filteredRobots.length)}</div>
          <div className={cx('option')}>
            <Checkbox checked={isShowAll} onClick={toggleShowAll} />
            Show All
          </div>
        </div>
        {filteredRobots.map((robot) => {
          const Dashboard = MODELS[robot.model.id]?.Dashboard;
          if (!Dashboard) return null;

          return <Dashboard key={robot.id} data={robot} />;
        })}
      </div>
    </div>
  );
};

export default Dashboards;
