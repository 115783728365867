import classNames from 'classnames/bind';
import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import MissionItemRow from '@/components/ui/MissionItemRow';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const domRefs = useRef({
    relay: useRef(),
    setting: useRef(),
  }).current;

  useEffect(() => {
    domRefs.relay.current.value = missionItem.data.relay;
    domRefs.setting.current.value = missionItem.data.setting;
  }, [missionItem.data]);

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = Math.max(0, Number(e.target.value));
    dispatch(actions.mission.updateMissionItem(index, name, value));
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Relay Instance Number">
        <input
          ref={domRefs.relay}
          name="relay"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Setting">
        <select ref={domRefs.setting} name="setting" onChange={handleBlur}>
          <option value="1">On</option>
          <option value="0">Off</option>
        </select>
      </MissionItemRow>
    </div>
  );
};

export default MissionItem;
