import classNames from 'classnames/bind';
import React from 'react';

import styles from './BottomBar.module.scss';

import Geo from '@/components/ui/Geo';

const cx = classNames.bind(styles);

const BottomBar = () => {
  return (
    <div className={cx('container')}>
      <Geo />
    </div>
  );
};

export default BottomBar;
