import classNames from 'classnames/bind';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import ConfirmSlider from '@/components/ui/ConfirmSlider';
import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);
  const [inputs, setInputs] = useState({
    altitude: 20,
    climbSpeed: 2,
  });

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: Number(e.target.value),
    });
  };

  const doCommand = () => {
    robots.forEach((robot) => {
      publishCommand(robot, 'action/takeoff/arducopter', [[], [inputs.climbSpeed], [inputs.altitude]]);
    });

    dispatch(actions.command.toggle());
  };

  return (
    <div className={cx('container')}>
      <div className={cx('form')}>
        <label>
          Altitude
          <div className={cx('fieldWrapper')}>
            <input name="altitude" type="number" value={inputs.altitude} onChange={handleChange} />
            <div className={cx('unit')}>m</div>
          </div>
        </label>
        <label>
          Climb Speed
          <div className={cx('fieldWrapper')}>
            <input name="climbSpeed" type="number" value={inputs.climbSpeed} onChange={handleChange} />
            <div className={cx('unit')}>m/s</div>
          </div>
        </label>
      </div>
      <ConfirmSlider onConfirm={doCommand} />
    </div>
  );
};

export default Action;
