import MODELS from '@/models';

const useCommand = () => {
  const getCommand = (commandId) => {
    const [modelId, groupName, commandName] = commandId.split('/');
    const group = MODELS[modelId].commandGroups.find(({ name }) => name === groupName);
    const command = group.commands.find(({ name }) => name === commandName);
    return command;
  };

  const getComponent = (commandId) => {
    return getCommand(commandId).component;
  };

  const isSingleRobotOnly = (commandId) => {
    return getCommand(commandId).singleRobotOnly;
  };

  return { getComponent, isSingleRobotOnly };
};

export default useCommand;
