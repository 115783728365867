import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import actions from '@/actions';
import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';
import useWaitingRobotTimer from '@/hooks/useWaitingRobotTimer';
import EventEmitter from '@/libs/EventEmitter';
import { isColdPosition } from '@/utils/MapUtils';

const useOrganizer = (robot) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);
  const heartbeat = useSelector((state) => state.telemetry[robot.id]?.m0);
  const gpsRaw = useSelector((state) => state.telemetry[robot.id]?.m24);
  const attitude = useSelector((state) => state.telemetry[robot.id]?.m30);
  const globalPosition = useSelector((state) => state.telemetry[robot.id]?.m33);
  const missionCurrent = useSelector((state) => state.telemetry[robot.id]?.m42);
  const missionCount = useSelector((state) => state.telemetry[robot.id]?.m44);
  const missionAck = useSelector((state) => state.telemetry[robot.id]?.m47);
  const missionItem = useSelector((state) => state.telemetry[robot.id]?.m73);
  const vfrHud = useSelector((state) => state.telemetry[robot.id]?.m74);
  const commandLong = useSelector((state) => state.telemetry[robot.id]?.m76);
  const commandAck = useSelector((state) => state.telemetry[robot.id]?.m77);
  const batteryStatus = useSelector((state) => state.telemetry[robot.id]?.m147);
  const vibration = useSelector((state) => state.telemetry[robot.id]?.m241);
  const homePosition = useSelector((state) => state.telemetry[robot.id]?.m242);
  const statustext = useSelector((state) => state.telemetry[robot.id]?.m253);

  useWaitingRobotTimer(robot.id, [heartbeat]);

  useMountEffect(() => {
    const intervals = [];
    intervals.push(setInterval(() => publishCommand(robot, 'request/heartbeat', [[]]), 1000));

    return () => {
      intervals.forEach(clearInterval);
    };
  });

  useUpdateEffect(() => {
    const isArm = heartbeat.baseMode >= 128;
    const mode = heartbeat.customMode;
    const status = heartbeat.systemStatus;

    EventEmitter.publish(`${robot.id}/telemetry/heartbeat`, { isArm, mode, status });
  }, [heartbeat]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/gpsRaw`, gpsRaw);
  }, [gpsRaw]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/attitude`, attitude);
  }, [attitude]);

  useUpdateEffect(() => {
    let { lat, lon: lng, alt, relativeAlt: ralt, hdg } = globalPosition;
    lat /= 10000000;
    lng /= 10000000;
    alt /= 1000;
    ralt /= 1000;
    hdg /= 100;

    // GPS 데이터 유효하지 않은 경우
    if (isColdPosition(lat, lng)) return;

    EventEmitter.publish(`${robot.id}/event/footprint`, { lat, lng });
    EventEmitter.publish(`${robot.id}/telemetry/globalPosition`, { lat, lng, alt, ralt, hdg });
  }, [globalPosition]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/missionCurrent`, { seq: missionCurrent.seq + 1 });
  }, [missionCurrent]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/missionCount`, missionCount);
  }, [missionCount]);

  useUpdateEffect(() => {
    const { type, missionType } = missionAck;
    if (type === 0 && missionType === 2) {
      publishCommand(robot, 'mission/request_list', [[]]);
    }
  }, [missionAck]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/missionItem`, missionItem);
  }, [missionItem]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/vfrHud`, vfrHud);
  }, [vfrHud]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/commandLong`, commandLong);
  }, [commandLong]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/commandAck`, commandAck);
  }, [commandAck]);

  useUpdateEffect(() => {
    const voltage = batteryStatus.voltages[0];

    EventEmitter.publish(`${robot.id}/telemetry/batteryStatus`, { voltage });
  }, [batteryStatus]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/vibration`, vibration);
  }, [vibration]);

  useUpdateEffect(() => {
    let { latitude: lat, longitude: lng } = homePosition;
    lat /= 10000000;
    lng /= 10000000;

    EventEmitter.publish(`${robot.id}/telemetry/homePosition`, { lat, lng });
  }, [homePosition]);

  useUpdateEffect(() => {
    if (!statustext) return;

    dispatch(actions.notification.add(robot.id, statustext.text));
  }, [statustext]);
};

export default useOrganizer;
