import commandGroups from './commands';
import Dashboard from './Dashboard';
import MapMapper from './MapMapper';

const map = {
  commandGroups,
  Dashboard,
  MapMapper,
};

export default map;
