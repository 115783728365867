import classNames from 'classnames/bind';
import { fromLonLat } from 'ol/proj';
import React from 'react';
import { RiFullscreenLine, RiZoomOutLine, RiZoomInLine, RiCrosshairLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import styles from './MapToolbar.module.scss';

import actions from '@/actions';
import Bar from '@/components/ui/Bar';
import { OL_MAX_ZOOM } from '@/config';
import OlMap from '@/helpers/OlMap';

const cx = classNames.bind(styles);

const MapToolbar = () => {
  const dispatch = useDispatch();
  const zoom = useSelector((state) => state.map.zoom);

  const fitBounds = () => {
    dispatch(actions.robot.setFocus());
    OlMap.fitBoundsAuto();
  };

  const zoomOut = () => {
    const view = OlMap.getMap().getView();
    view.animate({
      zoom: view.getZoom() - 1,
      duration: 300,
    });
  };

  const zoomIn = () => {
    const view = OlMap.getMap().getView();
    view.animate({
      zoom: view.getZoom() + 1,
      duration: 300,
    });
  };

  const moveToCurrent = () => {
    window.navigator.geolocation.getCurrentPosition(({ coords }) => {
      OlMap.getMap().getView().setZoom(OL_MAX_ZOOM);
      OlMap.setCenter(fromLonLat([coords.longitude, coords.latitude]));
    });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('group')}>
        <div className={cx('button')} onClick={fitBounds}>
          <RiFullscreenLine size={18} title="Fit Bounds" />
        </div>
        <Bar />
        <div className={cx('button')} onClick={moveToCurrent}>
          <RiCrosshairLine size={18} title="Current Location" />
        </div>
      </div>
      <div className={cx('group')}>
        <div className={cx('button')} onClick={zoomOut}>
          <RiZoomOutLine size={18} title="Zoom Out" />
        </div>
        <Bar />
        <div className={cx('zoom')}>{Math.round(zoom)}</div>
        <Bar />
        <div className={cx('button')} onClick={zoomIn}>
          <RiZoomInLine size={18} title="Zoom In" />
        </div>
      </div>
    </div>
  );
};

export default MapToolbar;
