import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useSelector, useDispatch } from 'react-redux';

import actions from '@/actions';

const useEditorLabels = () => {
  const dispatch = useDispatch();
  const path = useSelector((state) => state.editor.path);

  useUpdateEffect(() => {
    const filteredPath = path.filter(
      ({ id }) => !/\/survey\/\d+$/.test(id) // 서베이 중간 경로점 제외
    );

    const labels = [];
    filteredPath.slice(1).forEach(({ id }, index) => {
      // 종료점 아닌 경우 라벨 표시
      if (!id.endsWith('/end')) {
        labels.push({
          id,
          from: filteredPath[index].position,
          to: filteredPath[index + 1].position,
        });
      }
    });

    dispatch(actions.editor.loadLabels(labels));
  }, [path]);
};

export default useEditorLabels;
