import classNames from 'classnames/bind';
import React, { useMemo } from 'react';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import styles from './index.module.scss';
import useLoadRobots from './useLoadRobots';
import useUpdatePosition from './useUpdatePosition';

import actions from '@/actions';
import EmptyList from '@/components/ui/EmptyList';

const cx = classNames.bind(styles);

const GuideRobotList = () => {
  useLoadRobots();
  useUpdatePosition();

  const dispatch = useDispatch();
  const robots = useSelector((state) => state.robots);

  const sortedRobots = useMemo(() => {
    const activeRobots = robots.filter(({ isActive }) => isActive);
    activeRobots.sort((a, b) => (a.name < b.name ? -1 : 1));

    const inactiveRobots = robots.filter(({ isActive }) => !isActive);
    inactiveRobots.sort((a, b) => (a.name < b.name ? -1 : 1));

    return [...activeRobots, ...inactiveRobots];
  }, [robots]);

  const toggleShow = (e) => {
    const robotId = e.target.closest('[data-id]').dataset.id;
    const { isActive } = robots.find(({ id }) => id === robotId);

    if (isActive) {
      dispatch(actions.robots.toggleShow(robotId));
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('name')}>Guide Robots</div>
        <div className={cx('total')}>Total {sortedRobots.length}</div>
      </div>
      <div className={cx('body')}>
        {sortedRobots.length === 0 && (
          <div className={cx('empty')}>
            <EmptyList message="No items" />
          </div>
        )}
        {sortedRobots.map((robot, index) => (
          <div key={index} data-id={robot.id} className={cx('row')}>
            <div className={cx('color')} style={robot.isActive ? { backgroundColor: robot.color } : null} />
            <div className={cx(['name', { active: robot.isActive }])}>{robot.name}</div>
            {robot.isActive && (
              <div className={cx('button')} onClick={toggleShow}>
                {robot.isShow && <RiEyeLine size={14} color="white" />}
                {!robot.isShow && <RiEyeOffLine size={14} color="white" />}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GuideRobotList;
