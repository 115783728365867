export const getCSSVariable = (variable) => {
  return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
};

export const getColorWithOpacity = (hexColor, opacity) => {
  // '#' 기호 존재 시 제거
  hexColor = hexColor.replace(/^#/, '');

  const alpha = Math.round(opacity * 255)
    .toString(16)
    .padStart(2, '0');

  return `#${hexColor}${alpha}`;
};
