import classNames from 'classnames/bind';
import React, { useState, useEffect } from 'react';
import { MdOutlineNotifications } from 'react-icons/md';
import { useSelector } from 'react-redux';

import styles from './TopNotificationCount.module.scss';

const cx = classNames.bind(styles);

const Component = ({ robotId }) => {
  const [count, setCount] = useState(0);
  const notifications = useSelector((state) => state.notification[robotId]);

  useEffect(() => {
    if (notifications) {
      setCount(notifications.length);
    }
  }, [notifications]);

  return (
    <div className={cx('container')}>
      <MdOutlineNotifications size={16} color="white" />
      <div className={cx('count')}>{count}</div>
    </div>
  );
};

export default Component;
