import classNames from 'classnames/bind';
import React from 'react';
import { RiArrowDownSFill } from 'react-icons/ri';

import styles from './AccordionCaret.module.scss';

const cx = classNames.bind(styles);

const AccordionCaret = ({ up, size }) => {
  return (
    <div className={cx(['container', { up }])} style={{ width: `${size}px`, height: `${size}px` }}>
      <RiArrowDownSFill size={20} color="gray" />
    </div>
  );
};

export default AccordionCaret;
