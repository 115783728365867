import classNames from 'classnames/bind';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import styles from './Specs.module.scss';

import Bar from '@/components/ui/Bar';

const cx = classNames.bind(styles);

const Specs = () => {
  const specs = useSelector((state) => state.mission.json.specs);

  const [protocol, firmware, frame] = useMemo(() => {
    let draftProtocol = 'N/A';
    switch (specs.protocol) {
      case 'mavlink':
        draftProtocol = 'MavLink';
        break;
      default:
        break;
    }

    let draftFirmware = 'N/A';
    switch (specs.firmware) {
      case 'ardupilot':
        draftFirmware = 'ArduPilot';
        break;
      case 'px4':
        draftFirmware = 'PX4';
        break;
      default:
        break;
    }

    let draftFrame = 'N/A';
    switch (specs.frame) {
      case 'copter':
        draftFrame = 'Copter';
        break;
      case 'vtol':
        draftFrame = 'Plane (VTOL)';
        break;
      case 'fixedwing':
        draftFrame = 'Plane (Fixed-wing)';
        break;
      case 'boat':
        draftFrame = 'Boat';
        break;
      default:
        break;
    }

    return [draftProtocol, draftFirmware, draftFrame];
  }, [specs]);

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('term')}>
          <div className={cx('label')}>Protocol</div>
          <div className={cx('value')}>{protocol}</div>
        </div>
        <Bar height={28} />
        <div className={cx('term')}>
          <div className={cx('label')}>Firmware</div>
          <div className={cx('value')}>{firmware}</div>
        </div>
        <Bar height={28} />
        <div className={cx('term')}>
          <div className={cx('label')}>Frame</div>
          <div className={cx('value')}>{frame}</div>
        </div>
      </div>
    </div>
  );
};

export default Specs;
