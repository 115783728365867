import classNames from 'classnames/bind';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './index.module.scss';
import MapSelector from './MapSelector';
import ZonesSelector from './ZonesSelector';

import AccountCircle from '@/components/ui/AccountCircle';
import Bar from '@/components/ui/Bar';

const cx = classNames.bind(styles);

const TopBar = () => {
  const location = useLocation();

  const key = useMemo(() => {
    return location.pathname.split('/')[1];
  }, [location]);

  const title = useMemo(() => {
    if (key === 'control-center') {
      return 'Control Center';
    } else if (key === 'mission-hub') {
      return 'Mission Hub';
    } else if (key === 'video-manager') {
      return 'Video Manager';
    }
  }, [key]);

  const isShowCenter = useMemo(() => {
    return ['control-center', 'mission-hub'].includes(key);
  }, [key]);

  const handleChange = (e) => {
    switch (e.target.value) {
      case 'control-center':
      case 'mission-hub':
      case 'video-manager':
        window.open(`/${e.target.value}`);
        break;

      case 'g-viewer':
        window.open('https://gimcheon.smatii.com/');
        break;

      default:
        break;
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('left')}>
        <img src={require('@/assets/images/logo.png')} alt="M1UCS" />
        <div className={cx('wordmark')}>
          <div className={cx('app')}>M1UCS</div>
          <div className={cx('title')}>{title}</div>
        </div>
      </div>
      {isShowCenter && (
        <div className={cx('center')}>
          <MapSelector />
          {process.env.REACT_APP_USE_VWORLD_ZONES === 'true' && (
            <>
              <Bar height={20} />
              <ZonesSelector />
            </>
          )}
        </div>
      )}
      <div className={cx('right')}>
        <select value={key} onChange={handleChange}>
          <option value="control-center">Control Center</option>
          <option value="mission-hub">Mission Hub</option>
          <option value="video-manager">Video Manager</option>
          {process.env.REACT_APP_USE_GVIEWER_LINK === 'true' && <option value="g-viewer">G-Viewer</option>}
        </select>
        <AccountCircle />
      </div>
    </div>
  );
};

export default TopBar;
