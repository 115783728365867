import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { setActionsForPage } from './actions';
import { getReducerForPage } from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['map'],
};

export const createStoreForPage = (pageType) => {
  setActionsForPage(pageType);
  const persistedReducer = persistReducer(persistConfig, getReducerForPage(pageType));

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
      }),
  });
  const persistor = persistStore(store);

  return { store, persistor };
};
