import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useMemo, useState } from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useSelector } from 'react-redux';

import styles from './index.module.scss';
import Panel from './Panel';
import Video from './Video';

const cx = classNames.bind(styles);

const Main = () => {
  const videos = useSelector((state) => state.videos);
  const targetRobotIds = useSelector((state) => state.robot.targetRobotIds);
  const [selectedVideo, setSelectedVideo] = useState();
  const [sortOption, setSortOption] = useState('none');

  // 노출 영상 선별
  const filteredVideos = useMemo(() => {
    return videos.filter(({ robotId, isVisible }) => targetRobotIds.includes(robotId) && isVisible);
  }, [videos, targetRobotIds]);

  // 영상 정렬
  const sortedVideos = useMemo(() => {
    const videosCopy = [...filteredVideos];

    switch (sortOption) {
      case 'nameAsc':
        return videosCopy.sort((a, b) => a.name.localeCompare(b.name));
      case 'nameDesc':
        return videosCopy.sort((a, b) => b.name.localeCompare(a.name));
      case 'timeAsc':
        return videosCopy.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      case 'timeDesc':
        return videosCopy.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      default:
        return videosCopy;
    }
  }, [filteredVideos, sortOption]);

  useUpdateEffect(() => {
    setSelectedVideo();
  }, [targetRobotIds]);

  const handleSelect = (video) => {
    if (video.id === selectedVideo?.id) {
      setSelectedVideo();
    } else {
      setSelectedVideo(video);
    }
  };

  const handleClose = (e) => {
    setSelectedVideo();
  };

  return (
    <div className={cx('container')}>
      <div className={cx('collection')}>
        <div className={cx('header')}>
          <div className={cx('count')}>Total {filteredVideos.length}</div>
          <select onChange={(e) => setSortOption(e.target.value)}>
            <option value="none">None</option>
            <option value="nameAsc">Name Ascending</option>
            <option value="nameDesc">Name Descending</option>
            <option value="timeAsc">Time Ascending</option>
            <option value="timeDesc">Time Descending</option>
          </select>
        </div>
        {sortedVideos.length === 0 && (
          <div className={cx('empty')}>
            <div className={cx('message')}>No items found</div>
            {targetRobotIds.length === 0 && (
              <div className={cx('row')}>
                <div className={cx('arrow')}>
                  <MdOutlineArrowBack size={16} color="gray" />
                </div>
                <div className={cx('please')}>Please select a robot on the left.</div>
              </div>
            )}
          </div>
        )}
        {sortedVideos.length > 0 && (
          <div className={cx('videos')}>
            {sortedVideos.map((video) => (
              <Video
                key={video.id}
                data={video}
                selected={video.id === selectedVideo?.id}
                onSelect={() => handleSelect(video)}
              />
            ))}
          </div>
        )}
      </div>
      <Panel data={selectedVideo} onClose={handleClose} />
    </div>
  );
};

export default Main;
