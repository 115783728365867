import React from 'react';
import { MdOutlineBolt } from 'react-icons/md';

import { Top, TopStatusValue, TopNotificationCount } from '@/components/ui/Dashboard';
import { getPermissionLabel } from '@/components/ui/Dashboard/Top';

const Component = ({ robot }) => {
  return (
    <Top labels={[robot.model.agentType, getPermissionLabel(robot.isOwned)]}>
      <TopStatusValue
        Icon={MdOutlineBolt}
        event={`${robot.id}/telemetry/lowstate`}
        callback={({ power_a }) => power_a.toFixed(1)}
        unit="A"
      />
      <TopStatusValue
        Icon={MdOutlineBolt}
        event={`${robot.id}/telemetry/lowstate`}
        callback={({ power_v }) => power_v.toFixed(1)}
        unit="V"
      />
      <TopNotificationCount robotId={robot.id} />
    </Top>
  );
};

export default Component;
