import produce from 'immer';
import { handleActions } from 'redux-actions';

const initialState = [];

export default handleActions(
  {
    'VIDEOS/LOAD': (state, action) =>
      produce(state, (draft) => {
        const { videos } = action.payload;

        const videoIds = new Set(state.map(({ id }) => id));
        const willBeAdded = videos
          .filter(({ id }) => !videoIds.has(id))
          .map((video) => ({
            ...video,
            isVisible: true,
          }));

        draft.push(...willBeAdded);
      }),
    'VIDEOS/SET_VISIBLE': (state, action) =>
      produce(state, (draft) => {
        const { id, isVisible } = action.payload;

        const index = state.findIndex((video) => video.id === id);
        if (index !== -1) {
          draft[index].isVisible = isVisible;
        }
      }),
    'VIDEOS/DELETE': (state, action) =>
      produce(state, (draft) => {
        const { id } = action.payload;

        const index = state.findIndex((video) => video.id === id);
        if (index !== -1) {
          draft.splice(index, 1);
        }
      }),
  },
  initialState
);
