import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useSelector } from 'react-redux';

import useWaitingRobotTimer from '@/hooks/useWaitingRobotTimer';
import EventEmitter from '@/libs/EventEmitter';

const useOrganizer = (robot) => {
  const event = useSelector((state) => state.event[robot.id]);

  useWaitingRobotTimer(robot.id, [event]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/event/${event.action}`, event.data);
  }, [event]);
};

export default useOrganizer;
