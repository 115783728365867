import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const Shoots = () => {
  const isShowShoots = useSelector((state) => state.map.isShowShoots);
  const latestShoots = useSelector((state) => state.editor.shoots);
  const entities = useRef([]);

  useUpdateEffect(() => {
    entities.current.forEach((entity) => (entity.show = isShowShoots));
  }, [isShowShoots]);

  useEffect(() => {
    // 영역 제거
    entities.current.forEach((entity) => window.ws3d.viewer.entities.remove(entity));

    if (latestShoots.length === 0) {
      entities.current = [];
      return;
    }

    entities.current = latestShoots.map((point) => {
      const positions = point.boundary.map(({ lat, lng }) => window.ws3d.common.Cartesian3.fromDegrees(lng, lat));

      return window.ws3d.viewer.entities.add({
        show: isShowShoots,
        // 촬영 영역
        polygon: {
          hierarchy: new window.Cesium.PolygonHierarchy(positions),
          material: window.ws3d.common.Color.BLACK.withAlpha(0.3),
        },
        // 촬영 영역 경계
        polyline: {
          positions,
          width: 1,
          material: new window.ws3d.common.PolylineDashMaterialProperty({
            dashLength: 4,
            color: window.ws3d.common.Color.WHITE,
          }),
          clampToGround: true,
        },
        // 촬영 지점
        position: window.ws3d.common.Cartesian3.fromDegrees(point.position.lng, point.position.lat, point.mslAltitude),
        point: {
          pixelSize: 4,
          color: window.ws3d.common.Color.YELLOW,
        },
      });
    });
  }, [latestShoots]);

  return null;
};

export default Shoots;
