import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState } from 'react';

import styles from './Monitor.module.scss';

import { Cell } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Monitor = ({ robotId }) => {
  const [data, setData] = useState();

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/event/ping`, setData);

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return (
    <div className={cx('container')}>
      <div className={cx('boxes')}>
        <Cell
          data={[
            {
              title: 'Temperature',
              value: data ? data.temperature.toFixed(1) : 0,
              unit: '°C',
            },
          ]}
        />
        <Cell
          data={[
            {
              title: 'Humidity',
              value: data ? data.humidity.toFixed(1) : 0,
              unit: '%',
            },
          ]}
        />
        <Cell
          data={[
            {
              title: 'Wind Speed',
              value: data ? data.wind_speed.toFixed(1) : 0,
              unit: 'm/s',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Monitor;
