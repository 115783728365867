import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './LoadForm.module.scss';

import EmptyList from '@/components/ui/EmptyList';
import ModalSpinner from '@/components/ui/ModalSpinner';
import { getMissionItemForSurveySegment } from '@/helpers/MissionConverter';
import { getMissions } from '@/helpers/Requester';
import { ModalService as modal } from '@/libs/Modal';
import { NotifierService as notifier } from '@/libs/Notifier';
import { deepCopy } from '@/utils/ObjectUtils';

const cx = classNames.bind(styles);

const Component = () => {
  const navigate = useNavigate();
  const [missions, setMissions] = useState();
  const [selectedMission, setSelectedMission] = useState();
  const [isLoading, setLoading] = useState(false);

  useMountEffect(() => {
    setLoading(true);

    getMissions()
      .then(({ success, data }) => {
        if (success) {
          setMissions(data);
        }
      })
      .finally(() => setLoading(false));
  });

  const select = (e) => {
    const mission = deepCopy(missions.find(({ id }) => id === e.currentTarget.dataset.id));
    mission.json.missionItems = mission.json.missionItems.map((missionItem) =>
      getMissionItemForSurveySegment(missionItem)
    );

    setSelectedMission(mission);
  };

  const doOK = () => {
    if (!selectedMission) {
      notifier.error('No item selected.');
      return;
    }

    navigate(`/mission-hub/${selectedMission.id}`, { replace: true });
    modal.hide();
  };

  return (
    <div className={cx('container')}>
      <div className={cx('table')}>
        <div className={cx('header')}>
          <div className={cx(['column', 'no'])}>No</div>
          <div className={cx(['column', 'name'])}>Name</div>
          <div className={cx(['column', 'time'])}>Last Update</div>
        </div>
        <div className={cx('body')}>
          {isLoading && <ModalSpinner />}
          {missions?.length === 0 && (
            <div className={cx('empty')}>
              <EmptyList />
            </div>
          )}
          {missions?.map((mission, index) => {
            const isTarget = mission.id === selectedMission?.id;
            const updatedAt = moment(mission.updatedAt).format('YYYY-MM-DD HH:mm:ss');

            return (
              <div key={index} data-id={mission.id} onClick={select} className={cx(['row', { target: isTarget }])}>
                <div className={cx(['column', 'no'])}>{index + 1}</div>
                <div className={cx(['column', 'name'])}>{mission.name}</div>
                <div className={cx(['column', 'time'])}>{updatedAt}</div>
              </div>
            );
          })}
        </div>
      </div>
      <button type="button" className={cx(['button', 'accent'])} onClick={doOK}>
        OK
      </button>
    </div>
  );
};

export default Component;
