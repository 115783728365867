import useMountEffect from '@restart/hooks/useMountEffect';
import React, { createContext, useState, useRef, useContext } from 'react';
import { useStore } from 'react-redux';

import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';

const NTRIPContext = createContext(null);
export { NTRIPContext };

const Provider = ({ children }) => {
  const store = useStore();
  const { publishCommand } = useContext(MessageContext);
  const [robotOptions, setRobotOptions] = useState({
    byId: {},
    allIds: [],
  });
  const ntripWorker = useRef();

  useMountEffect(() => {
    ntripWorker.current = new Worker('./workers/ntrip-worker.js');

    ntripWorker.current.onmessage = ({ data }) => messageHandler(data);
  });

  const messageHandler = ({ command, data }) => {
    switch (command) {
      case 'responseConnect':
        if (!data.success) {
          removeRobotOption(data.data.robotId);
        }
        break;

      case 'responseDisconnect':
        if (data.success) {
          removeRobotOption(data.data.robotId);
        }
        break;

      case 'sendRTCM':
        if (data.success) {
          const { robotId, flags, chunk } = data.data;
          const robot = store.getState().robots.find(({ id }) => id === robotId);

          publishCommand(robot, 'gps/rtcm', [[flags, chunk.length, chunk]]);
        }
        break;

      default:
        break;
    }
  };

  const removeRobotOption = (robotId) => {
    // Robot ID 해당 정보 제거
    const nextRobotOptions = { ...robotOptions };
    delete nextRobotOptions.byId[robotId];
    nextRobotOptions.allIds = nextRobotOptions.allIds.filter((id) => id !== robotId);
    setRobotOptions(nextRobotOptions);
  };

  const connect = (robotId, options) => {
    // Robot ID 해당 정보 추가
    const nextRobotOptions = { ...robotOptions };
    nextRobotOptions.byId[robotId] = options;
    nextRobotOptions.allIds.push(robotId);
    setRobotOptions(nextRobotOptions);

    ntripWorker.current.postMessage({
      command: 'requestConnect',
      data: { robotId, options },
    });
  };

  const disconnect = (robotId) => {
    ntripWorker.current.postMessage({
      command: 'requestDisconnect',
      data: { robotId },
    });
  };

  const ntrip = {
    connect,
    disconnect,
    robotOptions,
  };

  return <NTRIPContext.Provider value={ntrip}>{children}</NTRIPContext.Provider>;
};

export default Provider;
