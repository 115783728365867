import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import Altitudes from './Altitudes';
import styles from './index.module.scss';
import MapToolbar from './MapToolbar';
import Markers from './Markers';
import Path from './Path';
import Shoots from './Shoots';

import actions from '@/actions';
import VWorldMap from '@/helpers/VWorldMap';
import useMapCenterOnDimensionChange from '@/hooks/useMapCenterOnDimensionChange';

const cx = classNames.bind(styles);

const Map = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const isShow = useSelector((state) => state.map3d.isShow);
  const [isLoaded, setIsLoaded] = useState(false);
  const iframeRef = useRef();

  useMapCenterOnDimensionChange();

  const [latitude, longitude] = useMemo(() => {
    const { center } = store.getState().map;
    return [center.lat, center.lng];
  }, []);

  useMountEffect(() => {
    // iframe → React
    window.addEventListener('message', (e) => {
      const { event, data } = e.data;

      if (event === 'loadEnd') {
        setIsLoaded(true);
      }
    });
  });

  useUpdateEffect(() => {
    const { camera } = window.ws3d.viewer;

    // https://cesium.com/learn/ion-sdk/ref-doc/Camera.html#changed
    camera.percentageChanged = 0.01;

    // 지도 이동 중일 시
    camera.changed.addEventListener(() => {
      const { lat, lng } = VWorldMap.getCenter();
      dispatch(actions.map.setCenter(lat, lng));
    });

    // 지도 이동 완료 시
    camera.moveEnd.addEventListener(() => {
      const { lat, lng } = VWorldMap.getCenter();
      dispatch(actions.map.setPositionWithZoom(lat, lng));
    });
  }, [isLoaded]);

  return (
    <div className={cx(['container', { show: isShow }])}>
      <iframe
        ref={iframeRef}
        width="100%"
        height="100%"
        title="map"
        frameBorder="0"
        src={`${process.env.REACT_APP_WWW_URL}/map3d.html?latitude=${latitude}&longitude=${longitude}`}></iframe>
      {isLoaded && (
        <>
          <Path />
          <Shoots />
          <Markers />
          <Altitudes />
          <MapToolbar />
        </>
      )}
    </div>
  );
};

export default Map;
