import React from 'react';

import Mode from './Mode';
import Monitor1 from './Monitor1';
import Monitor2 from './Monitor2';
import Monitor3 from './Monitor3';
import Top from './Top';
import useOrganizer from '../useOrganizer';

import { Frame } from '@/components/ui/Dashboard';

const Dashboard = ({ data: robot }) => {
  useOrganizer(robot);

  return (
    <Frame robot={robot} mode={<Mode robotId={robot.id} />} top={<Top robot={robot} />}>
      <Monitor1 robotId={robot.id} />
      <Monitor2 robotId={robot.id} />
      <Monitor3 robotId={robot.id} />
    </Frame>
  );
};

export default Dashboard;
