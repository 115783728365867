import classNames from 'classnames/bind';
import React from 'react';
import { RiAddLine, RiCloseLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import styles from './GuideMissionList.module.scss';

import actions from '@/actions';
import MissionListModal, { PURPOSE } from '@/components/modals/MissionList';
import EmptyList from '@/components/ui/EmptyList';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const GuideMissionList = () => {
  const dispatch = useDispatch();
  const missions = useSelector((state) => state.missions);

  const openMissionList = () => {
    modal.show(MissionListModal, { purpose: PURPOSE.GUIDE });
  };

  const doDelete = (e) => {
    const index = Number(e.currentTarget.dataset.index);
    dispatch(actions.missions.remove(index));
  };

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('name')}>Guide Missions</div>
        <div className={cx('button')} onClick={openMissionList}>
          <RiAddLine size={16} color="white" />
        </div>
      </div>
      <div className={cx('body')}>
        {missions.length === 0 && (
          <div className={cx('empty')}>
            <EmptyList message="No items" />
          </div>
        )}
        {missions.map((mission, index) => (
          <div key={index} className={cx('row')}>
            <div className={cx('name')}>{mission.name}</div>
            <div data-index={index} className={cx('button')} onClick={doDelete}>
              <RiCloseLine size={16} color="white" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GuideMissionList;
