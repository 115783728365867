import { createActions } from 'redux-actions';

export default createActions(
  {
    LOAD: (videos) => ({ videos }),
    SET_VISIBLE: (id, isVisible) => ({ id, isVisible }),
    DELETE: (id) => ({ id }),
  },
  { prefix: 'VIDEOS' }
);
