import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useSelector, useStore } from 'react-redux';

import OlMap from '@/helpers/OlMap';
import VWorldMap from '@/helpers/VWorldMap';

const useMapCenterOnDimensionChange = () => {
  const store = useStore();
  const isShowMap3d = useSelector((state) => state.map3d.isShow);

  useUpdateEffect(() => {
    const { center } = store.getState().map;

    if (isShowMap3d) {
      VWorldMap.setCenter(center);
    } else {
      OlMap.setCenter(center);
    }
  }, [isShowMap3d]);
};

export default useMapCenterOnDimensionChange;
