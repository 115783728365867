import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import { toLonLat } from 'ol/proj';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import BinarySwitch from '@/components/ui/BinarySwitch';
import ConfirmSlider from '@/components/ui/ConfirmSlider';
import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';
import OlMap from '@/helpers/OlMap';
import EventEmitter from '@/libs/EventEmitter';
import { getElevation } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);
  const [isImmediately, setIsImmediately] = useState(true);
  const map = OlMap.getMap();

  useUpdateEffect(() => {
    if (!isImmediately) {
      map.getTargetElement().style.cursor = 'crosshair';
      map.on('click', clickOnMap);
    }

    return () => {
      map.getTargetElement().style.cursor = 'default';
      map.un('click', clickOnMap);
    };
  }, [robots, isImmediately]);

  const selectSwitch = (e) => {
    setIsImmediately(e.currentTarget.dataset.value === 'true');
  };

  const clickOnMap = (e) => {
    const [lng, lat] = toLonLat(e.coordinate);

    getElevation({ lat, lng }).then((elevation) => {
      robots.forEach((robot) => {
        publishCommand(robot, 'action/set_home_and_rth/arducopter', [[NaN, lat, lng, elevation], []]);
        EventEmitter.publish(`${robot.id}/event/removeGoto`);
      });

      dispatch(actions.command.toggle());
    });
  };

  const doCommand = () => {
    robots.forEach((robot) => {
      publishCommand(robot, 'action/land/arducopter', [[]]);
      EventEmitter.publish(`${robot.id}/event/removeGoto`);
    });

    dispatch(actions.command.toggle());
  };

  return (
    <div className={cx('container')}>
      <BinarySwitch
        items={[
          { title: 'Immediately', value: true, onClick: selectSwitch },
          { title: 'Set Home & RTH', value: false, onClick: selectSwitch },
        ]}
      />
      {isImmediately && <ConfirmSlider onConfirm={doCommand} />}
      {!isImmediately && (
        <div className={cx('message')}>
          Click on a location on the map
          <br />
          where you want to land the drone.
        </div>
      )}
    </div>
  );
};

export default Action;
