import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const Altitudes = () => {
  const missionId = useSelector((state) => state.mission.id);
  const altitudes = useSelector((state) => state.editor.markers);
  const altitudesRef = useRef({});

  useUpdateEffect(() => {
    Object.values(altitudesRef.current).forEach(({ entity }) => window.ws3d.viewer.entities.remove(entity));
    altitudesRef.current = {};
  }, [missionId]);

  useEffect(() => {
    const currAltitudeIds = Object.keys(altitudesRef.current);
    const nextAltitudeIds = Object.keys(altitudes);

    // 추가
    if (currAltitudeIds.length < nextAltitudeIds.length) {
      const willBeAddedIds = nextAltitudeIds.filter((id) => !Object.hasOwn(altitudesRef.current, id));

      willBeAddedIds.forEach((id) => {
        const entity = getAltitudeEntity(id);
        altitudesRef.current[id] = { ...altitudes[id], entity };
      });
    }
    // 제거
    else if (nextAltitudeIds.length < currAltitudeIds.length) {
      const willBeRemovedIds = currAltitudeIds.filter((id) => !Object.hasOwn(altitudes, id));

      willBeRemovedIds.forEach((id) => {
        window.ws3d.viewer.entities.remove(altitudesRef.current[id].entity);
        delete altitudesRef.current[id];
      });
    }
    // 이동
    else if (nextAltitudeIds.length === currAltitudeIds.length) {
      nextAltitudeIds.forEach((id) => {
        const oldAltitude = altitudesRef.current[id];
        const newAltitude = altitudes[id];

        const oldPosition = getCartesian3Position(oldAltitude.position, oldAltitude.mslAltitude);
        const newPosition = getCartesian3Position(newAltitude.position, newAltitude.mslAltitude);

        if (!newPosition.equals(oldPosition)) {
          const entity = altitudesRef.current[id].entity;
          const bottom = getCartesian3Position(newAltitude.position, 0);

          // 갱신
          entity.position = newPosition;
          entity.polyline.positions = [bottom, newPosition];
          entity.label.text = `${newAltitude.relAltitude}m`;

          altitudesRef.current[id] = { ...newAltitude, entity };
        }
      });
    }
  }, [altitudes]);

  const getAltitudeEntity = (id) => {
    const { position, relAltitude, mslAltitude } = altitudes[id];

    const bottom = getCartesian3Position(position, 0);
    const top = getCartesian3Position(position, mslAltitude);

    return window.ws3d.viewer.entities.add({
      // 고도 수직선
      polyline: {
        positions: [bottom, top],
        width: 2,
        material: new window.ws3d.common.PolylineDashMaterialProperty({
          dashLength: 8,
          color: window.ws3d.common.Color.WHITE.withAlpha(0.6),
        }),
      },
      // 고도 라벨
      position: top,
      label: {
        text: `${relAltitude}m`,
        font: 'bold 14px "Noto Sans KR", sans-serif',
        pixelOffset: new window.ws3d.common.Cartesian2(0, 16),
        fillColor: window.ws3d.common.Color.WHITE,
        outlineColor: window.ws3d.common.Color.BLACK,
        style: window.ws3d.common.LabelStyle.FILL_AND_OUTLINE,
        verticalOrigin: window.ws3d.common.VerticalOrigin.CENTER,
      },
    });
  };

  const getCartesian3Position = ({ lat, lng }, mslAltitude) => {
    return window.ws3d.common.Cartesian3.fromDegrees(lng, lat, mslAltitude);
  };

  return null;
};

export default Altitudes;
