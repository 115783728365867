import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useRef, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import Checkbox from '@/components/ui/Checkbox';
import ConfirmSlider from '@/components/ui/ConfirmSlider';
import { NotifierService as notifier } from '@/libs/Notifier';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const selectedRobotId = useRef();
  const [isChecked, setChecked] = useState(false);

  useMountEffect(() => {
    if (robots.length > 1) {
      notifier.error('This command can only be executed on a single robot.');
      dispatch(actions.command.reset());
      return;
    }
  });

  const handleChange = (e) => {
    selectedRobotId.current = e.target.value ? e.target.value : null;
  };

  const handleCheck = () => {
    setChecked(!isChecked);
  };

  const doCommand = () => {
    if (!selectedRobotId.current) {
      notifier.error('Please select a robot.');
      return;
    }
    if (!isChecked) {
      notifier.error('Please check if the robot is at the station.');
      return;
    }

    dispatch(actions.command.toggle());
  };

  return (
    <div className={cx('container')}>
      <div className={cx('message')}>Select a robot for diagnostics.</div>
      <select onChange={handleChange}>
        <option value="">Select</option>
        {store.getState().robots.map((robot, index) => (
          <option key={index} value={robot.id}>
            {robot.name}
          </option>
        ))}
      </select>
      <div className={cx('agree')}>
        <Checkbox checked={isChecked} size={16} onClick={handleCheck} />
        The robot is positioned at the station.
      </div>
      <ConfirmSlider onConfirm={doCommand} />
    </div>
  );
};

export default Action;
