class VWorldMap {
  setCenter({ lat, lng }, options = { duration: 0 }) {
    window.ws3d.viewer.camera.flyTo({
      destination: window.ws3d.common.Cartesian3.fromDegrees(lng, lat, 10000),
      duration: options.duration / 1000,
    });
  }

  getCenter() {
    const { latitude, longitude } = window.ws3d.viewer.camera.positionCartographic;
    const lat = window.ws3d.common.Math.toDegrees(latitude);
    const lng = window.ws3d.common.Math.toDegrees(longitude);

    return { lat, lng };
  }

  fitBounds() {
    window.ws3d.viewer.flyTo(window.ws3d.viewer.entities, {
      offset: new window.ws3d.common.HeadingPitchRange(0, -90),
      duration: 0,
    });
  }
}

export default Object.freeze(new VWorldMap());
