import classNames from 'classnames/bind';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './NewForm.module.scss';

import actions from '@/actions';
import { ModalService as modal } from '@/libs/Modal';
import { NotifierService as notifier } from '@/libs/Notifier';

const cx = classNames.bind(styles);

const Component = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [specs, setSpecs] = useState({
    protocol: 'mavlink',
    firmware: '',
    frame: '',
  });
  const domRefs = useRef({
    protocol: useRef(),
    firmware: useRef(),
    frame: useRef(),
  }).current;

  const handleChange = (e) => {
    const nextSpecs = { ...specs };
    if (e.target.name === 'firmware') {
      nextSpecs.firmware = e.target.value;
      nextSpecs.frame = '';
    }
    if (e.target.name === 'frame') {
      nextSpecs.frame = e.target.value;
    }

    setSpecs(nextSpecs);
  };

  const doOK = () => {
    if (!specs.firmware) {
      notifier.error('Please select a firmware.');
      return;
    }
    if (!specs.frame) {
      notifier.error('Please select a frame.');
      return;
    }

    dispatch(actions.mission.setSpecs(specs));

    navigate('/mission-hub/new');
    modal.hide();
  };

  return (
    <div className={cx('container')}>
      <div className={cx('body')}>
        <select ref={domRefs.protocol} name="protocol">
          <option value="mavlink">MavLink</option>
        </select>
        <select ref={domRefs.firmware} name="firmware" value={specs.firmware} onChange={handleChange}>
          <option value="">Select</option>
          <option value="ardupilot">ArduPilot</option>
          <option value="px4">PX4</option>
        </select>
        <select
          ref={domRefs.frame}
          name="frame"
          value={specs.frame}
          onChange={handleChange}
          disabled={specs.firmware === ''}>
          <option value="">Select</option>
          <option value="copter">Copter</option>
          <option value="vtol">Plane (VTOL)</option>
          <option value="fixedwing">Plane (Fixed-wing)</option>
          {specs.firmware === 'px4' && <option value="boat">Boat</option>}
        </select>
      </div>
      <button type="button" className={cx(['button', 'accent'])} onClick={doOK}>
        OK
      </button>
    </div>
  );
};

export default Component;
