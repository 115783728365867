import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useSelector, useDispatch } from 'react-redux';

import actions from '@/actions';
import { getMissionItemForSurveySegment } from '@/helpers/MissionConverter';

const useEditorMarkers = () => {
  const dispatch = useDispatch();
  const missionItems = useSelector((state) => state.mission.json.missionItems);

  useUpdateEffect(() => {
    const draftMarkers = [];
    let labelNumber = 1;

    missionItems.forEach((missionItem) => {
      switch (missionItem.type) {
        case 'navLand':
          draftMarkers.push({
            id: missionItem.id,
            label: labelNumber,
            position: missionItem.data.position,
            relAltitude: draftMarkers.at(-1).relAltitude,
            mslAltitude: draftMarkers.at(-1).mslAltitude,
            draggable: true,
          });

          // Parachute 항목 체크한 경우
          if (missionItem.data.parachute) {
            labelNumber += 3; // Waypoint, Parachute Enable, Release 반영
          }
          break;

        case 'doSetRoiLocation':
        case 'navLoiterTime':
        case 'navLoiterToAlt':
        case 'navLoiterTurns':
        case 'navTakeoff':
          draftMarkers.push({
            id: missionItem.id,
            label: labelNumber,
            position: missionItem.data.position,
            relAltitude: missionItem.data.altitude,
            mslAltitude: missionItems[0].data.elevation + missionItem.data.altitude,
            draggable: true,
          });
          break;

        case 'navWaypoint':
          draftMarkers.push({
            id: missionItem.id,
            label: labelNumber,
            position: missionItem.data.position,
            relAltitude: missionItem.data.altitude,
            mslAltitude: missionItems[0].data.elevation + missionItem.data.altitude,
            draggable: true,
          });

          // Speed 항목 체크한 경우
          if (missionItem.data.speed) {
            labelNumber++; // Change Speed 반영
          }
          break;

        case 'navReturnToLaunch':
          draftMarkers.push({
            id: missionItem.id,
            label: labelNumber,
            position: missionItems[0].data.position,
            relAltitude: draftMarkers.at(-1).relAltitude,
            mslAltitude: draftMarkers.at(-1).mslAltitude,
            draggable: false,
          });
          break;

        case 'cusSurvey':
          {
            const surveyMissionItem = getMissionItemForSurveySegment(missionItem);
            const startLabel = labelNumber;
            const endLabel = labelNumber + surveyMissionItem.data.positions.length - 1;
            const mslAltitude = missionItems[0].data.elevation + missionItem.data.altitude;

            // 서베이 시작점
            draftMarkers.push({
              id: `${surveyMissionItem.id}/survey/start`,
              label: startLabel,
              position: surveyMissionItem.data.positions.at(0),
              relAltitude: missionItem.data.altitude,
              mslAltitude,
              draggable: false,
            });

            // 서베이 종료점
            draftMarkers.push({
              id: `${surveyMissionItem.id}/survey/end`,
              label: endLabel,
              position: surveyMissionItem.data.positions.at(-1),
              relAltitude: missionItem.data.altitude,
              mslAltitude,
              draggable: false,
            });

            labelNumber = endLabel;
          }
          break;

        default:
          break;
      }

      labelNumber++;
    });

    const markers = Object.fromEntries(
      draftMarkers.map((marker) => {
        const { id, ...data } = marker;
        return [id, data];
      })
    );

    dispatch(actions.editor.loadMarkers(markers));
  }, [missionItems]);
};

export default useEditorMarkers;
