import callout from './common/callout';
import map from './common/map';
import map3d from './common/map3d';
import robots from './common/robots';
import telemetry from './common/telemetry';
import user from './common/user';
import command from './control-center/command';
import commandSet from './control-center/commandSet';
import event from './control-center/event';
import missionsForControlCenter from './control-center/missions';
import notification from './control-center/notification';
import robot from './control-center/robot';
import robotOptions from './control-center/robotOptions';
import editor from './mission-hub/editor';
import mission from './mission-hub/mission';
import missionsForMissionHub from './mission-hub/missions';
import videos from './video-manager/videos';

const actions = {};

export const setActionsForPage = (pageType) => {
  switch (pageType) {
    case 'control-center':
      actions.callout = callout;
      actions.command = command;
      actions.commandSet = commandSet;
      actions.event = event;
      actions.map = map;
      actions.map3d = map3d;
      actions.missions = missionsForControlCenter;
      actions.notification = notification;
      actions.robot = robot;
      actions.robotOptions = robotOptions;
      actions.robots = robots;
      actions.telemetry = telemetry;
      actions.user = user;
      break;

    case 'mission-hub':
      actions.callout = callout;
      actions.editor = editor;
      actions.map = map;
      actions.map3d = map3d;
      actions.mission = mission;
      actions.missions = missionsForMissionHub;
      actions.robots = robots;
      actions.telemetry = telemetry;
      actions.user = user;
      break;

    case 'video-manager':
      actions.robot = robot;
      actions.robots = robots;
      actions.user = user;
      actions.videos = videos;
      break;

    default:
      break;
  }
};

export default actions;
