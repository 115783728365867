import classNames from 'classnames/bind';
import React from 'react';

import styles from './ModalSpinner.module.scss';

import Spinner from '@/components/ui/Spinner';
import { getCSSVariable } from '@/utils/theme';

const cx = classNames.bind(styles);

const ModalSpinner = () => {
  return (
    <div className={cx('container')}>
      <Spinner size={24} color={getCSSVariable('--tint-color')} />
    </div>
  );
};

export default ModalSpinner;
