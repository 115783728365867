import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState } from 'react';

import styles from './Monitor.module.scss';

import { Row } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const STATUS = {
  0: 'Uninitialized',
  1: 'Booting Up',
  2: 'Calibrating',
  3: 'On Stanby',
  4: 'Active',
  5: 'Critical',
  6: 'Emergency',
  7: 'Power Off',
  8: 'Termination',
};

const Monitor = ({ robotId }) => {
  const [armState, setArmState] = useState();
  const [status, setStatus] = useState();
  const [globalPosition, setGlobalPosition] = useState();

  useMountEffect(() => {
    const subscribeTokens = [];
    subscribeTokens.push(
      EventEmitter.subscribe(`${robotId}/telemetry/heartbeat`, (data) => {
        setArmState(data.isArm ? 'ARM' : 'DISARM');
        setStatus(STATUS[data.status]);
      })
    );
    subscribeTokens.push(EventEmitter.subscribe(`${robotId}/telemetry/globalPosition`, setGlobalPosition));

    return () => {
      subscribeTokens.forEach((subscribeToken) => EventEmitter.unsubscribe(subscribeToken));
    };
  });

  return (
    <div className={cx('container')}>
      <div className={cx('rows')}>
        <Row label="Arm State" value={armState} valueStyle={armState === 'DISARM' && { color: 'red' }} />
        <Row label="System Status" value={status} />
        <Row
          label="Current Position"
          value={globalPosition ? `${globalPosition.lat.toFixed(6)}, ${globalPosition.lng.toFixed(6)}` : '-'}
        />
      </div>
    </div>
  );
};

export default Monitor;
