import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import { asArray } from 'ol/color';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Style, Stroke, Fill, Text } from 'ol/style';
import React, { useState, useRef, useMemo } from 'react';
import { RiCheckboxBlankLine, RiCheckboxFill, RiMapPin5Line } from 'react-icons/ri';
import { useStore } from 'react-redux';

import styles from './ZonesSelector.module.scss';
import AccordionCaret from '../AccordionCaret';

import geoZones from '@/define/geoZones';
import API from '@/helpers/API';
import OlMap from '@/helpers/OlMap';
import { NotifierService as notifier } from '@/libs/Notifier';

const cx = classNames.bind(styles);

const Zones = () => {
  const store = useStore();
  const [showPanel, setShowPanel] = useState(false);
  const [zones, setZones] = useState();
  const menuRef = useRef();
  const panelRef = useRef();
  const polygons = useRef({});
  const map = OlMap.getMap();

  const count = useMemo(() => {
    return zones?.filter((zone) => zone.on).length ?? 0;
  }, [zones]);

  useMountEffect(() => {
    const promises = Object.keys(geoZones).map((name) =>
      API.get(`/proxy/vworld?data=LT_C_AIS${name}`).then(({ data }) => ({
        name,
        ...geoZones[name],
        features: data.response?.result.featureCollection.features ?? null,
      }))
    );

    Promise.all(promises).then((results) => {
      const nextZones = results.map((result) => ({ ...result, on: false }));
      setZones(nextZones);
    });
  });

  useUpdateEffect(() => {
    if (showPanel) {
      document.addEventListener('mousedown', unfocusPanel);
    }

    return () => {
      document.removeEventListener('mousedown', unfocusPanel);
    };
  }, [showPanel]);

  const unfocusPanel = (e) => {
    if (menuRef.current.contains(e.target)) return;
    if (panelRef.current.contains(e.target)) return;

    setShowPanel(false);
  };

  const togglePanel = () => {
    if (store.getState().map3d.isShow) {
      notifier.error('This feature is not available in 3D map.');
      return;
    }

    setShowPanel(!showPanel);
  };

  const toggleZone = (e, zone) => {
    e.stopPropagation();

    if (!zone.on) {
      if (zone.features === null) {
        notifier.error('Failed to load the zone.');
        return;
      }

      showZone(zone);
    } else {
      hideZone(zone);
    }

    zone.on = !zone.on;
    setZones([...zones]);
  };

  const showZone = ({ color, features, title }) => {
    // 색상 RGB 값 분리
    const [r, g, b] = asArray(color);

    features.forEach(({ id, geometry }) => {
      const coordinates = geometry.coordinates[0][0].map((coord) => fromLonLat(coord));
      const feature = new Feature(new Polygon([coordinates]));
      const source = new VectorSource({ features: [feature], wrapX: false });
      const layer = new VectorLayer({
        source,
        style: new Style({
          stroke: new Stroke({ color, width: 2 }),
          fill: new Fill({ color: [r, g, b, 0.2] }),
          text: new Text({
            text: title,
            font: 'bold 12px "Noto Sans KR", sans-serif',
            fill: new Fill({ color: [r, g, b, 0.8] }),
            stroke: new Stroke({ color: [255, 255, 255, 0.8], width: 3 }),
          }),
        }),
      });
      map.addLayer(layer);
      polygons.current[id] = layer;
    });
  };

  const hideZone = ({ features }) => {
    features.forEach(({ id }) => {
      map.removeLayer(polygons.current[id]);
      delete polygons.current[id];
    });
  };

  return (
    <div className={cx('container')}>
      <div ref={menuRef} className={cx('menu')} onClick={togglePanel}>
        <RiMapPin5Line size={20} color="white" />
        <div className={cx('name')}>Zones {count > 0 && `(${count})`}</div>
        <AccordionCaret up={showPanel} size={32} />
      </div>
      <div ref={panelRef} className={cx(['panel', { show: showPanel }])}>
        {zones?.map((zone, index) => (
          <div key={index} onClick={(e) => toggleZone(e, zone)} className={cx('menu')}>
            {zone.on && <RiCheckboxFill size={14} color={zone.color} />}
            {!zone.on && <RiCheckboxBlankLine size={14} color="white" />}
            <div className={cx(['name', { active: zone.on }])}>{zone.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Zones;
