import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState } from 'react';

import styles from './TopStatusValue.module.scss';

import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Component = ({ Icon, event, callback, unit, style }) => {
  const [value, setValue] = useState('-');

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(event, (data) => {
      setValue(callback(data));
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return (
    <div className={cx('container')} style={style}>
      <Icon size={16} color="white" />
      <div className={cx('value')}>
        {value}
        {unit && <span className={cx('unit')}>{unit}</span>}
      </div>
    </div>
  );
};

export default Component;
