//! Be sure to review and update the 'ForMissionHub.js' file
//! whenever you make changes to this file.

import useMountEffect from '@restart/hooks/useMountEffect';
import _ from 'lodash';
import { MavLinkPacketParser, MavLinkProtocolV2, common, minimal, ardupilotmega } from 'node-mavlink';
import React, { createContext, useRef } from 'react';
import { useDispatch, useStore } from 'react-redux';

import actions from '@/actions';
import * as sheco from '@/models/mavlink2-px4-sheco/messages';

const MessageContext = createContext(null);
export { MessageContext };

const Provider = ({ children }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const mavlinkParser = useRef();
  const mavlinkProtocol = useRef();
  const mqttWorker = useRef();
  const robotMeta = useRef({});
  const registry = useRef({
    ...minimal.REGISTRY,
    ...common.REGISTRY,
    ...ardupilotmega.REGISTRY,
    ...sheco.REGISTRY,
  });

  useMountEffect(() => {
    mavlinkParser.current = new MavLinkPacketParser();
    mavlinkProtocol.current = new MavLinkProtocolV2();

    // MQTT Worker 정의
    mqttWorker.current = new Worker('./workers/mqtt-worker.js');
    // MQTT 연결
    mqttWorker.current.postMessage({
      command: 'connect',
      data: {
        brokerUrl: process.env.REACT_APP_MQTT_URL,
        username: process.env.REACT_APP_MQTT_USERNAME,
        password: process.env.REACT_APP_MQTT_PASSWORD,
      },
    });
    // 메시지 수신 처리
    mqttWorker.current.onmessage = ({ data }) => messageHandler(data);

    return () => {
      if (mqttWorker.current) {
        mqttWorker.current.onmessage = null;
        mqttWorker.current.terminate();
        mqttWorker.current = null;
      }
    };
  });

  const messageHandler = ({ robotId, type, message }) => {
    // 텔레메트리 수신 전 로봇 메타 데이터 초기화되지 않은 상태 시
    if (!robotMeta.current[robotId]) return;

    const { isActive } = store.getState().robots.find(({ id }) => id === robotId);
    // 활성 로봇이 아닌 경우
    if (!isActive) {
      // 로봇 활성화
      dispatch(actions.robotOptions.init(robotId));
      dispatch(actions.robots.activate(robotId));
    }

    switch (type) {
      case 'telemetry':
        switch (robotMeta.current[robotId].agentType) {
          case 'MavLink':
            handleMessageMavLink(robotId, message);
            break;

          case 'ROS':
            handleMessageRos(robotId, message);
            break;

          case 'IoT':
            handleMessageIot(robotId, message);
            break;

          default:
            break;
        }
        break;

      case 'iothub':
        handleIoTHubMessage(robotId, message);
        break;

      default:
        break;
    }
  };

  const subscribeTelemetry = (robots) => {
    robots.forEach((robot) => {
      robotMeta.current[robot.id] = {
        commandSeq: 0,
        agentType: robot.model.agentType,
      };

      // 메시지 구독 요청
      mqttWorker.current.postMessage({
        command: 'subscribe',
        data: {
          topics: [
            `robots/${robot.id}/telemetry`, // 텔레메트리
            `robots/${robot.id}/iothub`, // IoTHub
          ],
        },
      });
    });
  };

  // 버퍼 데이터 JSON 여부 검사
  const isJson = (buffer) => {
    try {
      JSON.parse(buffer.toString()); // 유효한 JSON인지 검사
      return true;
    } catch (e) {
      return false;
    }
  };

  // MavLink 메시지 처리
  const handleMessageMavLink = (robotId, message) => {
    const buffer = Buffer.from(message);

    // M1Edge 1.0.8 이상인 경우 JSON 처리
    if (isJson(buffer)) {
      const parsedMessage = JSON.parse(buffer.toString());

      // Component ID가 1이 아닌 경우 처리 방지
      // TODO: 제조사별 고유 Component ID 설정 허용
      if (parsedMessage.header.compid !== 1) return;

      const msgId = parsedMessage.header.msgid;
      const payload = parsedMessage.payload;

      dispatch(actions.telemetry.loadMavlink({ robotId, msgId, payload }));
    }
    // M1Edge 1.0.8 미만인 경우 바이너리 처리
    else {
      mavlinkParser.current._transform({ buffer: Buffer.from(message) }, 'utf8', (_, packet) => {
        // MavLink v1 메시지 처리 제외
        // https://mavlink.io/en/guide/serialization#mavlink2_packet_format
        if (packet.header.magic === 0) {
          console.debug('packet.header.magic', 0);
          return;
        }

        // Component ID가 1이 아닌 경우 처리 방지
        // TODO: 제조사별 고유 Component ID 설정 허용
        if (packet.header.compid !== 1) return;

        const msgId = packet.header.msgid;
        const messageClass = registry.current[msgId];
        if (!messageClass) return;

        const parsed = mavlinkProtocol.current.data(packet.payload, messageClass);
        const payload = {};
        Object.entries(parsed).forEach(([key, value]) => {
          if (typeof value === 'bigint') return;

          payload[key] = value;
        });

        dispatch(actions.telemetry.loadMavlink({ robotId, msgId, payload }));
      });
    }
  };

  // ROS 메시지 처리
  const handleMessageRos = (robotId, message) => {
    const data = JSON.parse(Buffer.from(message).toString());

    dispatch(
      actions.telemetry.loadRos({
        robotId,
        topic: data.topic,
        payload: data.message.data,
      })
    );
  };

  // IoT 메시지 처리
  const handleMessageIot = (robotId, message) => {
    dispatch(
      actions.telemetry.loadIot({
        robotId,
        payload: JSON.parse(Buffer.from(message).toString()),
      })
    );
  };

  // IoTHub 메시지 처리
  const handleIoTHubMessage = (robotId, message) => {
    dispatch(
      actions.event.load({
        robotId,
        payload: JSON.parse(Buffer.from(message).toString()),
      })
    );
  };

  const publishCommand = (robot, name, value) => {
    // 텔레메트리 수신 전 로봇 메타 데이터 초기화되지 않은 상태 시
    if (!robotMeta.current[robot.id]) return;

    switch (robot.model.agentType) {
      case 'MavLink':
        publishCommandMavLink(robot, name, value);
        break;

      case 'ROS':
        publishCommandRos(robot, name, value);
        break;

      default:
        break;
    }
  };

  // MavLink 명령 전송
  const publishCommandMavLink = (robot, name, paramsSet) => {
    const robotId = robot.id;
    const modelId = robot.model.id;

    const { sysId, compId, messages } = store.getState().commandSet[modelId][name];
    const protocol = new MavLinkProtocolV2(sysId, compId);

    messages.forEach((message) => {
      const { isMultiple, msgId, fields } = message;
      const paramsGroup = [];

      // 반복 메시지 경우
      if (isMultiple) {
        paramsSet.shift().forEach((params) => paramsGroup.push(params));
      }
      // 단일 메시지 경우
      else {
        paramsGroup.push(paramsSet.shift());
      }

      paramsGroup.forEach((params) => {
        const mavMessage = new registry.current[msgId]();
        const mavMessageFields = registry.current[msgId].FIELDS;

        fields.forEach((field) => {
          const { name } = mavMessageFields.find(({ source }) => source === field.name);

          // 변수인 경우
          if (field.isVariable) {
            mavMessage[name] = params.shift();
          } else {
            mavMessage[name] = field.value;
          }
        });

        const seq = robotMeta.current[robotId].commandSeq;
        robotMeta.current[robotId].commandSeq = (seq + 1) % 256;

        mqttWorker.current.postMessage({
          command: 'publish',
          data: {
            topic: `robots/${robotId}/command`,
            message: protocol.serialize(mavMessage, seq),
          },
        });
      });
    });
  };

  // ROS 명령 전송
  const publishCommandRos = (robot, name, param) => {
    const robotId = robot.id;
    const modelId = robot.model.id;

    const { type, topic, messageTemplate } = store.getState().commandSet[modelId][name];
    const compiled = _.template(messageTemplate);
    const data = compiled(param);

    mqttWorker.current.postMessage({
      command: 'publish',
      data: {
        topic: `robots/${robotId}/command`,
        message: JSON.stringify({
          topic,
          message: { type, data },
        }),
      },
    });
  };

  return <MessageContext.Provider value={{ subscribeTelemetry, publishCommand }}>{children}</MessageContext.Provider>;
};

export default Provider;
