import { createActions } from 'redux-actions';

export default createActions(
  {
    SET_CONFIG: (config) => ({ config }),
    CHANGE_MODE: (mode) => ({ mode }),
    SET_MOVE_FROM_INDEX: (moveFromIndex) => ({ moveFromIndex }),
    LOAD_LABELS: (labels) => ({ labels }),
    LOAD_MARKERS: (markers) => ({ markers }),
    LOAD_PATH: (path) => ({ path }),
    LOAD_SHOOTS: (shoots) => ({ shoots }),
  },
  { prefix: 'EDITOR' }
);
