import { createActions } from 'redux-actions';

export default createActions(
  {
    RESET: () => ({}),
    INIT: (robotId) => ({ robotId }),
    REMOVE: (robotId) => ({ robotId }),
    TOGGLE: (robotId, key) => ({ robotId, key }),
  },
  { prefix: 'ROBOT_OPTIONS' }
);
