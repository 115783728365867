import useMountEffect from '@restart/hooks/useMountEffect';
import { useNavigate } from 'react-router-dom';

const Page = () => {
  const navigate = useNavigate();

  useMountEffect(() => {
    navigate('/login', { replace: true });
  });

  return null;
};

export default Page;
