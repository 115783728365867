import {
  RiShutDownLine,
  RiFlightTakeoffLine,
  RiFlightLandLine,
  RiArrowUpDownLine,
  RiArrowRightUpLine,
  RiPauseLine,
  RiHome6Line,
  RiCamera2Line,
  RiFolderOpenLine,
  RiUpload2Line,
  RiPlayCircleLine,
  RiIndeterminateCircleLine,
} from 'react-icons/ri';

// Action
import Arm from './01_Action/01_Arm';
import Disarm from './01_Action/02_Disarm';
import Takeoff from './01_Action/03_Takeoff';
import Land from './01_Action/04_Land';
import Elevate from './01_Action/05_Elevate';
import Goto from './01_Action/06_Goto';
import Brake from './01_Action/07_Brake';
import Return from './01_Action/08_Return';
import Shoot from './01_Action/09_Shoot';
// Mission
import Load from './02_Mission/01_Load';
import Upload from './02_Mission/02_Upload';
import Start from './02_Mission/03_Start';
import Clear from './02_Mission/04_Clear';

const groups = [
  {
    name: 'Action',
    commands: [
      { column: 0, name: 'Arm', icon: RiShutDownLine, component: Arm },
      { column: 0, name: 'Disarm', icon: RiShutDownLine, component: Disarm },
      { column: 1, name: 'Takeoff', icon: RiFlightTakeoffLine, component: Takeoff },
      { column: 1, name: 'Goto', icon: RiArrowRightUpLine, component: Goto },
      { column: 2, name: 'Elevate', icon: RiArrowUpDownLine, component: Elevate },
      { column: 2, name: 'Brake', icon: RiPauseLine, component: Brake },
      { column: 3, name: 'Land', icon: RiFlightLandLine, component: Land },
      { column: 3, name: 'Return', icon: RiHome6Line, component: Return },
      { column: 4, name: 'Shoot', icon: RiCamera2Line, component: Shoot },
    ],
  },
  {
    name: 'Mission',
    commands: [
      { column: 0, name: 'Load', icon: RiFolderOpenLine, component: Load, singleRobotOnly: true },
      { column: 0, name: 'Upload', icon: RiUpload2Line, component: Upload },
      { column: 1, name: 'Start', icon: RiPlayCircleLine, component: Start },
      { column: 2, name: 'Clear', icon: RiIndeterminateCircleLine, component: Clear },
    ],
  },
];

export default groups;
