import classNames from 'classnames/bind';
import React, { useMemo, useState } from 'react';
import { useSelector, useStore } from 'react-redux';

import styles from './Notifications.module.scss';

import AccordionCaret from '@/components/ui/AccordionCaret';
import Bar from '@/components/ui/Bar';

const cx = classNames.bind(styles);

const Notifications = () => {
  const store = useStore();
  const notification = useSelector((state) => state.notification);
  const [isOpen, setIsOpen] = useState(true);

  const notifications = useMemo(() => {
    return Object.entries(notification).flatMap(([robotId, items]) =>
      items.map((item) => ({
        ...item,
        robot: store.getState().robots.find(({ id }) => id === robotId),
      }))
    );
  }, [notification]);

  const toggleOpen = (e) => {
    setIsOpen(!isOpen);
  };

  if (notifications.length === 0) return;

  return (
    <div className={cx('container')}>
      <div className={cx(['list', { open: isOpen }])} onClick={toggleOpen}>
        {notifications.map((notification) => (
          <div key={notification.id} className={cx('row')}>
            <div className={cx('robot')}>
              <div className={cx('color')} style={{ backgroundColor: notification.robot.color }} />
              <div className={cx('name')}>{notification.robot.name}</div>
            </div>
            <Bar />
            <div className={cx('text')}>{notification.text}</div>
            <div className={cx('time')}>{notification.time}</div>
          </div>
        ))}
      </div>
      <div onClick={toggleOpen}>
        <AccordionCaret up={isOpen} size={28} />
      </div>
    </div>
  );
};

export default Notifications;
