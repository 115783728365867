import useMountEffect from '@restart/hooks/useMountEffect';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('@token');

    if (token === null) {
      navigate('/', { replace: true });
    }
  }, [location.pathname]);

  return children;
};

export const UnprotectedRoute = ({ children }) => {
  const navigate = useNavigate();

  useMountEffect(() => {
    const token = localStorage.getItem('@token');
    if (token !== null) {
      navigate('/control-center', { replace: true });
    }
  });

  return children;
};
