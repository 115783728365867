import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useRef, useMemo } from 'react';
import { RiArrowRightLine } from 'react-icons/ri';

import styles from './ConfirmSlider.module.scss';
import Spinner from './Spinner';

const cx = classNames.bind(styles);

const ConfirmSlider = ({ width = 240, height = 56, onConfirm, isProcessing, labelStyle }) => {
  const trackRef = useRef();
  const buttonRef = useRef();
  const isDragging = useRef(false);
  const left = useRef(0);
  const maxX = useRef(0);
  const startX = useRef(0);

  const containerWidth = useMemo(() => {
    if (isProcessing) {
      return height;
    } else {
      return width;
    }
  }, [isProcessing]);

  useMountEffect(() => {
    maxX.current = trackRef.current.clientWidth - (height - 4);
  });

  const setLeft = (nextLeft) => {
    left.current = nextLeft;
    buttonRef.current.style.left = nextLeft + 'px';
  };

  const startDrag = (e) => {
    if (isProcessing) return;

    isDragging.current = true;

    // 마우스 드래그 시작 시
    if (e.type === 'mousedown') {
      startX.current = e.clientX;
      document.addEventListener('mousemove', onDrag);
      document.addEventListener('mouseup', endDrag);
    }
    // 터치 드래그 시작 시
    else {
      startX.current = e.touches[0].clientX;
      document.addEventListener('touchmove', onDrag);
      document.addEventListener('touchend', endDrag);
    }
  };

  const onDrag = (e) => {
    if (!isDragging.current) return;

    const clientX = e.clientX ?? e.touches[0].clientX;
    setLeft(Math.min(Math.max(0, clientX - startX.current), maxX.current));
  };

  const endDrag = (e) => {
    isDragging.current = false;

    if (left.current > maxX.current * 0.9) {
      onConfirm();
    }

    setLeft(0);

    // 마우스 드래그 종료 시
    if (e.type === 'mouseup') {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', endDrag);
    }
    // 터치 드래그 종료 시
    else {
      document.removeEventListener('touchmove', onDrag);
      document.removeEventListener('touchend', endDrag);
    }
  };

  return (
    <div
      ref={trackRef}
      className={cx(['container', { inactive: isProcessing }])}
      style={{ width: containerWidth, height, borderRadius: height / 2 }}>
      {!isProcessing && (
        <span className={cx('label')} style={labelStyle}>
          Slide to confirm
        </span>
      )}
      <div
        ref={buttonRef}
        className={cx(['button', { inactive: isProcessing }])}
        style={{
          left: left.current,
          width: height - 10,
          height: height - 10,
        }}
        onMouseDown={startDrag}
        onTouchStart={startDrag}>
        {!isProcessing && <RiArrowRightLine size={24} color="white" />}
        {isProcessing && <Spinner />}
      </div>
    </div>
  );
};

export default ConfirmSlider;
