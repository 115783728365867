import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useMemo, useState } from 'react';
import { MdOutlineCheck, MdOutlineClose } from 'react-icons/md';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Frame.module.scss';
import MorePanel from '../MorePanel';

import actions from '@/actions';
import ColorPickerModal from '@/components/modals/ColorPicker';
import SettingsModal from '@/components/modals/Settings';
import Bar from '@/components/ui/Bar';
import ConfirmSlider from '@/components/ui/ConfirmSlider';
import OlMap from '@/helpers/OlMap';
import useWaitingRobot from '@/hooks/useWaitingRobot';
import { ModalService as modal } from '@/libs/Modal';
import { NotifierService as notifier } from '@/libs/Notifier';

const cx = classNames.bind(styles);

const Frame = ({ robot, mode, top, children }) => {
  const dispatch = useDispatch();
  const focusRobotId = useSelector((state) => state.robot.focusRobotId);
  const targetRobotIds = useSelector((state) => state.robot.targetRobotIds);
  const robotOptions = useSelector((state) => state.robotOptions[robot.id]);
  const [monitorStep, setMonitorStep] = useState(0);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [isWaiting] = useWaitingRobot(robot.id);

  // 선택 여부
  const isTarget = useMemo(() => targetRobotIds.includes(robot.id), [targetRobotIds]);
  // 선택 가능여부
  const isTargetable = useMemo(() => robot.isOwned && robot.isActive, [robot]);

  const monitors = useMemo(() => {
    return Array.isArray(children) ? children : [children];
  }, []);

  useUpdateEffect(() => {
    // 로봇 연결 해제된 경우
    if (!robot.isActive) {
      setMonitorStep(0);
    }
  }, [robot.isActive]);

  useUpdateEffect(() => {
    if (isTarget && isWaiting) {
      dispatch(actions.robot.toggle(robot.id));
    }
    if (!isWaiting) {
      setIsShowConfirm(false);
    }
  }, [isWaiting]);

  const showColorPicker = (e) => {
    e.stopPropagation();
    if (!robot.isOwned) return;

    modal.show(ColorPickerModal, { robot });
  };

  const toggleTarget = () => {
    if (isWaiting) return;
    if (!robot.isActive) return;
    if (!isTargetable) return;

    dispatch(actions.robot.toggle(robot.id));
    dispatch(actions.command.toggle());
  };

  const decreaseBodyStep = (e) => {
    e.stopPropagation();

    if (isWaiting) {
      setMonitorStep(0);
    } else if (0 < monitorStep) {
      setMonitorStep(monitorStep - 1);
    }
  };

  const increaseBodyStep = (e) => {
    e.stopPropagation();

    if (isWaiting) {
      setMonitorStep(monitors.length);
    } else if (monitorStep < monitors.length) {
      setMonitorStep(monitorStep + 1);
    }
  };

  const toggleConfirm = () => {
    setIsShowConfirm(!isShowConfirm);
  };

  const doDisconnect = () => {
    dispatch(actions.event.remove(robot.id));
    dispatch(actions.missions.unload(robot.id));
    dispatch(actions.notification.remove(robot.id));
    dispatch(actions.robotOptions.remove(robot.id));
    dispatch(actions.robots.deactivate(robot.id));
    dispatch(actions.telemetry.remove(robot.id));
  };

  const openSettings = (e) => {
    modal.show(SettingsModal, { robot });
  };

  const toggleOption = (option) => {
    dispatch(actions.robotOptions.toggle(robot.id, option));
  };

  const toggleFocus = (e) => {
    // 지도 내 해당 로봇 마커 존재하지 않는 경우
    if (!OlMap.getMap().getOverlayById(`robots/${robot.id}`)) {
      notifier.error('Location has not been defined yet.');
      return;
    }

    if (focusRobotId === robot.id) {
      dispatch(actions.robot.setFocus());
    } else {
      dispatch(actions.robot.setFocus(robot.id));
    }
  };

  const removeTrace = (e) => {
    OlMap.removeShoot(robot.id);
    OlMap.removeFootprint(robot.id);
    OlMap.removeIoTHubEvent(robot.id);
  };

  const moreMenus = useMemo(() => {
    const draftMenus = [
      { name: `${robotOptions?.label ? 'Hide' : 'Show'} Label`, callback: () => toggleOption('label') },
      { name: `${robotOptions?.video ? 'Hide' : 'Show'} Video`, callback: () => toggleOption('video') },
      { name: `Set Focus ${focusRobotId === robot.id ? 'Off' : 'On'}`, callback: toggleFocus },
      { name: 'Remove Trace', callback: removeTrace },
    ];

    const firmware = robot.model.maker.split(' ')[0];
    if (['ArduPilot', 'PX4'].includes(firmware) && robot.isOwned) {
      draftMenus.push({ name: 'Settings', callback: openSettings });
    }

    return draftMenus;
  }, [robotOptions, focusRobotId]);

  return (
    <div
      id={`dashboard/${robot.id}`}
      className={cx(['container', { targetable: !isWaiting && isTargetable }])}
      onClick={toggleTarget}>
      <div className={cx(['tab', { target: isTarget }])}>
        <div>
          <MdOutlineCheck size={20} color="white" />
        </div>
      </div>
      <div className={cx(['box', { inactive: !robot.isActive }])}>
        <div
          className={cx(['color', { clickable: robot.isOwned }])}
          style={{ backgroundColor: robot.color }}
          onClick={showColorPicker}
        />
        {/* Top */}
        {robot.isActive && top}
        {/* Face */}
        <div className={cx('face')}>
          <div className={cx(['thumbnail', { active: robot.isActive }])}>
            <img src={robot.thumbnailUrl ?? robot.model.thumbnailUrl} width="100%" alt={robot.model.name} />
          </div>
          <div className={cx('info')}>
            <div className={cx(['name', { active: robot.isActive }])}>{robot.name}</div>
            {robot.isActive && mode}
          </div>
          {robot.isActive && <MorePanel data={moreMenus} />}
        </div>
        {/* Monitors */}
        {monitors.map((section, index) => {
          const hide = !robot.isActive || isWaiting || monitorStep < index + 1;

          return (
            <div key={index} className={cx(['monitor', { hide }])}>
              {section}
            </div>
          );
        })}
        {/* Waiting */}
        {isWaiting && 0 < monitorStep && (
          <div className={cx('waiting')}>
            <div className={cx('title')}>Communication Lost</div>
            <div className={cx('message')}>
              Please wait for the reconnection,
              <br />
              or manually disconnect the robot.
            </div>
            {!isShowConfirm && (
              <div className={cx('button')} onClick={toggleConfirm}>
                Disconnect
              </div>
            )}
            {isShowConfirm && (
              <div className={cx('sliderWrapper')}>
                <ConfirmSlider width={172} height={40} onConfirm={doDisconnect} labelStyle={{ fontSize: 12 }} />
                <div className={cx('cancel')} onClick={toggleConfirm}>
                  <MdOutlineClose size={16} color="white" />
                </div>
              </div>
            )}
          </div>
        )}
        {robot.isActive && (
          <div className={cx('bottom')}>
            <div className={cx(['button', { active: 0 < monitorStep }])} onClick={decreaseBodyStep}>
              <RiArrowUpSLine size={14} color={0 < monitorStep ? 'white' : 'gray'} />
            </div>
            <Bar height={6} />
            <div className={cx(['button', { active: monitorStep < monitors.length }])} onClick={increaseBodyStep}>
              <RiArrowDownSLine size={14} color={monitorStep < monitors.length ? 'white' : 'gray'} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Frame;
