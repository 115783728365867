import classNames from 'classnames/bind';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import MissionItemRow from '@/components/ui/MissionItemRow';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const domRefs = useRef({
    item: useRef(),
    repeat: useRef(),
  }).current;

  useEffect(() => {
    domRefs.item.current.value = missionItem.data.item;
    domRefs.repeat.current.value = missionItem.data.repeat;
  }, [missionItem.data]);

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = Math.max(1, Number(e.target.value));
    dispatch(actions.mission.updateMissionItem(index, name, value));
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Item Number">
        <input
          ref={domRefs.item}
          name="item"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Repeat">
        <input
          ref={domRefs.repeat}
          name="repeat"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
    </div>
  );
};

export default MissionItem;
