import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import actions from '@/actions';
import { removeAuthToken } from '@/helpers/API';
import { postLogout } from '@/helpers/Requester';
import { ModalService as modal } from '@/libs/Modal';

const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const doLogout = () => {
    postLogout();
    removeAuthToken();
    navigate('/', { replace: true });

    dispatch(actions.command.reset());
    dispatch(actions.event.reset());
    dispatch(actions.map.reset());
    dispatch(actions.missions.reset());
    dispatch(actions.notification.reset());
    dispatch(actions.robot.reset());
    dispatch(actions.robotOptions.reset());
    dispatch(actions.robots.reset());
    dispatch(actions.telemetry.reset());
    dispatch(actions.user.reset());

    modal.hide();
  };

  return { doLogout };
};

export default useLogout;
