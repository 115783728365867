import { createActions } from 'redux-actions';

export default createActions(
  {
    RESET: () => ({}),
    SET_CENTER: (lat, lng) => ({ lat, lng }),
    SET_POSITION_WITH_ZOOM: (lat, lng, zoom) => ({ lat, lng, zoom }),
    TOGGLE_SHOOTS: () => ({}),
  },
  { prefix: 'MAP' }
);
