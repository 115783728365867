import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useMemo, useState } from 'react';
import { MdOutlineCheck } from 'react-icons/md';
import { RiArrowRightSFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Robot.module.scss';

import actions from '@/actions';
import Spinner from '@/components/ui/Spinner';
import API from '@/helpers/API';

const cx = classNames.bind(styles);

const Robot = ({ data: robot }) => {
  const dispatch = useDispatch();
  const targetRobotIds = useSelector((state) => state.robot.targetRobotIds);
  const [count, setCount] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // 선택 여부
  const isTarget = useMemo(() => targetRobotIds.includes(robot.id), [targetRobotIds]);

  useMountEffect(() => {
    loadData();
  });

  useUpdateEffect(() => {
    if (!isTarget) return;

    loadData();
  }, [isTarget]);

  const loadData = () => {
    setIsLoading(true);

    API.get(`/robots/${robot.id}/videos`)
      .then(({ success, data }) => {
        if (success) {
          const videos = [...data.videos];
          videos.forEach((video) => (video.robotId = robot.id));

          dispatch(actions.videos.load(videos));
          setCount(videos.length);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const toggleTarget = () => {
    dispatch(actions.robot.toggle(robot.id));
  };

  return (
    <div className={cx('container')} onClick={toggleTarget}>
      <div className={cx(['tab', { target: isTarget }])}>
        <div>
          <MdOutlineCheck size={20} color="white" />
        </div>
      </div>
      <div className={cx('box')}>
        <div className={cx('wrapper')}>
          <div className={cx('thumbnail')}>
            <img src={robot.thumbnailUrl ?? robot.model.thumbnailUrl} width="100%" alt={robot.model.name} />
          </div>
          <div className={cx('info')}>
            <div className={cx('name')}>{robot.name}</div>
            <div className={cx('model')}>{robot.model.name}</div>
          </div>
          <div className={cx('right')}>
            {isLoading && <Spinner />}
            {!isNaN(count) && !isLoading && <div className={cx(['count', { exist: count > 0 }])}>{count}</div>}
            <div className={cx('caret')}>
              <RiArrowRightSFill size={20} color="gray" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Robot;
