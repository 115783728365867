import {
  RiShutDownLine,
  RiDragMoveLine,
  RiArrowRightUpLine,
  RiArrowGoBackLine,
  RiPauseLine,
  RiFolderOpenLine,
  RiUpload2Line,
  RiPlayCircleLine,
  RiIndeterminateCircleLine,
} from 'react-icons/ri';

// Action
import Arm from './01_Action/01_Arm';
import Disarm from './01_Action/02_Disarm';
import Move from './01_Action/03_Move';
import Goto from './01_Action/04_Goto';
import Return from './01_Action/05_Return';
import Brake from './01_Action/06_Brake';
// Mission
import Load from './02_Mission/01_Load';
import Upload from './02_Mission/02_Upload';
import Start from './02_Mission/03_Start';
import Clear from './02_Mission/04_Clear';

const groups = [
  {
    name: 'Action',
    commands: [
      { column: 0, name: 'Arm', icon: RiShutDownLine, component: Arm },
      { column: 0, name: 'Disarm', icon: RiShutDownLine, component: Disarm },
      { column: 1, name: 'Move', icon: RiDragMoveLine, component: Move, singleRobotOnly: true },
      { column: 1, name: 'Goto', icon: RiArrowRightUpLine, component: Goto },
      { column: 2, name: 'Brake', icon: RiPauseLine, component: Brake },
      { column: 2, name: 'Return', icon: RiArrowGoBackLine, component: Return },
    ],
  },
  {
    name: 'Mission',
    commands: [
      { column: 0, name: 'Load', icon: RiFolderOpenLine, component: Load, singleRobotOnly: true },
      { column: 0, name: 'Upload', icon: RiUpload2Line, component: Upload },
      { column: 1, name: 'Start', icon: RiPlayCircleLine, component: Start },
      { column: 2, name: 'Clear', icon: RiIndeterminateCircleLine, component: Clear },
    ],
  },
];

export default groups;
