import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useState, useRef } from 'react';
import { RiMore2Line } from 'react-icons/ri';

import styles from './MorePanel.module.scss';

const cx = classNames.bind(styles);

const MorePanel = ({ data: menus }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef();
  const panelRef = useRef();

  useUpdateEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', unfocusPanel);
    }

    return () => {
      document.removeEventListener('mousedown', unfocusPanel);
    };
  }, [isOpen]);

  const unfocusPanel = (e) => {
    if (containerRef.current.contains(e.target)) return;
    if (panelRef.current.contains(e.target)) return;

    setIsOpen(false);
  };

  const togglePanel = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);

    const rect = e.currentTarget.getBoundingClientRect();
    panelRef.current.style.top = `${rect.y + 28}px`;
    panelRef.current.style.left = `${rect.x + 4}px`;
  };

  return (
    <div ref={containerRef} className={cx('container')} onClick={togglePanel}>
      <RiMore2Line size={16} color="white" />
      <div ref={panelRef} className={cx(['panel', { open: isOpen }])}>
        <div className={cx('arrow')} />
        {menus.map((menu, index) => (
          <div key={index} className={cx('item')} onClick={menu.callback}>
            {menu.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MorePanel;
