import produce from 'immer';
import { handleActions } from 'redux-actions';

const initialState = {};

export default handleActions(
  {
    'MISSIONS/RESET': (state, action) => initialState,
    'MISSIONS/LOAD': (state, action) =>
      produce(state, (draft) => {
        const { robotId, missionItems } = action.payload;
        draft[robotId] = missionItems;
      }),
    'MISSIONS/UNLOAD': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;
        delete draft[robotId];
      }),
  },
  initialState
);
