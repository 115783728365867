import classNames from 'classnames/bind';
import React from 'react';

import styles from './Marker.module.scss';

import { getCSSVariable } from '@/utils/theme';

const cx = classNames.bind(styles);

const Marker = ({ color = getCSSVariable('--tint-color'), data: marker }) => {
  return (
    <div className={cx('container')}>
      {marker.name && <div className={cx('name')}>{marker.name}</div>}
      <div className={cx('circle')} style={{ backgroundColor: color }}>
        <div className={cx('label')}>{marker.label}</div>
      </div>
      <div className={cx('arrow')} style={{ borderTopColor: color }} />
    </div>
  );
};

export default Marker;
