import produce from 'immer';
import { handleActions } from 'redux-actions';

const initialState = {
  targetRobotIds: [],
  focusRobotId: null,
};

export default handleActions(
  {
    'ROBOT/RESET': (state, action) => initialState,
    'ROBOT/TOGGLE': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;

        // Focus 설정된 로봇 아닌 경우
        if (state.focusRobotId !== robotId) {
          draft.focusRobotId = null;
        }

        if (state.targetRobotIds.includes(robotId)) {
          draft.targetRobotIds.splice(state.targetRobotIds.indexOf(robotId), 1);
        } else {
          draft.targetRobotIds.push(robotId);
        }
      }),
    'ROBOT/SET_FOCUS': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;

        if (robotId) {
          draft.focusRobotId = robotId;
        } else {
          draft.focusRobotId = null;
        }
      }),
  },
  initialState
);
