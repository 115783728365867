import React from 'react';
import { useSelector } from 'react-redux';

import Group from './Group';

const Survey = () => {
  const missionItems = useSelector((state) => state.mission.json.missionItems);

  return (
    <>
      {missionItems
        .filter(({ type }) => type === 'cusSurvey')
        .map((missionItem) => {
          const index = missionItems.findIndex(({ id }) => missionItem.id === id);
          return <Group key={index} index={index} data={missionItem.data} />;
        })}
    </>
  );
};

export default Survey;
