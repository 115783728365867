import produce from 'immer';
import { handleActions } from 'redux-actions';

const initialState = {
  isShow: false,
};

export default handleActions(
  {
    'MAP3D/SHOW': (state, action) =>
      produce(state, (draft) => {
        draft.isShow = true;
      }),
    'MAP3D/HIDE': (state, action) =>
      produce(state, (draft) => {
        draft.isShow = false;
      }),
  },
  initialState
);
