import classNames from 'classnames/bind';
import React, { useState } from 'react';

import Battery from './01_Battery';
import Radio from './02_Radio';
import GCS from './03_GCS';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Plane = ({ robot }) => {
  const [menuIndex, setMenuIndex] = useState(0);

  return (
    <div className={cx('container')}>
      <div className={cx('menus')}>
        <div onClick={() => setMenuIndex(0)} className={cx(['menu', { target: menuIndex === 0 }])}>
          Battery
        </div>
        <div onClick={() => setMenuIndex(1)} className={cx(['menu', { target: menuIndex === 1 }])}>
          Radio
        </div>
        <div onClick={() => setMenuIndex(2)} className={cx(['menu', { target: menuIndex === 2 }])}>
          GCS
        </div>
      </div>
      <div className={cx('console')}>
        {menuIndex === 0 && <Battery robot={robot} />}
        {menuIndex === 1 && <Radio robot={robot} />}
        {menuIndex === 2 && <GCS robot={robot} />}
      </div>
    </div>
  );
};

export default Plane;
