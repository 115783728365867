import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useMemo, useRef, useState } from 'react';

import Calibration from './01_Calibration';
import Parameters from './02_Parameters';
import VideoStream from './03_VideoStream';
import Checklist from './04_Checklist';
import FailSafe from './05_FailSafe';
import Ntrip from './06_Ntrip';
import styles from './index.module.scss';

import ModalWrapper from '@/components/ui/Modal';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Modal = ({ robot, tabIndex }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(tabIndex);
  const tabsRef = useRef();

  const isArduPilot = useMemo(() => {
    return robot.model.maker.split(' ')[0] === 'ArduPilot';
  }, []);

  useMountEffect(() => {
    // 활성 탭 index 지정 호출 시 (e.g. Edit Checklist)
    if (tabIndex > -1) return;

    const nextActiveTabIndex = Number(tabsRef.current.children[0].dataset.index);
    setActiveTabIndex(nextActiveTabIndex);
  });

  const selectTab = (e) => {
    setActiveTabIndex(Number(e.target.dataset.index));
  };

  const close = () => {
    modal.hide();
  };

  return (
    <ModalWrapper>
      <div className={cx('container')}>
        <div ref={tabsRef} className={cx('tabs')}>
          {isArduPilot && (
            <>
              <div data-index="0" className={cx(['tab', { active: activeTabIndex === 0 }])} onClick={selectTab}>
                Calibration
              </div>
              <div data-index="1" className={cx(['tab', { active: activeTabIndex === 1 }])} onClick={selectTab}>
                Parameters
              </div>
            </>
          )}
          <div data-index="2" className={cx(['tab', { active: activeTabIndex === 2 }])} onClick={selectTab}>
            Video Stream
          </div>
          <div data-index="3" className={cx(['tab', { active: activeTabIndex === 3 }])} onClick={selectTab}>
            Checklist
          </div>
          {isArduPilot && (
            <>
              <div data-index="4" className={cx(['tab', { active: activeTabIndex === 4 }])} onClick={selectTab}>
                Fail Safe
              </div>
              <div data-index="5" className={cx(['tab', { active: activeTabIndex === 5 }])} onClick={selectTab}>
                NTRIP
              </div>
            </>
          )}
        </div>
        <div className={cx('pane')}>
          {isArduPilot && activeTabIndex === 0 && <Calibration robot={robot} />}
          {isArduPilot && activeTabIndex === 1 && <Parameters robot={robot} />}
          {activeTabIndex === 2 && <VideoStream robot={robot} />}
          {activeTabIndex === 3 && <Checklist robot={robot} />}
          {isArduPilot && activeTabIndex === 4 && <FailSafe robot={robot} />}
          {isArduPilot && activeTabIndex === 5 && <Ntrip robot={robot} />}
        </div>
      </div>
      <div className={cx('bottom')}>
        <button type="button" className={cx('button')} onClick={close}>
          Close
        </button>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
