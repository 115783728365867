import API from './API';

// 로그아웃
export const postLogout = () => {
  return API.post('/auth/logout');
};

// 로봇 수정
export const patchRobot = (robotId, data) => {
  return API.patch(`/robots/${robotId}`, data);
};

// 미션 저장
export const postMission = (name, json) => {
  return API.post('/missions', { name, json });
};

// 미션 내역 조회
export const getMissions = () => {
  return API.get('/missions');
};

// 미션 상세 조회
export const getMission = (id) => {
  return API.get(`/missions/${id}`);
};

// 미션 삭제
export const deleteMission = (id) => {
  return API.delete(`/missions/${id}`);
};

// 미션 수정
export const patchMission = (id, name, json) => {
  return API.patch(`/missions/${id}`, { name, json });
};
