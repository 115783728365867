import produce from 'immer';
import { handleActions } from 'redux-actions';

import { getPath } from '@/hooks/useEditorPath';

const initialState = [];

export default handleActions(
  {
    'MISSIONS/ADD': (state, action) =>
      produce(state, (draft) => {
        const { id, name, json } = action.payload;

        draft.push({
          id,
          name,
          path: getPath(json.missionItems),
        });
      }),
    'MISSIONS/REMOVE': (state, action) =>
      produce(state, (draft) => {
        const { index } = action.payload;

        draft.splice(index, 1);
      }),
  },
  initialState
);
