import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import React, { useState } from 'react';

import styles from './Monitor.module.scss';

import { Cell } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Monitor = ({ robotId }) => {
  const [telemetry, setTelemetry] = useState();

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry`, setTelemetry);

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return (
    <div className={cx('container')}>
      <div className={cx('boxes')}>
        <Cell
          data={[
            {
              title: 'Latitude',
              value: telemetry?.lat.toFixed(6) ?? 0,
            },
          ]}
        />
        <Cell
          data={[
            {
              title: 'Longitude',
              value: telemetry?.lon.toFixed(6) ?? 0,
            },
          ]}
        />
        <Cell
          data={[
            {
              title: 'Altitude',
              value: telemetry ? commaNumber(telemetry.alt.toFixed(1)) : 0,
              unit: 'm',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Monitor;
