import classNames from 'classnames/bind';
import React from 'react';

import styles from './Logout.module.scss';

import ModalWrapper from '@/components/ui/Modal';
import useLogout from '@/hooks/useLogout';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Modal = () => {
  const { doLogout } = useLogout();

  const close = () => {
    modal.hide();
  };

  return (
    <ModalWrapper>
      <div className={cx('message')}>Do you really want to logout?</div>
      <div className={cx('bottom')}>
        <button type="button" className={cx('button')} onClick={close}>
          Cancel
        </button>
        <button type="button" className={cx(['button', 'accent'])} onClick={doLogout}>
          Logout
        </button>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
