import produce from 'immer';
import { handleActions } from 'redux-actions';

import { OL_DEFAULT_CENTER, OL_DEFAULT_ZOOM } from '@/config';

const initialState = {
  center: OL_DEFAULT_CENTER,
  position: OL_DEFAULT_CENTER,
  zoom: OL_DEFAULT_ZOOM,
  isShowShoots: true,
};

export default handleActions(
  {
    'MAP/RESET': (state, action) => initialState,
    'MAP/SET_CENTER': (state, action) =>
      produce(state, (draft) => {
        const { lat, lng } = action.payload;
        draft.center = { lat, lng };
      }),
    'MAP/SET_POSITION_WITH_ZOOM': (state, action) =>
      produce(state, (draft) => {
        const { lat, lng, zoom } = action.payload;
        draft.position = { lat, lng };

        if (zoom) {
          draft.zoom = zoom;
        }
      }),
    'MAP/TOGGLE_SHOOTS': (state, action) =>
      produce(state, (draft) => {
        draft.isShowShoots = !state.isShowShoots;
      }),
  },
  initialState
);
