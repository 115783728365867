import { useSelector } from 'react-redux';

const useHandlerWithMoveCheck = () => {
  const moveFromIndex = useSelector((state) => state.editor.moveFromIndex);

  const handleWithMoveCheck = (func) => {
    return (e) => {
      e.stopPropagation();
      
      // If `moveFromIndex` exists, mission items are being rearranged.
      // In this case, prevent function execution.
      if (moveFromIndex !== null) return;
      func(e);
    };
  };

  return { handleWithMoveCheck };
};

export default useHandlerWithMoveCheck;
