import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState, useRef } from 'react';

import styles from './ChangePassword.module.scss';

import ModalWrapper from '@/components/ui/Modal';
import API from '@/helpers/API';
import useLogout from '@/hooks/useLogout';
import { ModalService as modal } from '@/libs/Modal';
import { NotifierService as notifier } from '@/libs/Notifier';

const cx = classNames.bind(styles);

const Modal = () => {
  const { doLogout } = useLogout();
  const [info, setInfo] = useState({
    currentPassword: '',
    newPassword: '',
    rePassword: '',
  });
  const currentPasswordRef = useRef();
  const newPasswordRef = useRef();
  const rePasswordRef = useRef();

  useMountEffect(() => {
    // 현재 비밀번호 입력 필드 포커스
    currentPasswordRef.current.focus();
  });

  const close = () => {
    modal.hide();
  };

  const handleChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const pressEnter = (e) => {
    if (e.code === 'Enter') {
      switch (e.target.name) {
        case 'currentPassword':
          newPasswordRef.current.focus();
          break;

        case 'newPassword':
          rePasswordRef.current.focus();
          break;

        case 'rePassword':
          doChangePassword();
          break;

        default:
          break;
      }
    }
  };

  // 유효성 검사
  const validAll = () => {
    if (info.currentPassword.trim() === '') {
      notifier.error('Please enter your current password.');
      currentPasswordRef.current.focus();
      return false;
    }
    if (info.newPassword.trim() === '') {
      notifier.error('Please enter a new password.');
      newPasswordRef.current.focus();
      return false;
    }
    if (info.rePassword.trim() === '') {
      notifier.error('Please enter a new password again.');
      rePasswordRef.current.focus();
      return false;
    }
    if (info.newPassword !== info.rePassword) {
      notifier.error('Please check a new password.');
      rePasswordRef.current.focus();
      return false;
    }
    return true;
  };

  const doChangePassword = () => {
    if (!validAll()) return;

    const data = {
      currentPassword: info.currentPassword,
      newPassword: info.newPassword,
    };

    API.patch('/accounts/password', data).then(({ success }) => {
      if (success) {
        doLogout();
      } else {
        notifier.error('Please check your current password.');
      }
    });
  };

  return (
    <ModalWrapper>
      <div className={cx('fields')}>
        <label>
          Current Password
          <input
            ref={currentPasswordRef}
            name="currentPassword"
            type="password"
            value={info.currentPassword}
            onChange={handleChange}
            onKeyDown={pressEnter}
          />
        </label>
        <label>
          New Password
          <input
            ref={newPasswordRef}
            name="newPassword"
            type="password"
            value={info.newPassword}
            onChange={handleChange}
            onKeyDown={pressEnter}
          />
        </label>
        <label>
          Re-Password
          <input
            ref={rePasswordRef}
            name="rePassword"
            type="password"
            value={info.rePassword}
            onChange={handleChange}
            onKeyDown={pressEnter}
          />
        </label>
      </div>
      <div className={cx('bottom')}>
        <button type="button" className={cx('button')} onClick={close}>
          Cancel
        </button>
        <button type="button" className={cx(['button', 'accent'])} onClick={doChangePassword}>
          Change
        </button>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
