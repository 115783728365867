import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import React, { useContext, useState, useMemo, useRef } from 'react';

import styles from './index.module.scss';
import ProcessingButton from '../ProcessingButton';

import Checkbox from '@/components/ui/Checkbox';
import EmptyList from '@/components/ui/EmptyList';
import ProgressBar from '@/components/ui/ProgressBar';
import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';
import EventEmitter from '@/libs/EventEmitter';
import { waitUntilParamSet } from '@/utils/Sleep';

const cx = classNames.bind(styles);

const FORM_TYPE = {
  UNDEFINED: 0,
  READ_ONLY: 1,
  FIELD: 2,
  SELECT: 3,
  CHECKBOX: 4,
};

const Parameters = ({ robot }) => {
  const { publishCommand } = useContext(MessageContext);
  const [keyword, setKeyword] = useState('');
  const [paramIds, setParamIds] = useState([]);
  const [paramInfo, setParamInfo] = useState();
  const paramDocs = useRef();
  const totalParamCount = useRef(0);
  const selectedParamId = useRef();
  const fieldRef = useRef();

  // 파라미터 수신 중 여부
  const isLoading = useMemo(() => {
    return totalParamCount.current === 0 || paramIds.length < totalParamCount.current;
  }, [paramIds]);

  // 노출 파라미터 선별
  const filteredParamIds = useMemo(() => {
    // 파라미터 수신 중
    if (isLoading) return [];

    // 검색 키워드 미입력 시
    if (keyword.trim().length === 0) return paramIds;

    return paramIds.filter((paramId) => {
      const upperCase = keyword.trim().toUpperCase();
      return paramId.includes(upperCase);
    });
  }, [isLoading, keyword]);

  const isOutOfRange = useMemo(() => {
    if (!paramInfo) return;
    if (paramInfo.formType !== FORM_TYPE.FIELD) return;

    const value = Number(paramInfo.paramValue);
    return value < paramInfo.range.low || paramInfo.range.high < value;
  }, [paramInfo]);

  useMountEffect(() => {
    loadParamDoc();
    // TODO: FORMAT_VERSION 파라미터 단일 조회 제거
    publishCommand(robot, 'param/read', [['FORMAT_VERSION']]);
    publishCommand(robot, 'param/list', [[]]);

    const subscribeToken = EventEmitter.subscribe(`${robot.id}/telemetry/paramValue`, (data) => {
      if (selectedParamId.current) {
        loadSingleParam(data);
      } else {
        loadAllParamIds(data);
      }
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  useUpdateEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.value = paramInfo.paramValue;
    }
  }, [paramInfo]);

  const loadParamDoc = () => {
    fetch(robot.metadata['FIRMWARE_PARAMETER_URL'])
      .then((response) => response.json())
      .then((json) => {
        paramDocs.current = Object.values(json).reduce((acc, params) => {
          return { ...acc, ...params };
        }, {});
      });
  };

  const loadSingleParam = (data) => {
    // 갱신 파라미터(65535)가 선택된 파라미터가 아닌 경우
    // e.g. STAT_RUNTIME 파라미터 수시 발생
    if (data[65535].paramId !== selectedParamId.current) return;

    const paramDoc = { ...paramDocs.current[selectedParamId.current] };
    Object.entries(paramDoc).forEach(([key, value]) => {
      const newKey = key[0].toLowerCase() + key.slice(1);
      paramDoc[newKey] = value;
      delete paramDoc[key];
    });

    // 양식 유형 정의
    let formType = FORM_TYPE.UNDEFINED;
    if (Object.hasOwn(paramDoc, 'readOnly')) {
      formType = FORM_TYPE.READ_ONLY;
    } else if (Object.hasOwn(paramDoc, 'values')) {
      formType = FORM_TYPE.SELECT;
    } else if (Object.hasOwn(paramDoc, 'range')) {
      formType = FORM_TYPE.FIELD;
    } else if (Object.hasOwn(paramDoc, 'bitmask')) {
      formType = FORM_TYPE.CHECKBOX;
    }

    setParamInfo({
      formType,
      ...paramDoc,
      ...data[65535],
    });
  };

  const loadAllParamIds = (data) => {
    // 파라미터 1개 선별
    let randomParam;
    for (const key in data) {
      randomParam = data[key];
      break;
    }
    // 파라미터 선별 실패 시
    if (!randomParam) return;

    // 총 파라미터 갯수
    totalParamCount.current = randomParam.paramCount;
    // 수신된 파라미터
    const nextParamIds = Object.values(data)
      .filter(({ paramIndex }) => paramIndex < 65535) // 갱신 파라미터(65535) 제외
      .map(({ paramId }) => paramId);

    setParamIds(nextParamIds);
  };

  const changeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const doSelect = (e) => {
    const paramId = e.target.dataset.id;

    selectedParamId.current = paramId;
    publishCommand(robot, 'param/read', [[paramId]]);
  };

  const handleChange = (e) => {
    setParamInfo({ ...paramInfo, paramValue: e.target.value });
  };

  const handleBlur = (e) => {
    let paramValue = e.target.value;
    if (paramValue === '') {
      paramValue = paramInfo.range?.low ?? 0;
    }

    setParamInfo({ ...paramInfo, paramValue: Number(paramValue) });
  };

  const toggleCheck = (key) => {
    const bit = 1 << Number(key);
    const isBitOn = Boolean(paramInfo.paramValue & bit);

    let paramValue = paramInfo.paramValue;
    if (isBitOn) {
      paramValue &= ~bit;
    } else {
      paramValue |= bit;
    }

    setParamInfo({ ...paramInfo, paramValue });
  };

  const doSave = () => {
    return new Promise((resolve) => {
      publishCommand(robot, 'param/set', [[paramInfo.paramId, Number(paramInfo.paramValue), paramInfo.paramType]]);
      waitUntilParamSet().then(() => resolve(true));
    });
  };

  // 파라미터 수신 중
  if (isLoading) {
    return (
      <div className={cx('loading')}>
        <ProgressBar current={paramIds.length} total={totalParamCount.current} />
      </div>
    );
  }

  return (
    <div className={cx('container')}>
      <div className={cx('left')}>
        <input type="text" placeholder="ID" onChange={changeKeyword} />
        {filteredParamIds.length === 0 && (
          <div className={cx('empty')}>
            <EmptyList />
          </div>
        )}
        {filteredParamIds.length > 0 && (
          <ul>
            {filteredParamIds.map((paramId) => (
              <li
                key={paramId}
                data-id={paramId}
                className={cx({ selected: paramId === selectedParamId.current })}
                onClick={doSelect}>
                {paramId}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className={cx('console')}>
        {!paramInfo && (
          <div className={cx('empty')}>
            <EmptyList message="No item selected" />
          </div>
        )}
        {paramInfo && (
          <>
            <div className={cx('id')}>{paramInfo.paramId}</div>
            <div className={cx('info')}>
              <div className={cx('name')}>{paramInfo.displayName}</div>
              <div className={cx('desc')}>{paramInfo.description}</div>
            </div>
            <div className={cx('form')}>
              {paramInfo.formType === FORM_TYPE.READ_ONLY && <div className={cx('title')}>Current Value :</div>}
              {paramInfo.formType !== FORM_TYPE.READ_ONLY && <div className={cx('title')}>Change Value :</div>}
              {paramInfo.formType === FORM_TYPE.READ_ONLY && (
                <div className={cx('field')}>
                  <input type="number" value={paramInfo.paramValue} readOnly />
                  <div className={cx('unit')}>{paramInfo.units}</div>
                </div>
              )}
              {[FORM_TYPE.UNDEFINED, FORM_TYPE.FIELD].includes(paramInfo.formType) && (
                <div className={cx('field')}>
                  <input
                    ref={fieldRef}
                    type="number"
                    defaultValue={paramInfo.paramValue}
                    onBlur={handleBlur}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        e.target.blur();
                      }
                    }}
                  />
                  <div className={cx('unit')}>{paramInfo.units}</div>
                </div>
              )}
              {paramInfo.formType === FORM_TYPE.UNDEFINED && (
                <div className={cx('message')}>This value hasn’t been validated. Please make sure it’s correct.</div>
              )}
              {isOutOfRange && (
                <div className={cx('message')}>This value is out of range. Please make sure it’s correct.</div>
              )}
              {paramInfo.formType === FORM_TYPE.FIELD && (
                <ul className={cx('guide')}>
                  <li>∙ Min : {commaNumber(paramInfo.range.low)}</li>
                  <li>∙ Max : {commaNumber(paramInfo.range.high)}</li>
                  {Object.hasOwn(paramInfo, 'increment') && <li>∙ Increment : {commaNumber(paramInfo.increment)}</li>}
                </ul>
              )}
              {paramInfo.formType === FORM_TYPE.SELECT && (
                <select value={paramInfo.paramValue} onChange={handleChange}>
                  {Object.entries(paramInfo.values).map(([key, value], index) => (
                    <option key={index} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              )}
              {paramInfo.formType === FORM_TYPE.CHECKBOX && (
                <>
                  <div className={cx('field')}>
                    <input type="number" value={paramInfo.paramValue} readOnly />
                  </div>
                  <div className={cx('options')}>
                    {Object.entries(paramInfo.bitmask).map(([key, value]) => {
                      const isChecked = Boolean(paramInfo.paramValue & (1 << Number(key)));

                      return (
                        <div key={key} className={cx('option')}>
                          <Checkbox checked={isChecked} onClick={() => toggleCheck(key)} />
                          {value}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              {paramInfo.formType !== FORM_TYPE.READ_ONLY && (
                <>
                  <ProcessingButton text="Save" doProcess={doSave} className={cx('button')} />
                  <div className={cx('warn')}>
                    <span className={cx('accent')}>Warning</span>
                    <br />
                    Altering settings while the vehicle is in flight may result in instability or even loss of the
                    vehicle. Ensure you fully understand the changes you’re making and carefully verify your inputs
                    before saving!
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Parameters;
