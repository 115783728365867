import useMountEffect from '@restart/hooks/useMountEffect';
import React, { useState, useMemo } from 'react';
import { MdOutlineBolt, MdOutlineSatelliteAlt } from 'react-icons/md';

import { Top, TopStatusValue, TopNotificationCount } from '@/components/ui/Dashboard';
import { getPermissionLabel } from '@/components/ui/Dashboard/Top';
import EventEmitter from '@/libs/EventEmitter';

const Component = ({ robot }) => {
  const [fixType, setFixType] = useState();

  const labels = useMemo(() => {
    const draftLabels = [robot.model.agentType, getPermissionLabel(robot.isOwned)];
    if ([5, 6].includes(fixType)) {
      draftLabels.push('RTK');
    }

    return draftLabels;
  }, [robot, fixType]);

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robot.id}/telemetry/gpsRaw`, (data) => {
      setFixType(data.fixType);
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return (
    <Top labels={labels}>
      <TopStatusValue
        Icon={MdOutlineBolt}
        event={`${robot.id}/telemetry/batteryStatus`}
        callback={({ voltage }) => (voltage / 1000).toFixed(1)}
        unit="V"
      />
      <TopStatusValue
        Icon={MdOutlineSatelliteAlt}
        event={`${robot.id}/telemetry/gpsRaw`}
        callback={({ satellitesVisible }) => satellitesVisible}
        style={{ gap: 4 }}
      />
      <TopNotificationCount robotId={robot.id} />
    </Top>
  );
};

export default Component;
