import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import React, { useEffect, useRef } from 'react';

import styles from './ProgressBar.module.scss';

const cx = classNames.bind(styles);

const ProgressBar = ({ current, total }) => {
  const progressBarRef = useRef();
  const progressTextRef = useRef();

  useEffect(() => {
    progressBarRef.current.style.width = `${(current / total) * 100}%`;
    progressTextRef.current.textContent = `${commaNumber(current)} / ${commaNumber(total)}`;
  }, [current, total]);

  return (
    <div className={cx('container')}>
      <div className={cx('gauge')}>
        <div ref={progressBarRef} className={cx('bar')} />
        <div ref={progressTextRef} className={cx('text')} />
      </div>
      <div className={cx('message')}>Loading...</div>
    </div>
  );
};

export default ProgressBar;
