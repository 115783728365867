import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { useDispatch, useSelector, useStore } from 'react-redux';

import styles from './Robot.module.scss';

import actions from '@/actions';
import RobotMarker from '@/components/ui/map/RobotMarker';
import OlMap from '@/helpers/OlMap';

const cx = classNames.bind(styles);

const MarkerContent = ({ data: robot }) => {
  const showLabel = useSelector((state) => state.robotOptions[robot.id]?.label);

  return (
    <div className={cx('container')}>
      <RobotMarker data={robot} hideDirection />
      {showLabel && <div className={cx('panel')}>{robot.name}</div>}
    </div>
  );
};

const Robot = ({ data: robot }) => {
  const dispatch = useDispatch();
  const store = useStore();

  useEffect(() => {
    const element = getMarkerElement();

    element.addEventListener('click', () => {
      if (!robot.isOwned) return;

      dispatch(actions.robot.toggle(robot.id));
    });
    element.addEventListener('mouseenter', () => {
      dispatch(actions.callout.setText(robot.name));
    });
    element.addEventListener('mouseleave', () => {
      dispatch(actions.callout.setText(null));
    });

    OlMap.addRobot(robot.id, element, {
      lat: robot.metadata['WEFLO_POSITION_LATITUDE'],
      lng: robot.metadata['WEFLO_POSITION_LONGITUDE'],
    });

    return () => {
      OlMap.removeRobot(robot.id);
    };
  }, [robot]);

  const getMarkerElement = () => {
    const content = document.createElement('div');

    createRoot(content).render(
      <Provider store={store}>
        <MarkerContent data={robot} />
      </Provider>
    );
    return content;
  };
};

export default Robot;
