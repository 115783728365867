import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useContext, useState, useRef } from 'react';
import { Joystick } from 'react-joystick-component';

import styles from './common.module.scss';

import BinarySwitch from '@/components/ui/BinarySwitch';
import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const { publishCommand } = useContext(MessageContext);
  const [isVirtualJoystick, setIsVirtualJoystick] = useState(true);
  const [joystickLeftPosition, setJoystickLeftPosition] = useState({ x: 0, y: 0 });
  const [joystickRightPosition, setJoystickRightPosition] = useState({ x: 0, y: 0 });
  const manualControl = useRef({ x: 0, y: 0, z: 500, r: 0 });

  useMountEffect(() => {
    // QuadPlane Loiter 모드 전환
    publishCommand(robots[0], 'action/qloiter', [[]]);
    EventEmitter.publish(`${robots[0].id}/event/removeGoto`);

    const interval = setInterval(() => {
      const { x, y, z, r } = manualControl.current;
      publishCommand(robots[0], 'action/qmanual', [[x, y, z, r]]);
    }, 50);

    return () => {
      clearInterval(interval);
    };
  });

  useUpdateEffect(() => {
    if (isVirtualJoystick) return;

    let frameId;
    const handleJoystick = () => {
      const joystick = navigator.getGamepads()[0];
      if (joystick) {
        moveJoystickLeft({
          x: joystick.axes[0],
          y: joystick.axes[1],
        });
        moveJoystickRight({
          x: joystick.axes[2],
          y: joystick.axes[3],
        });
      }

      frameId = window.requestAnimationFrame(handleJoystick);
    };

    handleJoystick();

    return () => {
      window.cancelAnimationFrame(frameId);
      stopJoystickLeft();
      stopJoystickRight();
    };
  }, [isVirtualJoystick]);

  const selectSwitch = (e) => {
    setIsVirtualJoystick(e.currentTarget.dataset.value === 'true');
  };

  const moveJoystickLeft = ({ x, y }) => {
    manualControl.current.r = parseInt(x * 1000);
    manualControl.current.z = parseInt(((y + 1) / 2) * 1000); // z 범위: 0 ~ 1000, 중립: 500

    setJoystickLeftPosition({ x, y });
  };

  const stopJoystickLeft = () => {
    manualControl.current.r = 0;
    manualControl.current.z = 500;

    setJoystickLeftPosition({ x: 0, y: 0 });
  };

  const moveJoystickRight = ({ x, y }) => {
    manualControl.current.x = -parseInt(y * 1000);
    manualControl.current.y = parseInt(x * 1000);

    setJoystickRightPosition({ x, y });
  };

  const stopJoystickRight = () => {
    manualControl.current.x = 0;
    manualControl.current.y = 0;

    setJoystickRightPosition({ x: 0, y: 0 });
  };

  return (
    <div className={cx('container')}>
      <BinarySwitch
        items={[
          { title: 'Virtual', value: true, onClick: selectSwitch },
          { title: 'Real', value: false, onClick: selectSwitch },
        ]}
      />
      <div className={cx('joysticks')}>
        <div className={cx(['joystick', { inactive: !isVirtualJoystick }])}>
          <Joystick
            size={120}
            stickSize={64}
            sticky={false}
            stickImage={require('../../assets/joystick-blue.png')}
            baseImage={require('../../assets/joystick-base.png')}
            move={moveJoystickLeft}
            stop={stopJoystickLeft}
            pos={joystickLeftPosition}
            disabled={!isVirtualJoystick}
          />
          <div className={cx('info')}>
            <div className={cx('item')}>{joystickLeftPosition.x.toFixed(2)}</div>
            <div className={cx('item')}>{joystickLeftPosition.y.toFixed(2)}</div>
          </div>
        </div>
        <div className={cx(['joystick', { inactive: !isVirtualJoystick }])}>
          <Joystick
            size={120}
            stickSize={64}
            sticky={false}
            stickImage={require('../../assets/joystick-blue.png')}
            baseImage={require('../../assets/joystick-base.png')}
            move={moveJoystickRight}
            stop={stopJoystickRight}
            pos={joystickRightPosition}
            disabled={!isVirtualJoystick}
          />
          <div className={cx('info')}>
            <div className={cx('item')}>{joystickRightPosition.x.toFixed(2)}</div>
            <div className={cx('item')}>{joystickRightPosition.y.toFixed(2)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Action;
