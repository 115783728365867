//! Be sure to review and update the 'PX4.js' file
//! whenever you make changes to this file.

const toMavLink = (missionItems) => {
  const messages = [];
  missionItems.forEach((missionItem) => {
    // Wait For Distance
    if (missionItem.type === 'condDistance') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        114, // MAV_CMD_CONDITION_DISTANCE
        0, // current
        missionItem.data.distance,
        0, // param2
        0, // param3
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Wait For Yaw
    if (missionItem.type === 'condYaw') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        115, // MAV_CMD_CONDITION_YAW
        0, // current
        missionItem.data.heading,
        missionItem.data.rate,
        missionItem.data.direction,
        missionItem.data.offset,
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Autotune Enable
    if (missionItem.type === 'doAutotuneEnable') {
      messages.push([
        messages.length,
        0, // MAV_FRAME_GLOBAL
        212, // MAV_CMD_DO_AUTOTUNE_ENABLE
        0, // current
        missionItem.data.enable,
        0, // param2
        0, // param3
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Change Speed
    if (missionItem.type === 'doChangeSpeed') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        178, // MAV_CMD_DO_CHANGE_SPEED
        0, // current
        missionItem.data.type ?? 1, // 1: SPEED_TYPE_GROUNDSPEED
        missionItem.data.speed,
        missionItem.data.throttle ?? -1, // -1: no change
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Gripper
    else if (missionItem.type === 'doGripper') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        211, // MAV_CMD_DO_GRIPPER
        0, // current
        missionItem.data.gripper,
        missionItem.data.action,
        0, // param3
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Guided Limits
    else if (missionItem.type === 'doGuidedLimits') {
      messages.push([
        messages.length,
        0, // MAV_FRAME_GLOBAL
        222, // MAV_CMD_DO_GUIDED_LIMITS
        0, // current
        missionItem.data.timeout,
        missionItem.data.minAlt,
        missionItem.data.maxAlt,
        missionItem.data.horizMoveLimit,
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Set Camera Trigger Distance
    else if (missionItem.type === 'doSetCamTriggDist') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        206, // MAV_CMD_DO_SET_CAM_TRIGG_DIST
        0, // current
        missionItem.data.distance, // distance
        0, // camera shutter integration time: ignore
        1, // trigger camera once immediately
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Set Relay
    else if (missionItem.type === 'doSetRelay') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        181, // MAV_CMD_DO_SET_RELAY
        0, // current
        missionItem.data.relay,
        missionItem.data.setting,
        0, // param3
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Cycle Relay
    else if (missionItem.type === 'doRepeatRelay') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        182, // MAV_CMD_DO_REPEAT_RELAY
        0, // current
        missionItem.data.relay,
        missionItem.data.count,
        missionItem.data.time,
        0, // param4,
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Set Region Of Interest
    else if (missionItem.type === 'doSetRoiLocation') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        201, // MAV_CMD_DO_SET_ROI
        0, // current
        3, // MAV_ROI_LOCATION
        0, // param2
        0, // param3
        0, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.altitude,
      ]);
    }
    // Set Region Of Interest None
    else if (missionItem.type === 'doSetRoiNone') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        201, // MAV_CMD_DO_SET_ROI
        0, // current
        0, // MAV_ROI_NONE
        0, // param2
        0, // param3
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Set Servo
    else if (missionItem.type === 'doSetServo') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        183, // MAV_CMD_DO_SET_SERVO
        0, // current
        missionItem.data.servo,
        missionItem.data.pwm,
        0, // param3
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Cycle Servo
    else if (missionItem.type === 'doRepeatServo') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        184, // MAV_CMD_DO_REPEAT_SERVO
        0, // current
        missionItem.data.servo,
        missionItem.data.pwm,
        missionItem.data.count,
        missionItem.data.time,
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Jump To Item
    else if (missionItem.type === 'doJump') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        177, // MAV_CMD_DO_JUMP
        0, // current
        missionItem.data.item,
        missionItem.data.repeat,
        0, // param3
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Trigger Parachute
    else if (missionItem.type === 'doParachute') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        208, // MAV_CMD_DO_PARACHUTE
        0, // current
        missionItem.data.action,
        0, // param2
        0, // param3
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Change Altitude
    else if (missionItem.type === 'navContinueAndChangeAlt') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        30, // MAV_CMD_NAV_CONTINUE_AND_CHANGE_ALT
        0, // current
        missionItem.data.mode,
        0, // param2
        0, // param3
        0, // param4
        0, // x
        0, // y
        missionItem.data.altitude,
      ]);
    }
    // Delay Until
    else if (missionItem.type === 'navDelay') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        93, // MAV_CMD_NAV_DELAY
        0, // current
        missionItem.data.hold,
        missionItem.data.hour,
        missionItem.data.minute,
        missionItem.data.second,
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Guided Enable
    else if (missionItem.type === 'navGuidedEnable') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        92, // MAV_CMD_NAV_GUIDED_ENABLE
        0, // current
        missionItem.data.enable,
        0, // param2
        0, // param3
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Land
    else if (missionItem.type === 'navLand') {
      // 낙하산 사용 시
      if (missionItem.data.parachute) {
        messages.push([
          messages.length,
          3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
          16, // MAV_CMD_NAV_WAYPOINT
          0, // current
          0, // param1
          0, // param2
          0, // param3
          NaN, // param4
          missionItem.data.position.lat * 10000000,
          missionItem.data.position.lng * 10000000,
          missionItem.data.altitude,
        ]);
        messages.push([
          messages.length,
          2, // MAV_FRAME_MISSION
          208, // MAV_CMD_DO_PARACHUTE
          0, // current
          1, // parachute action: PARACHUTE_ENABLE(1)
          0, // param2
          0, // param3
          0, // param4
          0, // param5
          0, // param6
          0, // param7
        ]);
        messages.push([
          messages.length,
          2, // MAV_FRAME_MISSION
          208, // MAV_CMD_DO_PARACHUTE
          0, // current
          2, // parachute action: PARACHUTE_RELEASE(2)
          0, // param2
          0, // param3
          0, // param4
          0, // param5
          0, // param6
          0, // param7
        ]);
      }

      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        21, // MAV_CMD_NAV_LAND
        0, // current
        0, // param1
        0, // param2
        0, // param3
        NaN, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        0,
      ]);
    }
    // Loiter (Time)
    else if (missionItem.type === 'navLoiterTime') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        19, // MAV_CMD_NAV_LOITER_TIME
        0, // current
        missionItem.data.loiterTime,
        1, // param2
        50, // param3
        1, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.altitude,
      ]);
    }
    // Loiter (Altitude)
    else if (missionItem.type === 'navLoiterToAlt') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        31, // MAV_CMD_NAV_LOITER_TO_ALT
        0, // current
        1, // param1
        50, // param2
        0, // param3
        1, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.altitude,
      ]);
    }
    // Loiter (Turns)
    else if (missionItem.type === 'navLoiterTurns') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        18, // MAV_CMD_NAV_LOITER_TURNS
        0, // current
        missionItem.data.turns,
        1, // param2
        50, // param3
        1, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.altitude,
      ]);
    }
    // Return To Launch
    else if (missionItem.type === 'navReturnToLaunch') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        20, // MAV_CMD_NAV_RETURN_TO_LAUNCH
        0, // current
        0, // param1
        0, // param2
        0, // param3
        0, // param4
        0,
        0,
        0,
      ]);
    }
    // Takeoff
    else if (missionItem.type === 'navTakeoff') {
      messages.push([
        messages.length,
        0, // MAV_FRAME_GLOBAL
        16, // MAV_CMD_NAV_WAYPOINT
        1, // current
        0, // param1
        0, // param2
        0, // param3
        0, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.elevation,
      ]);
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        22, // MAV_CMD_NAV_TAKEOFF
        0, // current
        0, // param1
        0, // param2
        0, // param3
        NaN, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.altitude,
      ]);
    }
    // Waypoint
    else if (missionItem.type === 'navWaypoint') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        16, // MAV_CMD_NAV_WAYPOINT
        0, // current
        0, // param1
        0, // param2
        0, // param3
        NaN, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.altitude,
      ]);

      // 속도 변경하는 경우
      if (missionItem.data.speed) {
        messages.push([
          messages.length,
          2, // MAV_FRAME_MISSION
          178, // MAV_CMD_DO_CHANGE_SPEED
          0, // current
          1, // speed type: SPEED_TYPE_GROUNDSPEED
          missionItem.data.speed, // speed
          -1, // throttle: no change
          0, // param4
          0, // x
          0, // y
          0, // z
        ]);
      }
    }
  });
  return messages;
};

const fromMavLink = (messages) => {
  const missionItems = [];
  messages.forEach((message, index) => {
    // 16: MAV_CMD_NAV_WAYPOINT
    if (message.command === 16) {
      missionItems.push({
        id: index,
        type: 'navWaypoint',
        data: {
          name: '',
          position: {
            lat: message.x / 10000000,
            lng: message.y / 10000000,
          },
          altitude: message.z,
        },
      });
    }
    // 18: MAV_CMD_NAV_LOITER_TURNS
    else if (message.command === 18) {
      missionItems.push({
        id: index,
        type: 'navLoiterTurns',
        data: {
          turns: message.param1,
          position: {
            lat: message.x / 10000000,
            lng: message.y / 10000000,
          },
          altitude: message.z,
        },
      });
    }
    // 19: MAV_CMD_NAV_LOITER_TIME
    else if (message.command === 19) {
      missionItems.push({
        id: index,
        type: 'navLoiterTime',
        data: {
          loiterTime: message.param1,
          position: {
            lat: message.x / 10000000,
            lng: message.y / 10000000,
          },
          altitude: message.z,
        },
      });
    }
    // 20: MAV_CMD_NAV_RETURN_TO_LAUNCH
    else if (message.command === 20) {
      missionItems.push({
        id: index,
        type: 'navReturnToLaunch',
      });
    }
    // 21: MAV_CMD_NAV_LAND
    else if (message.command === 21) {
      missionItems.push({
        id: index,
        type: 'navLand',
        data: {
          position: {
            lat: message.x / 10000000,
            lng: message.y / 10000000,
          },
        },
      });
    }
    // 22: MAV_CMD_NAV_TAKEOFF
    else if (message.command === 22) {
      missionItems[missionItems.length - 1].type = 'navTakeoff';
      missionItems[missionItems.length - 1].data.altitude = message.z;
    }
    // 30: MAV_CMD_NAV_CONTINUE_AND_CHANGE_ALT
    else if (message.command === 30) {
      missionItems.push({
        id: index,
        type: 'navContinueAndChangeAlt',
        data: {
          mode: message.param1,
          altitude: message.z,
        },
      });
    }
    // 31: MAV_CMD_NAV_LOITER_TO_ALT
    else if (message.command === 31) {
      missionItems.push({
        id: index,
        type: 'navLoiterToAlt',
        data: {
          position: {
            lat: message.x / 10000000,
            lng: message.y / 10000000,
          },
          altitude: message.z,
        },
      });
    }
    // 92: MAV_CMD_NAV_GUIDED_ENABLE
    else if (message.command === 92) {
      missionItems.push({
        id: index,
        type: 'navGuidedEnable',
        data: {
          enable: message.param1,
        },
      });
    }
    // 93: MAV_CMD_NAV_DELAY
    else if (message.command === 93) {
      missionItems.push({
        id: index,
        type: 'navDelay',
        data: {
          hold: message.param1,
          hour: message.param2,
          minute: message.param3,
          second: message.param4,
        },
      });
    }
    // 114: MAV_CMD_CONDITION_DISTANCE
    else if (message.command === 114) {
      missionItems.push({
        id: index,
        type: 'condDistance',
        data: {
          distance: message.param1,
        },
      });
    }
    // 115: MAV_CMD_CONDITION_YAW
    else if (message.command === 115) {
      missionItems.push({
        id: index,
        type: 'condYaw',
        data: {
          heading: message.param1,
          rate: message.param2,
          direction: message.param3,
          offset: message.param4,
        },
      });
    }
    // 177: MAV_CMD_DO_JUMP
    else if (message.command === 177) {
      missionItems.push({
        id: index,
        type: 'doJump',
        data: {
          item: message.param1,
          repeat: message.param2,
        },
      });
    }
    // 178: MAV_CMD_DO_CHANGE_SPEED
    else if (message.command === 178) {
      missionItems.push({
        id: index,
        type: 'doChangeSpeed',
        data: {
          type: message.param1,
          speed: message.param2,
          throttle: message.param3,
        },
      });
    }
    // 181: MAV_CMD_DO_SET_RELAY
    else if (message.command === 181) {
      missionItems.push({
        id: index,
        type: 'doSetRelay',
        data: {
          relay: message.param1,
          setting: message.param2,
        },
      });
    }
    // 182: MAV_CMD_DO_REPEAT_RELAY
    else if (message.command === 182) {
      missionItems.push({
        id: index,
        type: 'doRepeatRelay',
        data: {
          relay: message.param1,
          count: message.param2,
          time: message.param3,
        },
      });
    }
    // 183: MAV_CMD_DO_SET_SERVO
    else if (message.command === 183) {
      missionItems.push({
        id: index,
        type: 'doSetServo',
        data: {
          servo: message.param1,
          pwm: message.param2,
        },
      });
    }
    // 184: MAV_CMD_DO_REPEAT_SERVO
    else if (message.command === 184) {
      missionItems.push({
        id: index,
        type: 'doRepeatServo',
        data: {
          servo: message.param1,
          pwm: message.param2,
          count: message.param3,
          time: message.param4,
        },
      });
    }
    // 206: MAV_CMD_DO_SET_CAM_TRIGG_DIST
    else if (message.command === 206) {
      missionItems.push({
        id: index,
        type: 'doSetCamTriggDist',
        data: { distance: message.param1 },
      });
    }
    // 201: MAV_CMD_DO_SET_ROI
    else if (message.command === 201) {
      // MAV_ROI_NONE
      if (message.param1 === 0) {
        missionItems.push({
          id: index,
          type: 'doSetRoiNone',
        });
      }
      // MAV_ROI_LOCATION
      else if (message.param1 === 3) {
        missionItems.push({
          id: index,
          type: 'doSetRoiLocation',
          data: {
            position: {
              lat: message.x / 10000000,
              lng: message.y / 10000000,
            },
            altitude: message.z,
          },
        });
      }
    }
    // 208: MAV_CMD_DO_PARACHUTE
    else if (message.command === 208) {
      missionItems.push({
        id: index,
        type: 'doParachute',
        data: { action: message.param1 },
      });
    }
    // 211: MAV_CMD_DO_GRIPPER
    else if (message.command === 211) {
      missionItems.push({
        id: index,
        type: 'doGripper',
        data: {
          gripper: message.param1,
          action: message.param2,
        },
      });
    }
    // 212: MAV_CMD_DO_AUTOTUNE_ENABLE
    else if (message.command === 212) {
      missionItems.push({
        id: index,
        type: 'doAutotuneEnable',
        data: {
          enable: message.param1,
        },
      });
    }
    // 222: MAV_CMD_DO_GUIDED_LIMITS
    else if (message.command === 222) {
      missionItems.push({
        id: index,
        type: 'doGuidedLimits',
        data: {
          timeout: message.param1,
          minAlt: message.param2,
          maxAlt: message.param3,
          horizMoveLimit: message.param4,
        },
      });
    }
  });

  return missionItems;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { toMavLink, fromMavLink };
