import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import ChartJs from 'chart.js/auto';
import React, { useRef } from 'react';

// X축 구간 수
const TICK_COUNT = 30;

const FootForceChart = ({ data: footForce }) => {
  const lastFootForce = useRef();
  const chartRef = useRef();
  const chart = useRef();

  useUpdateEffect(() => {
    if (!footForce) return;

    lastFootForce.current = footForce;
    const intervalId = setInterval(drawChart, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [footForce]);

  const drawChart = () => {
    // 차트 존재 시
    if (chart.current) {
      updateChart();
    }
    // 차트 미존재 시
    else {
      const initialData = Array(TICK_COUNT).fill(null);

      const context = chartRef.current.getContext('2d');
      const options = {
        type: 'line',
        data: {
          labels: Array(TICK_COUNT).fill(''),
          datasets: [
            {
              label: 'FL',
              data: initialData.slice(),
              borderColor: 'rgba(255, 0, 0, 0.7)',
              borderWidth: 1,
              pointRadius: 1,
              pointBackgroundColor: 'rgba(255, 0, 0, 0.7)',
              tension: 0.5,
            },
            {
              label: 'FR',
              data: initialData.slice(),
              borderColor: 'rgba(0, 255, 0, 0.7)',
              borderWidth: 1,
              pointRadius: 1,
              pointBackgroundColor: 'rgba(0, 255, 0, 0.7)',
              tension: 0.5,
            },
            {
              label: 'RL',
              data: initialData.slice(),
              borderColor: 'rgba(0, 255, 255, 0.7)',
              borderWidth: 1,
              pointRadius: 1,
              pointBackgroundColor: 'rgba(0, 255, 255, 0.7)',
              tension: 0.5,
            },
            {
              label: 'RR',
              data: initialData.slice(),
              borderColor: 'rgba(255, 255, 0, 0.7)',
              borderWidth: 1,
              pointRadius: 1,
              pointBackgroundColor: 'rgba(255, 255, 0, 0.7)',
              tension: 0.5,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            x: {
              ticks: { display: false },
              grid: { display: false },
            },
            y: {
              ticks: {
                color: 'gray',
                font: { size: 8 },
              },
              grid: {
                color: '#77777788',
                drawTicks: false,
                lineWidth: 0.5,
              },
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                boxWidth: 4,
                boxHeight: 4,
                color: 'white',
                font: { size: 10 },
                padding: 8,
                usePointStyle: true,
              },
            },
          },
          animation: {
            easing: 'linear',
          },
        },
      };

      chart.current = new ChartJs(context, options);
    }
  };

  const updateChart = () => {
    const { labels, datasets } = chart.current.data;

    // X축 구간 수 동일한 경우, 첫 번째 데이터 제거
    if (TICK_COUNT === labels.length) {
      labels.shift();
      datasets.forEach((dataset) => dataset.data.shift());
    }

    labels.push('');
    datasets.forEach((dataset, index) => dataset.data.push(lastFootForce.current[index]));

    // 차트 반영
    chart.current.update();
  };

  return <canvas ref={chartRef} />;
};

export default FootForceChart;
