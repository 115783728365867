import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

import BottomBar from './BottomBar';
import Command from './Command';
import Dashboards from './Dashboards';
import styles from './index.module.scss';
import Map from './Map';
import Mission from './Mission';
import Notifications from './Notifications';
import Videos from './Videos';
import TutorialModal from '../../components/modals/Tutorial';
import { ModalService as modal } from '../../libs/Modal';

import actions from '@/actions';
import Callout from '@/components/ui/Callout';
import TopBar from '@/components/ui/TopBar';
import API from '@/helpers/API';
import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';

const cx = classNames.bind(styles);

const Page = () => {
  const dispatch = useDispatch();
  const { subscribeTelemetry } = useContext(MessageContext);

  useMountEffect(() => {
    const isTutorialViewed = localStorage.getItem('@preventTutorial');
    if (!isTutorialViewed) {
      modal.show(TutorialModal);
    }

    // API: 계정 조회
    API.get('/accounts').then(({ success, data }) => {
      if (success) {
        dispatch(actions.user.load(data));
      }
    });

    // API: 로봇내역 조회
    API.get('/robots').then(({ success, data }) => {
      if (success) {
        // 1. 로봇 정의
        dispatch(actions.robots.load(data));

        // 2. 모델 명령세트 정의
        const models = data.map(({ model }) => model);
        const modelIds = models.map(({ id }) => id);
        const uniqueModelIds = [...new Set(modelIds)];

        const funcs = uniqueModelIds.map(async (modelId) => {
          const result = await API.get(`/models/${modelId}/commands`);
          if (!result.success || !result.data) return;

          // 모델 에이전트 유형
          const { agentType } = models.find(({ id }) => id === modelId);
          // 모델 명령세트
          const commandSet = getCommandSet(result.data, agentType);
          dispatch(actions.commandSet.load(modelId, commandSet));
        });

        // 3. 텔레메트리 구독
        Promise.all(funcs).then(() => subscribeTelemetry(data));
      }
    });
  });

  const getCommandSet = (commands, agentType) => {
    const commandSet = {};

    commands.forEach((command) => {
      switch (agentType) {
        case 'MavLink':
          commandSet[command.name] = {
            sysId: command.sysId,
            compId: command.compId,
            messages: command.messages,
          };
          break;

        case 'ROS':
          commandSet[command.name] = {
            type: command.type,
            topic: command.topic,
            messageTemplate: command.messageTemplate,
          };
          break;

        default:
          break;
      }
    });

    return commandSet;
  };

  return (
    <>
      <Helmet>
        <title>M1UCS | Control Center</title>
      </Helmet>
      <div className={cx('container')}>
        <TopBar />
        <div className={cx('content')}>
          <Map />
          <div className={cx('leftTop')}>
            <Dashboards />
            <Videos />
          </div>
          <div className={cx('centerTop')}>
            <Notifications />
          </div>
          <div className={cx('rightTop')}>
            <Mission />
          </div>
          <div className={cx('centerBottom')}>
            <Command />
          </div>
        </div>
        <BottomBar />
        <Callout />
      </div>
    </>
  );
};

export default Page;
