import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useSelector, useDispatch } from 'react-redux';

import actions from '@/actions';
import { getMissionItemForSurveySegment } from '@/helpers/MissionConverter';

const useEditorPath = () => {
  const dispatch = useDispatch();
  const missionItems = useSelector((state) => state.mission.json.missionItems);

  useUpdateEffect(() => {
    const path = getPath(missionItems);

    dispatch(actions.editor.loadPath(path));
  }, [missionItems]);
};

export default useEditorPath;

export const getPath = (missionItems) => {
  const path = [];
  missionItems.forEach((missionItem) => {
    switch (missionItem.type) {
      case 'navTakeoff':
        path.push(
          {
            id: `${missionItem.id}/takeoff/start`,
            position: missionItem.data.position,
            mslAltitude: 0, // 해수면 고도로 임의 지정하여 3D Map 경로 표시 시 지면에 닿아 보이도록 처리
          },
          {
            id: `${missionItem.id}/takeoff/end`,
            position: missionItem.data.position,
            mslAltitude: missionItems[0].data.elevation + missionItem.data.altitude,
          }
        );
        break;

      case 'navLand':
        path.push(
          {
            id: `${missionItem.id}/land/start`,
            position: missionItem.data.position,
            mslAltitude: path.at(-1).mslAltitude,
          },
          {
            id: `${missionItem.id}/land/end`,
            position: missionItem.data.position,
            mslAltitude: 0, // 해수면 고도로 임의 지정하여 3D Map 경로 표시 시 지면에 닿아 보이도록 처리
          }
        );
        break;

      case 'navLoiterTime':
      case 'navLoiterToAlt':
      case 'navLoiterTurns':
      case 'navWaypoint':
        path.push({
          id: missionItem.id,
          position: missionItem.data.position,
          mslAltitude: missionItems[0].data.elevation + missionItem.data.altitude,
        });
        break;

      case 'navReturnToLaunch':
        path.push(
          {
            id: `${missionItem.id}/rtl/start`,
            position: missionItems[0].data.position,
            mslAltitude: path.at(-1).mslAltitude,
          },
          {
            id: `${missionItem.id}/rtl/end`,
            position: missionItems[0].data.position,
            mslAltitude: 0, // 해수면 고도로 임의 지정하여 3D Map 경로 표시 시 지면에 닿아 보이도록 처리
          }
        );
        break;

      case 'cusSurvey':
        {
          const surveyMissionItem = getMissionItemForSurveySegment(missionItem);
          surveyMissionItem.data.positions.forEach((position, index) => {
            let id = missionItem.id;
            // 서베이 시작점
            if (index === 0) {
              id = `${id}/survey/start`;
            }
            // 서베이 종료점
            else if (index === surveyMissionItem.data.positions.length - 1) {
              id = `${id}/survey/end`;
            }
            // 서베이 중간점
            else {
              id = `${id}/survey/${index}`;
            }

            path.push({
              id,
              position,
              mslAltitude: missionItems[0].data.elevation + missionItem.data.altitude,
            });
          });
        }
        break;

      default:
        break;
    }
  });
  return path;
};
