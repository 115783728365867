import { combineReducers } from 'redux';

import callout from './common/callout';
import map from './common/map';
import map3d from './common/map3d';
import robots from './common/robots';
import telemetry from './common/telemetry';
import user from './common/user';
import command from './control-center/command';
import commandSet from './control-center/commandSet';
import event from './control-center/event';
import missionsForControlCenter from './control-center/missions';
import notification from './control-center/notification';
import robot from './control-center/robot';
import robotOptions from './control-center/robotOptions';
import editor from './mission-hub/editor';
import mission from './mission-hub/mission';
import missionsForMissionHub from './mission-hub/missions';
import videos from './video-manager/videos';

export const getReducerForPage = (pageType) => {
  const reducers = {};

  switch (pageType) {
    case 'control-center':
      reducers.callout = callout;
      reducers.command = command;
      reducers.commandSet = commandSet;
      reducers.event = event;
      reducers.map = map;
      reducers.map3d = map3d;
      reducers.missions = missionsForControlCenter;
      reducers.notification = notification;
      reducers.robot = robot;
      reducers.robotOptions = robotOptions;
      reducers.robots = robots;
      reducers.telemetry = telemetry;
      reducers.user = user;
      break;

    case 'mission-hub':
      reducers.callout = callout;
      reducers.editor = editor;
      reducers.map = map;
      reducers.map3d = map3d;
      reducers.mission = mission;
      reducers.missions = missionsForMissionHub;
      reducers.robots = robots;
      reducers.telemetry = telemetry;
      reducers.user = user;
      break;

    case 'video-manager':
      reducers.robot = robot;
      reducers.robots = robots;
      reducers.user = user;
      reducers.videos = videos;
      break;

    default:
      break;
  }

  // Reducer 없는 경우 (e.g. /login)
  if (Object.keys(reducers).length === 0) {
    return (state = {}) => state;
  }
  return combineReducers(reducers);
};
