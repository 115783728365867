import classNames from 'classnames/bind';
import { toLonLat } from 'ol/proj';
import React, { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';
import OlMap from '@/helpers/OlMap';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);
  const [speed, setSpeed] = useState(2);
  const map = OlMap.getMap();

  useEffect(() => {
    map.getTargetElement().style.cursor = 'crosshair';
    map.on('click', clickOnMap);

    return () => {
      map.getTargetElement().style.cursor = 'default';
      map.un('click', clickOnMap);
    };
  }, [robots, speed]);

  const clickOnMap = (e) => {
    const [lng, lat] = toLonLat(e.coordinate);

    robots.forEach((robot) => {
      publishCommand(robot, 'action/goto/px4/boat', [[speed, NaN, lat * 10000000, lng * 10000000]]);

      OlMap.addGotoMarker(robot.id, { lat, lng }, robot.color);
    });

    dispatch(actions.command.toggle());
  };

  const handleChange = (e) => {
    setSpeed(Number(e.target.value));
  };

  return (
    <div className={cx('container')}>
      <div className={cx('form')}>
        <label>
          Speed
          <div className={cx('fieldWrapper')}>
            <input name="speed" type="number" value={speed} onChange={handleChange} />
            <div className={cx('unit')}>m/s</div>
          </div>
        </label>
      </div>
      <div className={cx('message')}>
        Click on a location on the map
        <br />
        where you want to move the boat.
      </div>
    </div>
  );
};

export default Action;
