import React from 'react';

import Mode from './Mode';
import Monitor from './Monitor';
import useOrganizer from '../useOrganizer';

import { Frame } from '@/components/ui/Dashboard';

const Dashboard = ({ data: robot }) => {
  useOrganizer(robot);

  return (
    <Frame robot={robot} mode={<Mode robotId={robot.id} />}>
      <Monitor robotId={robot.id} />
    </Frame>
  );
};

export default Dashboard;
