import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useSelector } from 'react-redux';

import EventEmitter from '@/libs/EventEmitter';
import { isColdPosition } from '@/utils/MapUtils';

const useUpdatePosition = () => {
  const robots = useSelector((state) => state.robots);
  const telemetry = useSelector((state) => state.telemetry);

  useUpdateEffect(() => {
    robots
      .filter(({ isActive }) => isActive)
      .filter(({ id }) => telemetry[id])
      .forEach((robot) => {
        let { lat, lon: lng, hdg } = telemetry[robot.id].m33;
        lat /= 10000000;
        lng /= 10000000;
        hdg /= 100;

        // GPS 데이터 유효하지 않은 경우
        if (isColdPosition(lat, lng)) return;

        EventEmitter.publish(`${robot.id}/telemetry/globalPosition`, { lat, lng, hdg });
      });
  }, [robots, telemetry]);
};

export default useUpdatePosition;
