import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState, useContext, useMemo } from 'react';

import styles from './console.module.scss';
import ProcessingButton from '../../ProcessingButton';

import ProgressBar from '@/components/ui/ProgressBar';
import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';
import EventEmitter from '@/libs/EventEmitter';
import { waitUntilParamSet } from '@/utils/Sleep';

const cx = classNames.bind(styles);

const Console = ({ robot }) => {
  const { publishCommand } = useContext(MessageContext);
  const [params, setParams] = useState({
    FS_THR_ENABLE: null,
    FS_THR_VALUE: null,
  });

  // 수신된 파라미터 갯수
  const isLoadedCount = useMemo(() => {
    return Object.values(params).filter((value) => value !== null).length;
  }, [params]);

  // 파라미터 수신 중 여부
  const isLoading = useMemo(() => {
    return isLoadedCount < Object.keys(params).length;
  }, [isLoadedCount]);

  useMountEffect(() => {
    // TODO: FS_THR_ENABLE 파라미터 단일 조회 제거
    publishCommand(robot, 'param/read', [['FS_THR_ENABLE']]);

    Object.keys(params).forEach((key) => {
      publishCommand(robot, 'param/read', [[key]]);
    });

    const subscribeToken = EventEmitter.subscribe(`${robot.id}/telemetry/paramValue`, (data) => {
      if (!data[65535]) return;
      if (!Object.keys(params).includes(data[65535].paramId)) return;

      setParams((prev) => ({
        ...prev,
        [data[65535].paramId]: {
          value: data[65535].paramValue,
          type: data[65535].paramType,
        },
      }));
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  const handleChange = (e) => {
    const nextParams = { ...params };
    nextParams[e.target.name].value = e.target.value;

    setParams(nextParams);
  };

  const handleBlur = (e) => {
    const nextParams = { ...params };
    nextParams[e.target.name].value = String(Number(e.target.value));

    setParams(nextParams);
  };

  const doSave = async (prefix) => {
    const paramSet = Object.entries(params)
      .filter(([key]) => key.startsWith(prefix))
      .map(([id, { value, type }]) => ({ id, value, type }));

    for (const param of paramSet) {
      publishCommand(robot, 'param/set', [[param.id, Number(param.value), param.type]]);
      await waitUntilParamSet();
    }
    return true;
  };

  if (isLoading) {
    return (
      <div className={cx('loading')}>
        <ProgressBar current={isLoadedCount} total={Object.keys(params).length} />
      </div>
    );
  }

  return (
    <div className={cx('container')}>
      <div className={cx('rows')}>
        <div className={cx('row')}>
          <div className={cx('label')}>Throttle FailSafe</div>
          <div className={cx('radioGroup')}>
            <label>
              <input
                type="radio"
                name="FS_THR_ENABLE"
                value={0}
                defaultChecked={params['FS_THR_ENABLE'].value === 0}
                onChange={handleChange}
              />
              Disabled
            </label>
            <label>
              <input
                type="radio"
                name="FS_THR_ENABLE"
                value={3}
                defaultChecked={params['FS_THR_ENABLE'].value === 3}
                onChange={handleChange}
              />
              Land
            </label>
            <label>
              <input
                type="radio"
                name="FS_THR_ENABLE"
                value={1}
                defaultChecked={params['FS_THR_ENABLE'].value === 1}
                onChange={handleChange}
              />
              RTL
            </label>
          </div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>PWM Threshold</div>
          <div className={cx('fieldWrapper')}>
            <input
              type="number"
              name="FS_THR_VALUE"
              value={params['FS_THR_VALUE'].value}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  e.target.blur();
                }
              }}
            />
          </div>
        </div>
      </div>
      <ProcessingButton text="Save" doProcess={() => doSave('FS_THR')} />
    </div>
  );
};

export default Console;
