import classNames from 'classnames/bind';
import React, { useState } from 'react';

import styles from './index.module.scss';
import LoadForm from './LoadForm';
import NewForm from './NewForm';

import BinarySwitch from '@/components/ui/BinarySwitch';
import ModalWrapper from '@/components/ui/Modal';

const cx = classNames.bind(styles);

const Modal = () => {
  const [mode, setMode] = useState(0); // 0: New, 1: Load
  const [isShowForm, setIsShowForm] = useState(true);

  const selectSwitch = (e) => {
    const nextMode = Number(e.currentTarget.dataset.value);
    setMode(nextMode);

    setIsShowForm(false);
    setTimeout(() => setIsShowForm(true), 400);
  };

  return (
    <ModalWrapper disableEscape>
      <div className={cx(['wrapper', { new: mode === 0, load: mode === 1 }])}>
        <BinarySwitch
          items={[
            { title: 'New', value: 0, onClick: selectSwitch },
            { title: 'Load', value: 1, onClick: selectSwitch },
          ]}
        />
        {mode === 0 && (
          <div className={cx(['form', { show: isShowForm }])}>
            <NewForm />
          </div>
        )}
        {mode === 1 && (
          <div className={cx(['form', { show: isShowForm }])}>
            <LoadForm />
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};

export default Modal;
