import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import Checkbox from '@/components/ui/Checkbox';
import MissionItemRow from '@/components/ui/MissionItemRow';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const [hasThrottle, setHasThrottle] = useState(missionItem.data.throttle !== undefined);
  const domRefs = useRef({
    type: useRef(),
    speed: useRef(),
    throttle: useRef(),
  }).current;

  useEffect(() => {
    domRefs.type.current.value = missionItem.data.type;
    domRefs.speed.current.value = missionItem.data.speed;
    domRefs.throttle.current.value = missionItem.data.throttle ?? '';
  }, [missionItem.data]);

  useUpdateEffect(() => {
    const nextValue = hasThrottle ? 0 : undefined;
    dispatch(actions.mission.editChangeSpeed(index, 'throttle', nextValue));
  }, [hasThrottle]);

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = Number(e.target.value);
    dispatch(actions.mission.editChangeSpeed(index, name, value));
  };

  const toggleHasThrottle = () => {
    setHasThrottle(!hasThrottle);
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Type">
        <select ref={domRefs.type} name="type" onChange={handleBlur}>
          <option value="0">Airspeed</option>
          <option value="1">Ground Speed</option>
          <option value="2">Ascend Speed</option>
          <option value="3">Descend Speed</option>
        </select>
      </MissionItemRow>
      <MissionItemRow label="Speed" unit="m/s">
        <input
          ref={domRefs.speed}
          name="speed"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Throttle" unit="%">
        <Checkbox checked={hasThrottle} onClick={toggleHasThrottle} />
        <input
          ref={domRefs.throttle}
          name="throttle"
          type="number"
          disabled={!hasThrottle}
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
    </div>
  );
};

export default MissionItem;
