import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import styles from './Weather.module.scss';

import Bar from '@/components/ui/Bar';
import API from '@/helpers/API';
import { getDistance } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const THRESHOLD_DISTANCE = 1000; // 1km
const DIRECTIONS = [
  { name: 'N', value: 0 },
  { name: 'NNE', value: 22.5 },
  { name: 'NE', value: 45 },
  { name: 'ENE', value: 67.5 },
  { name: 'E', value: 90 },
  { name: 'ESE', value: 112.5 },
  { name: 'SE', value: 135 },
  { name: 'SSE', value: 157.5 },
  { name: 'S', value: 180 },
  { name: 'SSW', value: 202.5 },
  { name: 'SW', value: 225 },
  { name: 'WSW', value: 247.5 },
  { name: 'W', value: 270 },
  { name: 'WNW', value: 292.5 },
  { name: 'NW', value: 315 },
  { name: 'NNW', value: 337.5 },
];

const Weather = () => {
  const position = useSelector((state) => state.map.position);
  const [data, setData] = useState();
  const [isKelvinScale, setKelvinScale] = useState(false);
  const [isOpenWindDirection, setIsOpenWindDirection] = useState(false);
  const windDirectionTermRef = useRef();

  const needToFetch = useMemo(() => {
    return !data || getDistance(data.position, position) > THRESHOLD_DISTANCE;
  }, [position, data]);

  useEffect(() => {
    if (!needToFetch) return;

    API.get(`/proxy/weather?lat=${position.lat}&lon=${position.lng}`).then((result) => {
      if (result.success) {
        setData({ ...result.data, position });
      }
    });
  }, [needToFetch]);

  useUpdateEffect(() => {
    if (isOpenWindDirection) {
      document.addEventListener('mousedown', unfocusPanel);
    }

    return () => {
      document.removeEventListener('mousedown', unfocusPanel);
    };
  }, [isOpenWindDirection]);

  const unfocusPanel = (e) => {
    if (windDirectionTermRef.current.contains(e.target)) return;

    setIsOpenWindDirection(false);
  };

  const toggleTemperatureScale = () => {
    setKelvinScale(!isKelvinScale);
  };

  const toggleWindDirection = (e) => {
    // Panel 클릭된 경우 이벤트 방지
    const closest = e.target.closest('[data-panel]');
    if (!closest) {
      setIsOpenWindDirection(!isOpenWindDirection);
    }
  };

  return (
    <div className={cx('container')}>
      {/* 날씨 */}
      <div className={cx('weather')}>
        {data && (
          <img
            src={`https://openweathermap.org/img/w/${data.weather[0].icon}.png`}
            alt={data.weather[0].main}
            className={cx('icon')}
            title={data.weather[0].main}
          />
        )}
      </div>
      <Bar height={28} />
      {/* 온도 */}
      <div className={cx(['term', 'pointer'])} onClick={toggleTemperatureScale}>
        <div className={cx('label')}>Temperature</div>
        <div className={cx('value')}>
          {data ? `${(data.main.temp - (isKelvinScale ? 0 : 273.15)).toFixed(1)}${isKelvinScale ? 'K' : '°C'}` : '-'}
        </div>
      </div>
      <Bar height={28} />
      {/* 습도 */}
      <div className={cx('term')}>
        <div className={cx('label')}>Humidity</div>
        <div className={cx('value')}>{data ? `${data.main.humidity}%` : '-'}</div>
      </div>
      <Bar height={28} />
      {/* 풍속 */}
      <div className={cx('term')}>
        <div className={cx('label')}>Wind Speed</div>
        <div className={cx('value')}>{data ? `${data.wind.speed}m/s` : '-'}</div>
      </div>
      <Bar height={28} />
      {/* 풍향 */}
      <div ref={windDirectionTermRef} className={cx(['term', 'pointer'])} onClick={toggleWindDirection}>
        <div className={cx('label')}>Wind Direction</div>
        <div className={cx('value')}>{data ? `${data.wind.deg}°` : '-'}</div>
        <div data-panel className={cx(['panel', { show: isOpenWindDirection }])}>
          <div className={cx('title')}>Wind Direction</div>
          <div className={cx('wrapper')}>
            {DIRECTIONS.map((direction, index) => (
              <div key={index} className={cx('step')} style={{ transform: `rotate(${direction.value}deg)` }}>
                <div className={cx('name')}>{direction.name}</div>
                <div className={cx('value')}>{direction.value}°</div>
              </div>
            ))}
            <div className={cx('line')} style={{ transform: 'rotate(0deg)' }} />
            <div className={cx('line')} style={{ transform: 'rotate(22.5deg)' }} />
            <div className={cx('line')} style={{ transform: 'rotate(45deg)' }} />
            <div className={cx('line')} style={{ transform: 'rotate(67.5deg)' }} />
            <div className={cx('line')} style={{ transform: 'rotate(90deg)' }} />
            <div className={cx('line')} style={{ transform: 'rotate(112.5deg)' }} />
            <div className={cx('line')} style={{ transform: 'rotate(135deg)' }} />
            <div className={cx('line')} style={{ transform: 'rotate(157.5deg)' }} />
            {data && (
              <>
                <div className={cx('arrow')} style={{ transform: `rotate(${data.wind.deg}deg)` }}>
                  <div className={cx('head')} />
                </div>
                <div className={cx('label')}>{data.wind.deg}°</div>
              </>
            )}
          </div>
        </div>
      </div>
      <Bar height={28} />
      {/* 기압 */}
      <div className={cx('term')}>
        <div className={cx('label')}>Pressure</div>
        <div className={cx('value')}>{data ? `${commaNumber(data.main.pressure)}hPa` : '-'}</div>
      </div>
      <Bar height={28} />
    </div>
  );
};

export default Weather;
