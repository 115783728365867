import classNames from 'classnames/bind';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useStore } from 'react-redux';

import styles from './Video.module.scss';

const cx = classNames.bind(styles);

// TODO
const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const segments = [];
  if (hours > 0) {
    segments.push(String(hours).padStart(2, '0')); // 시
  }
  segments.push(String(minutes).padStart(2, '0')); // 분
  segments.push(String(secs).padStart(2, '0')); // 초

  return segments.join(':');
};

const Video = ({ data: video, selected, onSelect }) => {
  const store = useStore();

  const robot = useMemo(() => {
    return store.getState().robots.find(({ id }) => id === video.robotId);
  }, []);

  return (
    <div className={cx(['container', { selected }])} onClick={onSelect}>
      <div className={cx('thumb')}>
        <div className={cx('image')} style={{ backgroundImage: `url(${video.thumbnail})` }} />
        <div className={cx('duration')}>{formatTime(video.duration)}</div>
      </div>
      <div className={cx('info')}>
        <div className={cx('robot')}>
          <div className={cx('thumbnail')}>
            <img src={robot.thumbnailUrl ?? robot.model.thumbnailUrl} width="100%" alt={robot.model.name} />
          </div>
          <div className={cx('name')}>{robot.name}</div>
        </div>
        <div className={cx('title')}>{video.name}</div>
        <div className={cx('date')}>{moment(video.createdAt).format('YYYY-MM-DD HH:mm')}</div>
      </div>
    </div>
  );
};

export default Video;
