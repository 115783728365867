import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getCSSVariable } from '@/utils/theme';

const Path = () => {
  const path = useSelector((state) => state.editor.path);
  const pathRef = useRef();

  useEffect(() => {
    const positions = path.map(({ position, mslAltitude }) =>
      window.ws3d.common.Cartesian3.fromDegrees(position.lng, position.lat, mslAltitude)
    );

    // 기존 경로 존재하는 경우
    if (pathRef.current) {
      pathRef.current.polyline.positions = positions;
    }
    // 기존 경로 존재하지 않는 경우
    else {
      pathRef.current = window.ws3d.viewer.entities.add({
        polyline: {
          positions,
          width: 4,
          material: window.ws3d.common.Color.fromCssColorString(getCSSVariable('--tint-color')).withAlpha(0.8),
        },
      });
    }
  }, [path]);

  return null;
};

export default Path;
