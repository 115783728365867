import { useGLTF } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import * as THREE from 'three';

import styles from './GLTFViewer.module.scss';

import EventEmitter from '@/libs/EventEmitter';
import { getCSSVariable } from '@/utils/theme';

const cx = classNames.bind(styles);

const TYPE = {
  0: 'Idle',
  1: 'Trot',
  2: 'Run',
  3: 'Climb Stair',
  4: 'Forward Down Stair',
  9: 'Adjust',
};

const Dog = ({ robotId }) => {
  const { scene } = useGLTF(require('../assets/ros2-unitree-b2.gltf'));

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/lowstate`, ({ motor_state }) => {
      scene.getObjectByName('FL_hip').rotation.x = motor_state[0].q;
      scene.getObjectByName('FL_thigh').rotation.y = motor_state[1].q;
      scene.getObjectByName('FL_calf').rotation.y = motor_state[2].q;
      scene.getObjectByName('FR_hip').rotation.x = motor_state[3].q;
      scene.getObjectByName('FR_thigh').rotation.y = motor_state[4].q;
      scene.getObjectByName('FR_calf').rotation.y = motor_state[5].q;
      scene.getObjectByName('RL_hip').rotation.x = motor_state[6].q;
      scene.getObjectByName('RL_thigh').rotation.y = motor_state[7].q;
      scene.getObjectByName('RL_calf').rotation.y = motor_state[8].q;
      scene.getObjectByName('RR_hip').rotation.x = motor_state[9].q;
      scene.getObjectByName('RR_thigh').rotation.y = motor_state[10].q;
      scene.getObjectByName('RR_calf').rotation.y = motor_state[11].q;
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  useEffect(() => {
    scene.traverse((child) => {
      if (child.isMesh) {
        const materialName = child.material.name;

        // Material 명칭 기반 색상 정의
        if (materialName.endsWith('4C4C4C')) {
          child.material.color.set('#4C4C4C');
        } else if (materialName.endsWith('CCC')) {
          child.material.color.set('#CCCCCC');
        } else if (materialName.endsWith('4C514C')) {
          child.material.color.set('#4C514C');
        } else if (materialName.endsWith('100100100')) {
          child.material.color.set(getCSSVariable('--tint-color'));
        } else {
          child.material.color.set('white');
        }

        // Mesh 단위 연결
        setPartRelation(child.parent.name, child.name);
      }
    });

    // Front-Left
    setPartRelation('base_link', 'FL_hip');
    setPartRelation('FL_hip', 'FL_thigh');
    setPartRelation('FL_thigh', 'FL_thigh_protect');
    setPartRelation('FL_thigh', 'FL_calf');
    setPartRelation('FL_calf', 'FL_foot');

    // Front-Right
    setPartRelation('base_link', 'FR_hip');
    setPartRelation('FR_hip', 'FR_thigh');
    setPartRelation('FR_thigh', 'FR_thigh_protect');
    setPartRelation('FR_thigh', 'FR_calf');
    setPartRelation('FR_calf', 'FR_foot');

    // Rear-Left
    setPartRelation('base_link', 'RL_hip');
    setPartRelation('RL_hip', 'RL_thigh');
    setPartRelation('RL_thigh', 'RL_thigh_protect');
    setPartRelation('RL_thigh', 'RL_calf');
    setPartRelation('RL_calf', 'RL_foot');

    // Rear-Right
    setPartRelation('base_link', 'RR_hip');
    setPartRelation('RR_hip', 'RR_thigh');
    setPartRelation('RR_thigh', 'RR_thigh_protect');
    setPartRelation('RR_thigh', 'RR_calf');
    setPartRelation('RR_calf', 'RR_foot');

    // Etc.
    setPartRelation('base_link', 'f_dc_link');
    setPartRelation('base_link', 'f_oc_link');
    setPartRelation('base_link', 'r_dc_link');
    setPartRelation('base_link', 'r_oc_link');
    setPartRelation('base_link', 'lidar_link');
    setPartRelation('base_link', 'logo_left_link');
    setPartRelation('base_link', 'logo_right_link');

    scene.rotation.x = -Math.PI / 2;
    scene.position.y = 0.1;
  }, [scene]);

  const setPartRelation = (parentName, childName) => {
    const parent = scene.getObjectByName(parentName);
    const child = scene.getObjectByName(childName);

    const worldPosition = new THREE.Vector3();
    const worldQuaternion = new THREE.Quaternion();
    child.getWorldPosition(worldPosition);
    child.getWorldQuaternion(worldQuaternion);

    parent.add(child);

    child.position.copy(parent.worldToLocal(worldPosition));
    child.quaternion.copy(worldQuaternion);
  };

  return <primitive object={scene} />;
};

const GLTFViewer = ({ data: robotId }) => {
  const [type, setType] = useState();

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/sportmodestate`, ({ gait_type }) => {
      setType(TYPE[gait_type]);
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return (
    <div className={cx('container')}>
      <div className={cx('type')}>{type}</div>
      <Canvas camera={{ position: [2, 2, 4], fov: 15 }}>
        <ambientLight intensity={2} />
        <directionalLight position={[50, 50, 0]} intensity={2} />
        <pointLight position={[0, 0.4, -0.1]} />
        <Dog robotId={robotId} />
      </Canvas>
    </div>
  );
};

export default GLTFViewer;
