import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useContext, useState } from 'react';
import { Joystick } from 'react-joystick-component';

import styles from './common.module.scss';

import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';

const cx = classNames.bind(styles);

const baseImage = require('../../assets/joystick-base.png');
const stickImage = require('../../assets/joystick-blue.png');

const Action = ({ data: robots }) => {
  const { publishCommand } = useContext(MessageContext);
  const [joystickPosition, setJoystickPosition] = useState({
    left: { x: 0, y: 0 },
    right: { x: 0, y: 0 },
  });

  useMountEffect(() => {
    const intervalId = setInterval(() => {
      robots.forEach((robot) => {
        publishCommand(robot, 'move/joystick', {
          lx: joystickPosition.left.x,
          ly: joystickPosition.left.y,
          rx: joystickPosition.right.x,
          ry: joystickPosition.right.y,
        });
      });
    }, 50);

    let frameId;
    const handleJoystick = () => {
      const joystick = navigator.getGamepads()[0];
      if (joystick) {
        const [lx, ly, rx, ry] = joystick.axes;

        setJoystickPosition({
          left: { x: lx, y: -ly },
          right: { x: rx, y: -ry },
        });
      }

      frameId = window.requestAnimationFrame(handleJoystick);
    };

    handleJoystick();

    return () => {
      clearInterval(intervalId);
      window.cancelAnimationFrame(frameId);
    };
  });

  return (
    <div className={cx('container')}>
      <div className={cx('joysticks')}>
        <div className={cx('column')}>
          <Joystick
            size={120}
            stickSize={64}
            sticky={false}
            stickImage={stickImage}
            baseImage={baseImage}
            pos={joystickPosition.left}
            disabled={true}
          />
          <div className={cx('info')}>
            <div className={cx('item')}>{joystickPosition.left.x.toFixed(2)}</div>
            <div className={cx('item')}>{joystickPosition.left.y.toFixed(2)}</div>
          </div>
        </div>
        <div className={cx('column')}>
          <Joystick
            size={120}
            stickSize={64}
            sticky={false}
            stickImage={stickImage}
            baseImage={baseImage}
            pos={joystickPosition.right}
            disabled={true}
          />
          <div className={cx('info')}>
            <div className={cx('item')}>{joystickPosition.right.x.toFixed(2)}</div>
            <div className={cx('item')}>{joystickPosition.right.y.toFixed(2)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Action;
