import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { createStoreForPage } from '../store';

const StoreProvider = ({ children }) => {
  const location = useLocation();

  let pageType;
  if (location.pathname.startsWith('/control-center')) {
    pageType = 'control-center';
  } else if (location.pathname.startsWith('/mission-hub')) {
    pageType = 'mission-hub';
  } else if (location.pathname.startsWith('/video-manager')) {
    pageType = 'video-manager';
  }

  const { store, persistor } = useMemo(() => createStoreForPage(pageType), [pageType]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export default StoreProvider;
