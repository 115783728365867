import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useContext, useState, useMemo, useRef } from 'react';

import styles from './index.module.scss';

import ConfirmSlider from '@/components/ui/ConfirmSlider';
import Spinner from '@/components/ui/Spinner';
import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';
import EventEmitter from '@/libs/EventEmitter';
import { waitForInspectionStep } from '@/utils/Sleep';
import { getCSSVariable } from '@/utils/theme';

const cx = classNames.bind(styles);

// 상수 정의
const SURFACE_NUMBERS = [1, 2];
const SURFACE_PWM_MIN = 1100;
const SURFACE_PWM_MAX = 1800;

const Action = ({ data: robots }) => {
  const { publishCommand } = useContext(MessageContext);
  const [currentSurfaceNumber, setCurrentSurfaceNumber] = useState();
  const surfaceDefaultTrim = useRef({});

  // 테스트 진행 중 여부
  const isTesting = useMemo(() => {
    return currentSurfaceNumber !== undefined;
  }, [currentSurfaceNumber]);

  useMountEffect(() => {
    const subscribeTokens = [];

    robots.forEach((robot) => {
      surfaceDefaultTrim.current[robot.id] = {};

      publishCommand(robot, 'param/read', [['SERVO1_TRIM']]);
      publishCommand(robot, 'param/read', [['SERVO2_TRIM']]);

      subscribeTokens.push(
        EventEmitter.subscribe(`${robot.id}/telemetry/paramValue`, (data) => {
          if (!data[65535]) return;
          if (!['SERVO1_TRIM', 'SERVO2_TRIM'].includes(data[65535].paramId)) return;

          // 기체의 기존 Servo Trim 값 저장
          if (surfaceDefaultTrim.current[robot.id][data[65535].paramId] === undefined) {
            surfaceDefaultTrim.current[robot.id][data[65535].paramId] = data[65535].paramValue;
          }
        })
      );

      return () => {
        subscribeTokens.forEach((subscribeToken) => EventEmitter.unsubscribe(subscribeToken));
      };
    });
  });

  const doSurfaceTest = (surfaceNumber) => {
    setCurrentSurfaceNumber(surfaceNumber);

    return new Promise((resolve) => {
      robots.forEach(async (robot) => {
        // 우측 타면 테스트
        if (surfaceNumber === 1) {
          publishCommand(robot, 'param/set', [[`SERVO1_TRIM`, SURFACE_PWM_MIN, 4]]);
          await waitForInspectionStep();
          publishCommand(robot, 'param/set', [[`SERVO1_TRIM`, SURFACE_PWM_MAX, 4]]);
          await waitForInspectionStep();
          publishCommand(robot, 'param/set', [[`SERVO1_TRIM`, surfaceDefaultTrim.current[robot.id]['SERVO1_TRIM'], 4]]);
        }
        // 좌측 타면 테스트
        else if (surfaceNumber === 2) {
          publishCommand(robot, 'param/set', [[`SERVO2_TRIM`, SURFACE_PWM_MAX, 4]]);
          await waitForInspectionStep();
          publishCommand(robot, 'param/set', [[`SERVO2_TRIM`, SURFACE_PWM_MIN, 4]]);
          await waitForInspectionStep();
          publishCommand(robot, 'param/set', [[`SERVO2_TRIM`, surfaceDefaultTrim.current[robot.id]['SERVO2_TRIM'], 4]]);
        }
      });

      setTimeout(() => {
        setCurrentSurfaceNumber();
        resolve();
      }, 3000);
    });
  };

  const doStartAll = () => {
    // 전체 테스트 작업 정의
    const tasks = SURFACE_NUMBERS.map((surfaceNumber) => {
      return () => doSurfaceTest(surfaceNumber);
    });

    // 전체 테스트 작업 순차 실행
    tasks.reduce((promise, task) => {
      return promise.then(task);
    }, Promise.resolve());
  };

  const doStartSingle = (e) => {
    const surfaceNumber = Number(e.currentTarget.dataset.surface);
    doSurfaceTest(surfaceNumber).then(setCurrentSurfaceNumber);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('figure')}>
        <img src={require('../../../assets/inspection-body.png')} alt="" className={cx('body')} />
        {/* Wing Right */}
        <div className={cx('wing', 'right')}>
          <img
            src={require('../../../assets/inspection-surface-right.png')}
            alt=""
            className={cx(['surface', { active: currentSurfaceNumber === SURFACE_NUMBERS[0] }])}
          />
          <div className={cx('info')}>
            <svg width="36" height="64">
              <line
                x1="24"
                y1="0"
                x2="36"
                y2="64"
                stroke={getCSSVariable('--line-color')}
                strokeWidth="1"
                strokeDasharray="3,3"
              />
            </svg>
            <div className={cx('wrapper')}>
              <div className={cx('name')}>Surface (R)</div>
              <button data-surface={1} onClick={doStartSingle} disabled={isTesting}>
                {currentSurfaceNumber !== 1 && 'Start'}
                {currentSurfaceNumber === 1 && <Spinner size={14} />}
              </button>
            </div>
          </div>
        </div>
        {/* Wing Left */}
        <div className={cx('wing', 'left')}>
          <img
            src={require('../../../assets/inspection-surface-left.png')}
            alt=""
            className={cx(['surface', { active: currentSurfaceNumber === SURFACE_NUMBERS[1] }])}
          />
          <div className={cx('info')}>
            <svg width="48" height="64">
              <line
                x1="48"
                y1="0"
                x2="36"
                y2="64"
                stroke={getCSSVariable('--line-color')}
                strokeWidth="1"
                strokeDasharray="3,3"
              />
            </svg>
            <div className={cx('wrapper')}>
              <div className={cx('name')}>Surface (L)</div>
              <button data-surface={2} onClick={doStartSingle} disabled={isTesting}>
                {currentSurfaceNumber !== 2 && 'Start'}
                {currentSurfaceNumber === 2 && <Spinner size={14} />}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmSlider onConfirm={doStartAll} isProcessing={isTesting} />
    </div>
  );
};

export default Action;
