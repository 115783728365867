import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useState, useMemo, useContext, useRef } from 'react';

import styles from './index.module.scss';

import ProgressBar from '@/components/ui/ProgressBar';
import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const { publishCommand } = useContext(MessageContext);
  const [params, setParams] = useState({
    MNT1_PITCH_MAX: null,
    MNT1_PITCH_MIN: null,
    MNT1_YAW_MAX: null,
    MNT1_YAW_MIN: null,
  });
  const [values, setValues] = useState({ pitch: 0, yaw: 0 });
  const attitude = useRef(values);
  const intervalId = useRef();

  // 수신된 파라미터 갯수
  const isLoadedCount = useMemo(() => {
    return Object.values(params).filter((value) => value !== null).length;
  }, [params]);

  // 파라미터 수신 중 여부
  const isLoading = useMemo(() => {
    return isLoadedCount < Object.keys(params).length;
  }, [isLoadedCount]);

  const [pitchMax, pitchMin, yawMax, yawMin] = useMemo(() => {
    return [
      params['MNT1_PITCH_MAX']?.value ?? 0,
      params['MNT1_PITCH_MIN']?.value ?? 0,
      params['MNT1_YAW_MAX']?.value ?? 0,
      params['MNT1_YAW_MIN']?.value ?? 0,
    ];
  }, [params]);

  // Pitch slider 높이
  const sliderHeight = useMemo(() => {
    return pitchMax + Math.abs(pitchMin);
  }, [pitchMax, pitchMin]);

  // Yaw slider 가로
  const sliderWidth = useMemo(() => {
    return yawMax + Math.abs(yawMin);
  }, [yawMax, yawMin]);

  // Pitch label 수직 위치
  const labelTop = useMemo(() => {
    return `${(1 - (values.pitch + Math.abs(pitchMin)) / sliderHeight) * 100}%`;
  }, [sliderHeight, values.pitch]);

  // Yaw label 수평 위치
  const labelLeft = useMemo(() => {
    return `${((values.yaw + Math.abs(yawMin)) / sliderWidth) * 100}%`;
  }, [sliderWidth, values.yaw]);

  useMountEffect(() => {
    Object.keys(params).forEach((key) => {
      publishCommand(robots[0], 'param/read', [[key]]);
    });

    const subscribeToken = EventEmitter.subscribe(`${robots[0].id}/telemetry/paramValue`, (data) => {
      if (!data[65535]) return;
      if (!Object.keys(params).includes(data[65535].paramId)) return;

      setParams((prev) => ({
        ...prev,
        [data[65535].paramId]: {
          value: data[65535].paramValue,
          type: data[65535].paramType,
        },
      }));
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  useUpdateEffect(() => {
    attitude.current = values;
  }, [values]);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: Number(e.target.value),
    });
  };

  const startDrag = (e) => {
    intervalId.current = setInterval(() => {
      publishCommand(robots[0], 'action/gimbal/move', [[attitude.current.pitch, attitude.current.yaw]]);
    }, 100);
  };

  const stopDrag = (e) => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
  };

  const doReset = () => {
    setValues({ pitch: 0, yaw: 0 });
    publishCommand(robots[0], 'action/gimbal/reset', [[]]);
  };

  // '+' 기호 추가
  const withSign = (number) => {
    if (number > 0) return `+${number}`;
    return number;
  };

  // 파라미터 수신 중인 경우
  if (isLoading) {
    return <ProgressBar current={isLoadedCount} total={Object.keys(params).length} />;
  }

  return (
    <div className={cx('container')}>
      <div className={cx('sliders')}>
        <div className={cx('sliderWrapper')}>
          <input
            name="pitch"
            type="range"
            min={params['MNT1_PITCH_MIN'].value}
            max={params['MNT1_PITCH_MAX'].value}
            value={values.pitch}
            onChange={handleChange}
            onMouseDown={startDrag}
            onMouseUp={stopDrag}
            className={cx(['slider', 'pitch'])}
            style={{ height: sliderHeight }}
          />
          <div className={cx(['labelRange', 'pitch'])}>
            <div className={cx(['label', 'pitch'])} style={{ top: labelTop }}>
              {withSign(values.pitch)}
            </div>
          </div>
        </div>
        <div className={cx('sliderWrapper')}>
          <input
            name="yaw"
            type="range"
            min={params['MNT1_YAW_MIN'].value}
            max={params['MNT1_YAW_MAX'].value}
            value={values.yaw}
            onChange={handleChange}
            onMouseDown={startDrag}
            onMouseUp={stopDrag}
            className={cx(['slider', 'yaw'])}
            style={{ width: sliderWidth }}
          />
          <div className={cx(['labelRange', 'yaw'])}>
            <div className={cx(['label', 'yaw'])} style={{ left: labelLeft }}>
              {withSign(values.yaw)}
            </div>
          </div>
        </div>
      </div>
      <button onClick={doReset} className={cx('button')}>
        Reset
      </button>
    </div>
  );
};

export default Action;
