import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import { DragPan } from 'ol/interaction';
import React, { useRef } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import styles from './index.module.scss';
import Mappers from './Mappers';
import MapToolbar from './MapToolbar';

import actions from '@/actions';
import Cross from '@/components/ui/map/Cross';
import OlMap from '@/helpers/OlMap';

const cx = classNames.bind(styles);

const Map = () => {
  const dispatch = useDispatch();
  const focusRobotId = useSelector((state) => state.robot.focusRobotId);
  const store = useStore();
  const map = useRef();
  const mapRef = useRef();

  useMountEffect(() => {
    // redux-persist 활용한 최종 center 및 zoom 적용
    const { center, zoom } = store.getState().map;
    map.current = OlMap.init(mapRef.current, { center, zoom });

    setTimeout(() => OlMap.fitBoundsAuto(), 1000);

    // 지도 이동 중일 시
    map.current.getView().on('change:center', () => {
      const { lat, lng } = OlMap.getCenter();
      dispatch(actions.map.setCenter(lat, lng));
    });

    // 지도 이동 또는 줌 완료 시
    map.current.on('moveend', () => {
      // 로봇 Focus 설정된 경우
      if (store.getState().robot.focusRobotId) {
        // 로봇 위치로 지도 이동
        moveToRobot();
      }

      const { lat, lng } = OlMap.getCenter();
      const zoom = map.current.getView().getZoom();

      dispatch(actions.map.setPositionWithZoom(lat, lng, zoom));
    });
  });

  useUpdateEffect(() => {
    const dragPan = map.current
      .getInteractions()
      .getArray()
      .find((interaction) => interaction instanceof DragPan);

    // Focus 설정된 로봇이 존재하지 않는 경우 지도 drag 허용
    dragPan.setActive(!focusRobotId);

    if (!focusRobotId) return;

    // 로봇 위치로 지도 이동
    moveToRobot();

    const robot = map.current.getOverlayById(`robots/${focusRobotId}`);
    robot.on('change:position', moveToRobot); // 로봇 위치 변경 시

    return () => {
      robot.un('change:position', moveToRobot);
    };
  }, [focusRobotId]);

  const moveToRobot = () => {
    const currentFocusRobotId = store.getState().robot.focusRobotId;
    const robot = map.current.getOverlayById(`robots/${currentFocusRobotId}`);
    const center = robot.getPosition();

    map.current.getView().animate({ center, duration: 0 });
  };

  return (
    <div className={cx('container')}>
      <div ref={mapRef} className={cx('map')} />
      <Mappers />
      <Cross />
      <MapToolbar />
    </div>
  );
};

export default Map;
