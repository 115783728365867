import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useContext, useRef } from 'react';

import styles from './console.module.scss';
import ProcessingButton from '../ProcessingButton';

import { MessageContext } from '@/helpers/MessageProvider/ForControlCenter';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Panel = ({ robot }) => {
  const { publishCommand } = useContext(MessageContext);
  const subscribeToken = useRef();

  useMountEffect(() => {
    return () => {
      unsubscribe();
    };
  });

  const unsubscribe = () => {
    if (subscribeToken.current) {
      EventEmitter.unsubscribe(subscribeToken.current);
      subscribeToken.current = null;
    }
  };

  const doStart = () => {
    return new Promise((resolve) => {
      subscribeToken.current = EventEmitter.subscribe(`${robot.id}/telemetry/commandAck`, (data) => {
        // 241: MAV_CMD_PREFLIGHT_CALIBRATION
        if (data.command === 241) {
          unsubscribe();
          resolve(true);
        }
      });
      publishCommand(robot, 'calibration/gyroscope', [[]]);
    });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('message')}>Place your vehicle on a surface and leave it still.</div>
      <ProcessingButton text="Start" doProcess={doStart} />
    </div>
  );
};

export default Panel;
